import Axios from 'axios';
import storage from '../components/helpers/StorageHelper';

const __DEV = process.env.REACT_APP_ENV === 'development';

export default (() => {
  const conn = Axios.create({
    baseURL: __DEV
      ? process.env.REACT_APP_STAGING_API_LOCATION
      : process.env.REACT_APP_PROD_API_LOCATION,
  });

  conn.interceptors.request.use(function (config) {
    const token = storage.get('token');
    if (token) {
      config.headers.common['Authorization'] = `Bearer ${token}`;
    }
    config.headers.common['Content-Type'] = 'application/json';
    return config;
  });

  return conn;
})();
