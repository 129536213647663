import React from 'react';
import { Link } from 'react-router-dom';

export default function Conversion() {
  return (
    <>
      <div>
        <div>
          <div className='container mb-5'>
            <div className='row mt-5'>
              <div className='col-md-12'>
                <h1>ePub CONVERSION SERVICES</h1>
                <p>
                  We want the best user experience for our readers and we
                  believe only an ePub format guarantees this. Hence, our
                  resolve to make ePub the only accepted document format for all
                  books on Bambooks.
                </p>
                <p>
                  Perhaps your book is in PDF or Word Document, do well to
                  convert to ePub so you’re able to{' '}
                  <Link to='/login'>upload here.</Link>
                </p>
                <p>
                  Here are some recommended formatting styles to apply when
                  converting your book to ePub format:
                </p>
                <ul style={{ listStyleType: 'disc' }}>
                  <li>Line spacing: 1.15 –1.5</li>
                  <li>Font size: 12pt –14pt</li>
                  <li>Text Align: Justify</li>
                  <li>Add space between paragraphs</li>
                  <li>
                    Include Logical Table of Content (for easy navigation)
                  </li>
                  <li>Place your book cover on the first page</li>
                </ul>
                <p>
                  In a bid to ease the process of onboarding your book on our
                  platform, we offer ePub conversion services.
                </p>
                <p>We currently have the following plans:</p>
                <div className='section'>
                  <div className='row justify-content-center'>
                    <div className='col-md-4 mb-4'>
                      <div className='pay-card'>
                        <div className='pay-hanger-box'></div>
                        <div className='pay-span mt-5 mb-4 text-center'>
                          Basic Conversion
                        </div>
                        <div className='pay-price'>
                          <span>₦ 350.00/</span>
                          <small>1000 words</small>
                        </div>
                        <p style={{ textAlign: 'center' }}>
                          Base price: ₦5,000
                        </p>
                        <ul className='pay-features'>
                          <li>
                            <i
                              className='la la-check-circle'
                              style={{ color: '#1e7b85', marginRight: '8px' }}
                            />
                            <span>Text Content</span>
                          </li>
                          <li>
                            <i
                              className='la la-check-circle'
                              style={{ color: '#1e7b85', marginRight: '8px' }}
                            />
                            <span>Paragraphs</span>
                          </li>
                        </ul>
                        <ul className='pay-features-x'>
                          <li>
                            <i
                              className='la la-times-circle'
                              style={{ color: 'red', marginRight: '8px' }}
                            />
                            <span>Bullet Points</span>
                          </li>
                          <li>
                            <i
                              className='la la-times-circle'
                              style={{ color: 'red', marginRight: '8px' }}
                            />
                            <span>Lists</span>
                          </li>
                          <li>
                            <i
                              className='la la-times-circle'
                              style={{ color: 'red', marginRight: '8px' }}
                            />
                            <span> Columns</span>
                          </li>
                          <li>
                            <i
                              className='la la-times-circle'
                              style={{ color: 'red', marginRight: '8px' }}
                            />
                            <span>Images / Graphics</span>
                          </li>
                          <li>
                            <i
                              className='la la-times-circle'
                              style={{ color: 'red', marginRight: '8px' }}
                            />
                            <span>Tables</span>
                          </li>
                          <li>
                            <i
                              className='la la-times-circle'
                              style={{ color: 'red', marginRight: '8px' }}
                            />
                            <span>Charts</span>
                          </li>
                        </ul>
                        <ul className='pay-features'>
                          <li>
                            <i
                              className='la la-check-circle'
                              style={{ color: '#1e7b85', marginRight: '8px' }}
                            />
                            <span>Customer Support</span>
                          </li>
                        </ul>
                        <div className='pay-note mb-5'></div>

                        <div className='pay-action mb-5 text-center'>
                          <a href='mailto:publish@bambooks.io?subject=Basic ePub conversion Service'>
                            <button className='px-3'>Contact us</button>
                          </a>
                        </div>
                        <span className='clearfix'></span>
                      </div>
                    </div>
                    <div className='col-md-4 mb-4'>
                      <div className='pay-card primary'>
                        <div className='pay-span mt-5 mb-4 text-center'>
                          Advanced Conversion
                        </div>
                        <div className='pay-price'>
                          <span>₦ 400.00/</span>
                          <small>1000 words</small>
                        </div>
                        <p style={{ textAlign: 'center' }}>
                          Base price: ₦10,000
                        </p>
                        <ul className='pay-features'>
                          <li>
                            <i
                              className='la la-check-circle'
                              style={{ color: '#1e7b85', marginRight: '8px' }}
                            />
                            <span>Text Content</span>
                          </li>
                          <li>
                            <i
                              className='la la-check-circle'
                              style={{ color: '#1e7b85', marginRight: '8px' }}
                            />
                            <span>Paragraphs</span>
                          </li>
                          <li>
                            <i
                              className='la la-check-circle'
                              style={{ color: '#1e7b85', marginRight: '8px' }}
                            />
                            <span>Bullet Points</span>
                          </li>
                          <li>
                            <i
                              className='la la-check-circle'
                              style={{ color: '#1e7b85', marginRight: '8px' }}
                            />
                            <span>Lists</span>
                          </li>
                        </ul>
                        <ul className='pay-features-x'>
                        <li>
                            <i
                              className='la la-times-circle'
                              style={{ color: 'red', marginRight: '8px' }}
                            />
                            <span>Columns</span>
                          </li>
                          <li>
                            <i
                              className='la la-times-circle'
                              style={{ color: 'red', marginRight: '8px' }}
                            />
                            <span>Images / Graphics</span>
                          </li>
                          <li>
                            <i
                              className='la la-times-circle'
                              style={{ color: 'red', marginRight: '8px' }}
                            />
                            <span>Tables</span>
                          </li>
                          <li>
                            <i
                              className='la la-times-circle'
                              style={{ color: 'red', marginRight: '8px' }}
                            />
                            <span>Charts</span>
                          </li>
                        </ul>
                        <ul className='pay-features'>
                          <li>
                            <i
                              className='la la-check-circle'
                              style={{ color: '#1e7b85', marginRight: '8px' }}
                            />
                            <span>Customer Support</span>
                          </li>
                        </ul>
                        <div className='pay-note mb-5'></div>
                        <div className='pay-action mb-5 text-center'>
                          <a href='mailto:publish@bambooks.io?subject=Advanced ePub conversion Service'>
                            <button className='px-3'>Contact us</button>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4 mb-4'>
                      <div className='pay-card'>
                        <div className='pay-hanger-box'></div>
                        <div className='pay-span mt-5 mb-4 text-center'>
                          Premium Conversion
                        </div>
                        <div className='pay-price'>
                          <span>₦ 450.00/</span>
                          <small>1000 words</small>
                        </div>
                        <p style={{ textAlign: 'center' }}>
                          Base price: ₦10,000
                        </p>
                        <ul className='pay-features'>
                          <li>
                            <i
                              className='la la-check-circle'
                              style={{ color: '#1e7b85', marginRight: '8px' }}
                            />
                            <span>Text Content</span>
                          </li>
                          <li>
                            <i
                              className='la la-check-circle'
                              style={{ color: '#1e7b85', marginRight: '8px' }}
                            />
                            <span>Paragraphs</span>
                          </li>
                          <li>
                            <i
                              className='la la-check-circle'
                              style={{ color: '#1e7b85', marginRight: '8px' }}
                            />
                            <span>Bullet Points</span>
                          </li>
                          <li>
                            <i
                              className='la la-check-circle'
                              style={{ color: '#1e7b85', marginRight: '8px' }}
                            />
                            <span>Lists</span>
                          </li>
                          <li>
                            <i
                              className='la la-check-circle'
                              style={{ color: '#1e7b85', marginRight: '8px' }}
                            />
                            <span>Columns</span>
                          </li>
                        </ul>

                        <ul className='pay-features'>
                          <li>
                            <i
                              className='la la-check-circle'
                              style={{ color: '#1e7b85', marginRight: '8px' }}
                            />
                            <span>Images / Graphics</span>
                          </li>
                          <li>
                            <i
                              className='la la-check-circle'
                              style={{ color: '#1e7b85', marginRight: '8px' }}
                            />
                            <span>Tables</span>
                          </li>
                          <li>
                            <i
                              className='la la-check-circle'
                              style={{ color: '#1e7b85', marginRight: '8px' }}
                            />
                            <span>Charts</span>
                          </li>
                        </ul>
                        <ul className='pay-features'>
                          <li>
                            <i
                              className='la la-check-circle'
                              style={{ color: '#1e7b85', marginRight: '8px' }}
                            />
                            <span>Customer Support</span>
                          </li>
                        </ul>
                        <div className='pay-note mb-5'></div>

                        <div className='pay-action mb-5 text-center'>
                          <a href='mailto:publish@bambooks.io?subject=Premium ePub conversion Service'>
                            <button className='px-3'>Contact us</button>
                          </a>
                        </div>
                        <span className='clearfix'></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
