import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import React, { Component, createRef } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import Track from './Track';
import Modal from '../Modal';
import Spinner from '../Spinner';
import { ChunkUploader } from '../Helpers';
import { createSingleBook } from '../../actions/Content';
import { getDurationInHMS } from '../../Mp3Util';

export default class MultipartUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: '',
      files: [],
      open: false,
      terms: false,
      uploading: false,
      completed: false,
      hasFailed: false,
    };

    this.nameRef = createRef();
    this.audioRef = createRef();
    this.filenameRef = createRef();

    this.submit = this.submit.bind(this);
    this.setOpen = this.setOpen.bind(this);
    this.setTerms = this.setTerms.bind(this);
    this.setFiles = this.setFiles.bind(this);
    this.uploader = this.uploader.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.addTracks = this.addTracks.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }

  onDragEnd(r) {
    const { files } = this.state;
    const { destination, source } = r;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const cache = files;
    const obj = cache[source.index];
    cache.splice(source.index, 1);
    cache.splice(destination.index, 0, obj);
    this.setFiles([...cache]);
  }

  removeItem(n) {
    const cache = this.state.files;
    cache.splice(n, 1);
    this.setFiles([...cache]);
  }

  addTracks(v) {
    const aud = new Audio();
    const { name, audio } = v;
    const { files } = this.state;

    if (audio.type === 'audio/mpeg') {
      aud.src = URL.createObjectURL(audio);

      aud.oncanplaythrough = () => {
        let fileDuration = aud.duration;
        let fileDurationStr = getDurationInHMS(Math.ceil(fileDuration));

        audio.eTags = '';
        audio.title = name;
        audio.key = nanoid();
        audio.failed = false;
        audio.uploading = false;
        audio.completed = false;
        audio.chunksUploaded = 0;
        audio.uploadId = nanoid();
        audio.duration = fileDurationStr;
        audio.fileDuration = Math.ceil(fileDuration);

        this.setFiles([...files, audio]);
        this.setOpen(false);
      };
    } else {
      toast.error('Invalid file type added');
    }
  }

  async uploader() {
    let complete = true;
    const { files } = this.state;
    const { original_cover_name = '' } = this.props.book;

    if (files.length === 0) {
      toast.error('Please add at least 1 chapter');
      return;
    }
    if (!original_cover_name) {
      toast.error('Please select a book cover');
      return;
    }

    this.setState({
      ...this.state,
      uploading: true,
    });

    const {
      book,
      next,
      setLoading,
      book: { country_restriction = [] },
    } = this.props;

    let totalDuration = 0;
    let totalFileSize = 0;
    const audio_files = [];

    for (const file of files) {
      if (!file.completed) {
        await new ChunkUploader()
          .uploadFiles(file, (f) => this.setFiles([...files]))
          .then(() => {
            if (file.failed) {
              toast.error(`${file.title} upload failed`);
            }
          });
      }
    }

    files.forEach((f, i) => {
      if (f.completed) {
        audio_files.push({
          path: f.path,
          sequence: i + 1,
          chapter: f.title,
          file_size: f.size,
          start_time: '0.0',
          duration: f.fileDuration,
        });

        totalFileSize += f.size;
        totalDuration += f.fileDuration;
      } else {
        this.setState({
          ...this.state,
          hasFailed: true,
          uploading: false,
        });

        complete = false;
      }
    });

    if (complete) {
      if (!audio_files.length) {
        toast.error('No audio files');
        return;
      }

      setLoading(true);
      createSingleBook(
        {
          ...book,
          audio_files,
          book_type: 'audiobook',
          file_size: totalFileSize,
          file_path: audio_files[0].path,
          attachment: audio_files[0].path,
          num_of_pages: Math.ceil(totalDuration),
          country_restriction: [...new Set(country_restriction)],
        },
        (s, _) => {
          this.setState({
            ...this.state,
            uploading: true,
          });
          if (s) {
            toast.success('Book uploaded successfully');
            next(3);
          } else {
            toast.error('Failed to create book');
            setLoading(false);
          }
        },
        this.props.dispatch,
      );
    } else {
      toast.error('Some uploads failed! Click retry to resume failed uploads.');
    }
  }

  submit() {
    const name = this.nameRef.current.value;
    const audio = this.audioRef.current.files[0];

    if (!name || !audio) return;

    this.addTracks({ name, audio });
  }

  setOpen(o) {
    this.setState({
      ...this.state,
      open: o,
    });
  }

  setTerms(t) {
    this.setState({
      ...this.state,
      terms: t,
    });
  }

  setFiles(f) {
    this.setState({
      ...this.state,
      files: f,
    });
  }

  render() {
    const { next } = this.props;
    const { files, open, terms, uploading, hasFailed } = this.state;
    return (
      <>
        <div>
          <div className='flex-row f-j-b'>
            <h2>Add audio files</h2>
            <button
              type='button'
              className='btn btn-gradient-01'
              onClick={() => this.setOpen(true)}
            >
              Add Chapters
            </button>
          </div>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId={'column'}>
              {(provided) => {
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      minHeight: '20vh',
                      margin: '0 0 16px 0',
                    }}
                  >
                    {files.length > 0 ? (
                      <div
                        style={{
                          margin: '8px 0',
                        }}
                      >
                        {files.map((e, i) => (
                          <Track
                            file={e}
                            index={i}
                            key={e.key}
                            update={(n, i) => {
                              files[i].title = n;
                              this.setFiles([...files]);
                            }}
                            remove={this.removeItem}
                          />
                        ))}
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          minHeight: '200px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      ></div>
                    )}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
          <div className='flex f-a-c mb-3 f-wrap'>
            <div>
              <div className='styled-checkbox'>
                <input
                  type='checkbox'
                  name='savecard'
                  id='check-terms'
                  onChange={(e) => this.setTerms(e.target.checked)}
                />
                <label htmlFor='check-terms'>
                  I understand and agree to the{' '}
                  <Link to='/terms'>terms and conditions</Link> and{' '}
                  <Link to='/content-policy'>content policy guidelines</Link>
                </label>
              </div>
            </div>
            <ul className='pager wizard text-right f-grow'>
              <li className='previous d-inline-block'>
                <button
                  type='button'
                  className='btn btn-secondary ripple'
                  onClick={() => next(1)}
                >
                  Back
                </button>
              </li>{' '}
              <li className='next d-inline-block'>
                <button
                  type='submit'
                  onClick={this.uploader}
                  disabled={!terms || !files.length || uploading}
                  className='btn btn-gradient-01'
                >
                  {uploading ? (
                    <Spinner full={false} />
                  ) : hasFailed ? (
                    'Retry upload'
                  ) : (
                    'Create Book'
                  )}
                </button>
              </li>
            </ul>
          </div>
        </div>
        <Modal
          show={open}
          setShow={this.setOpen}
          header='Add a chapter'
          action={this.submit}
        >
          <div>
            <label className='form-control-label'>
              Chapter Title <span className='text-danger ml-2'>*</span>
            </label>
            <input
              type='text'
              name='name'
              ref={this.nameRef}
              className='form-control'
              placeholder='Chapter Title'
            />
          </div>
          <div className='form-group'>
            <label className='form-control-label'>
              Select audio file
              <span className='text-danger  ml-2'>*</span>
            </label>
            <div className='input-group'>
              <span className='input-group-btn'>
                <button
                  type='button'
                  className='btn btn-primary ripple'
                  onClick={() => {
                    // eslint-disable-next-line no-undef
                    document.getElementById('book').click();
                  }}
                >
                  Browse
                </button>
              </span>
              <input
                readOnly
                value={''}
                type='text'
                ref={this.filenameRef}
                required={true}
                className='form-control'
              />
              <input
                hidden
                id='book'
                name='audio'
                type='file'
                accept='.mp3'
                ref={this.audioRef}
                onChange={(e) => {
                  this.filenameRef.current.value = e.target.files[0].name;
                }}
              />
            </div>
            <small className='text-info'>
              Only mp3 formats are allowed; File Size limit - 50MB
            </small>
          </div>
        </Modal>
      </>
    );
  }
}
