import { combineReducers } from 'redux';
import User from './User';
import Content from './Content';

const rootReducer = combineReducers({
  User,
  Content,
});

export default rootReducer;
