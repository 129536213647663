import moment from 'moment';
import Datetime from 'react-datetime';
import { useForm } from 'react-hook-form';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import Modal from '../../Modal';
import GenrePicker from '../../Books/GenrePicker';
import CountryPicker from '../../Books/CountryPicker';
import {
  getBisac,
  getCountries,
  searchAuthors,
} from '../../../actions/Content';
import { toBase64 } from '../../Helpers';
import { toast } from 'react-toastify';

export default function AddBook({ value, save }) {
  const {
    name = '',
    synopsis = '',
    publisher = '',
    book_type = '',
    book_genre = '',
    book_base64 = '',
    num_of_pages = '',
    cover_base64 = '',
    country_names = '',
    year_of_release = '',
    age_restriction = '',
    original_book_name = '',
    original_cover_name = '',
    country_restriction = [],
    author: defaultAuthor = '',
  } = value[0];

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(false);
  const [author, setAuthor] = useState([]);
  const [genres, setGenres] = useState([]);
  const [country, setCountry] = useState([]);
  const [files, setFile] = useState({
    book_base64: book_base64,
    cover_base64: cover_base64,
    original_book_name: original_book_name,
    original_cover_name: original_cover_name,
  });
  const [restricted, setRestricted] = useState({
    country_name: country_names,
    country_code: country_restriction,
  });
  const [age, setAge] = useState(age_restriction);
  const [type, setType] = useState(book_type || '');
  const genre = useSelector((s) => s.Content.genres);
  const { handleSubmit, register, errors } = useForm();

  const saveValues = (v) => {
    delete v.book;
    delete v.cover;
    const data = {
      ...v,
      ...files,
      author_identifier: author[0].id || 0,
      book_genre: genre[0].name,
      specific_bisac_code: genre[0].code,
      related_bisac_code: `${genre[0].code} ${
        (genre[1] && genre[1].code) || ''
      } ${(genre[2] && genre[2].code) || ''}`,
      country_names: restricted.country_name,
      country_restriction: [...new Set(restricted.country_code)],
    };
    save([data, value[1]]);
  };

  const readFile = async (file, type) => {
    const b64String = await toBase64(file).catch((e) => {
      toast.error('Invalid file selected!');
      return '';
    });

    if (b64String)
      setFile({
        ...files,
        ...(type === 'cover' && {
          cover_base64: b64String,
          original_cover_name: file.name,
        }),
        ...(type === 'book' && {
          book_base64: b64String,
          original_book_name: file.name,
        }),
      });
  };

  useEffect(() => {
    getBisac(
      {
        id: [],
      },
      (s, m) => {
        if (s) {
          setGenres(m);
        }
      },
      dispatch,
    );

    getCountries(
      '',
      (s, m) => {
        if (s) {
          setCountry(m);
        }
      },
      dispatch,
    );
  }, [dispatch]);

  const getAuthors = (value) =>
    new Promise(
      (res) => {
        searchAuthors({ text: value }, (data) => {
          res(
            data.map((e) => {
              return { label: e.name, value: e.name };
            }),
          );
        });
      },
      (rej) => {
        rej([]);
      },
    );

  return (
    <>
      <div className='wizard-form px-md-5 py-5'>
        <div className='row mb-5'>
          <div className='col'>
            <h3 className='form-title'>Add one or more books here</h3>
            <small>**(Note: You can add up to 5 books here)</small>
          </div>
        </div>
        <div className='books mb-5'></div>
        <hr className='mb-5' />
        <form onSubmit={handleSubmit(saveValues)}>
          <div>
            <div className='row' style={{ position: 'relative' }}>
              <div className='col-md-6 mb-3'>
                <label htmlFor='' className='label'>
                  Book Title *
                </label>
                <input
                  type='text'
                  name='name'
                  defaultValue={name}
                  placeholder='Enter the book title'
                  className='publish-input form-control'
                  ref={register({
                    required: true,
                  })}
                />
                {errors.name && (
                  <small className='text-danger' style={{ marginTop: '5px' }}>
                    Please enter a name for the book
                  </small>
                )}
              </div>
              <div className='col-md-6 mb-3 '>
                <label htmlFor='' className='label'>
                  Book Cover Image *
                </label>
                <div
                  className='file-container'
                  style={{ position: 'relative' }}
                >
                  <input
                    readOnly
                    type='text'
                    value={files.original_cover_name}
                    placeholder='Select Cover Image'
                    className='publish-input form-control'
                  />
                  <input
                    type='file'
                    hidden
                    id='cover'
                    name='cover'
                    className='d-none'
                    accept='image/*'
                    onChange={(e) => readFile(e.target.files[0], 'cover')}
                    ref={register({
                      required: !(files.cover_base64.length > 0),
                      validate: (e) => {
                        if (
                          value &&
                          value.cover_base64 &&
                          value.cover_base64.length
                        )
                          return true;
                        return e[0] && e[0].size < 10485760;
                      },
                    })}
                  />
                  <button
                    type='button'
                    className='btn btn-info choose-file'
                    onClick={() => {
                      document.getElementById('cover').click();
                    }}
                  >
                    Browse
                  </button>
                </div>
                <small style={{ color: 'darkgreen', marginTop: '5px' }}>
                  Only JPG, PNG formats are allowed; File Size limit - 10MB
                </small>
                {errors.cover && (
                  <>
                    <br />
                    <small className='text-danger' style={{ marginTop: '5px' }}>
                      Please choose a valid cover image
                    </small>
                  </>
                )}
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-md-6 mb-4'>
                <label htmlFor='' className='label'>
                  Author's Name
                </label>

                <div
                  className='file-container'
                  style={{ position: 'relative' }}
                >
                  <input
                    readOnly
                    type='text'
                    name='author'
                    ref={register({
                      required: true,
                    })}
                    defaultValue={defaultAuthor}
                    placeholder="Add Author's name here"
                    className='publish-input form-control'
                    value={
                      (author && author.map((e) => ` ${e.value}`)) ||
                      defaultAuthor
                    }
                  />
                  <button
                    type='button'
                    onClick={() => setView(true)}
                    className='btn btn-info choose-file'
                  >
                    Add
                  </button>
                </div>
                {errors.author && (
                  <small className='text-danger' style={{ marginTop: '5px' }}>
                    Please enter the authors name
                  </small>
                )}
              </div>
              <div className='col-md-6'>
                <label htmlFor='' className='label'>
                  Select Book *
                </label>
                <div
                  className='file-container'
                  style={{ position: 'relative' }}
                >
                  <input
                    readOnly
                    type='text'
                    value={files.original_book_name}
                    placeholder='Upload Your Book'
                    className='publish-input form-control'
                  />
                  <input
                    id='book'
                    type='file'
                    name='book'
                    accept='.epub, .zip'
                    className='d-none'
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file.type.includes('application/epub')) {
                        readFile(file, 'book');
                      }
                    }}
                    ref={register({
                      required: !(files.book_base64.length > 0),
                      validate: (e) => {
                        if (
                          value &&
                          value.book_base64 &&
                          value.book_base64.length
                        )
                          return true;
                        return e[0] && e[0].size < 52428800;
                      },
                    })}
                  />
                  <button
                    type='button'
                    className='btn btn-info choose-file'
                    onClick={() => {
                      document.getElementById('book').click();
                    }}
                  >
                    Browse
                  </button>
                </div>
                <small style={{ color: 'darkgreen', marginTop: '5px' }}>
                  Only EPUB format is allowed; File Size limit - 50MB
                </small>
                <br />
                <a
                  style={{ color: '#1e7b85' }}
                  target='_blank'
                  rel='noreferrer noopener'
                  href='/conversion'
                >
                  <u>
                    To access our ePub Conversion Services, please click here
                  </u>
                </a>
                <br />
                {errors.book && (
                  <small className='text-danger' style={{ marginTop: '5px' }}>
                    Please select the book file
                  </small>
                )}
              </div>
            </div>

            <div className='row' style={{ position: 'relative' }}>
              <div className='col-md-6 mb-3'>
                <label htmlFor='' className='label'>
                  Publisher's Name
                </label>
                <input
                  type='text'
                  name='publisher'
                  ref={register({
                    required: true,
                  })}
                  defaultValue={publisher}
                  placeholder="Enter the publisher's name"
                  className='publish-input form-control'
                />
                {errors.publisher && (
                  <small className='text-danger' style={{ marginTop: '5px' }}>
                    Please enter the name of the publisher
                  </small>
                )}
              </div>
              <div className='col-md-6 mb-3 '>
                <label htmlFor='' className='label'>
                  Year Of Book Release
                </label>
                <div
                  className='file-container'
                  style={{ position: 'relative' }}
                >
                  <Datetime
                    dateFormat='YYYY'
                    closeOnSelect
                    inputProps={{
                      readOnly: true,
                      name: 'year_of_release',
                      placeholder: 'Tap to select year of release',
                      className: 'publish-input form-control yearpicker',
                      ref: register({
                        required: true,
                      }),
                    }}
                    timeFormat=''
                    defaultValue={moment(year_of_release, 'YYYY')}
                    initialValue={moment(year_of_release, 'YYYY')}
                    isValidDate={(c) => c.isBefore(moment())}
                  />
                </div>
                {errors.year_of_release && (
                  <small className='text-danger' style={{ marginTop: '5px' }}>
                    Please select the year this book was released
                  </small>
                )}
              </div>
            </div>

            <div className='row' style={{ position: 'relative' }}>
              <div className='col-md-6 mb-3'>
                <label htmlFor='' className='label'>
                  Age Restriction
                </label>
                <div
                  className='file-container'
                  style={{ position: 'relative' }}
                >
                  <input
                    readOnly
                    type='text'
                    placeholder='select age groups'
                    name='age_restriction'
                    value={age}
                    className='publish-input form-control'
                    ref={register({
                      required: true,
                    })}
                  />

                  <button
                    type='button'
                    className='btn btn-info choose-file dropdown-toggle'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    Age
                  </button>
                  <div className='dropdown-menu'>
                    {/*generate years here*/}
                    <button
                      type='button'
                      className='dropdown-item'
                      onClick={() => setAge('Kids')}
                    >
                      Kids (11 and under)
                    </button>
                    <button
                      type='button'
                      className='dropdown-item'
                      onClick={() => setAge('Teens')}
                    >
                      Teens (12yrs - 17yrs)
                    </button>
                    <button
                      type='button'
                      className='dropdown-item'
                      onClick={() => setAge('Adults')}
                    >
                      Adult (18 and above)
                    </button>
                  </div>
                </div>
                {errors.age_restriction && (
                  <small className='text-danger' style={{ marginTop: '5px' }}>
                    Please select the minimum age for viewing this book
                  </small>
                )}
              </div>
              <div className='col-md-6 mb-3'>
                <label htmlFor='' className='label'>
                  Genre
                </label>
                <div
                  className='file-container'
                  style={{ position: 'relative' }}
                >
                  <input
                    readOnly
                    type='text'
                    name='book_genre'
                    id='genre-placeholder'
                    defaultValue={book_genre}
                    placeholder='Add subjects here'
                    value={genre.map((e) => ` ${e.name}`)}
                    ref={register({
                      require: true,
                      validate: () => genre.length,
                    })}
                    className='publish-input form-control'
                  />
                  <button
                    type='button'
                    id='genre-modal-button'
                    className='btn btn-info choose-file'
                    onClick={() => setOpen(true)}
                  >
                    Add
                  </button>
                </div>
                {errors.book_genre && (
                  <small className='text-danger' style={{ marginTop: '5px' }}>
                    Please select the book genre
                  </small>
                )}
              </div>
            </div>

            <div className='row' style={{ position: 'relative' }}>
              <div className='col-md-6 mb-3'>
                <label htmlFor='' className='label'>
                  Length of Book
                </label>
                <input
                  type='text'
                  name='num_of_pages'
                  defaultValue={num_of_pages}
                  ref={register({ required: true })}
                  className='publish-input form-control'
                  placeholder='Enter the number of pages'
                />
                {errors.num_of_pages && (
                  <small className='text-danger' style={{ marginTop: '5px' }}>
                    Please enter the length of the book
                  </small>
                )}
              </div>
              <div className='col-md-6 mb-3 '>
                <label htmlFor='' className='label'>
                  Book type
                </label>
                <div
                  className='file-container'
                  style={{ position: 'relative' }}
                >
                  <input
                    readOnly
                    type='text'
                    value={type}
                    placeholder=''
                    name='book_type'
                    ref={register({ required: true })}
                    className='publish-input form-control'
                  />

                  <button
                    type='button'
                    className='btn btn-info choose-file dropdown-toggle'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    Book
                  </button>
                  <div className='dropdown-menu'>
                    <button
                      type='button'
                      className='dropdown-item'
                      onClick={() => setType('Book')}
                    >
                      Book
                    </button>
                    <button
                      type='button'
                      className='dropdown-item'
                      onClick={() => setType('Comic')}
                    >
                      Comic
                    </button>
                    <button
                      type='button'
                      className='dropdown-item'
                      onClick={() => setType('Magazine')}
                    >
                      Magazine
                    </button>
                  </div>
                </div>
                {errors.book_type && (
                  <small className='text-danger' style={{ marginTop: '5px' }}>
                    Please select the type of book you're uploading
                  </small>
                )}
              </div>
            </div>

            <div className='mb-3'>
              <label className='form-control-label'>Content Restriction</label>
              <div className='file-container' style={{ position: 'relative' }}>
                <input
                  type='text'
                  className='publish-input form-control'
                  readOnly
                  name='country_restriction'
                  value={restricted.country_name}
                  ref={register}
                />
                <button
                  type='button'
                  className='btn btn-info choose-file'
                  onClick={() => setShow(!show)}
                >
                  Add
                </button>
              </div>
            </div>

            <div className='row mb-5'>
              <div className='col'>
                <label htmlFor='' className='label'>
                  Book Synopsis (About) *
                </label>
                <textarea
                  rows='6'
                  type='text'
                  name='synopsis'
                  ref={register({
                    required: true,
                    minLength: 150,
                    maxLength: 1000,
                  })}
                  defaultValue={synopsis}
                  className='publish-input form-control'
                  placeholder='Copy and Paste your synopsis here.'
                ></textarea>
                <small style={{ color: 'darkgreen', marginTop: '5px' }}>
                  This should be between 100-150 words
                </small>
                <br />
                {errors.synopsis && (
                  <small className='text-danger' style={{ marginTop: '5px' }}>
                    Please enter the book synopsis
                  </small>
                )}
              </div>
            </div>

            <div className='row justify-content-around'>
              <button type='submit' className='wizard-button primary'>
                Add Book
              </button>
            </div>
          </div>
        </form>
      </div>
      <Modal
        show={show}
        setShow={setShow}
        header='Content restriction'
        action={() => setShow(false)}
      >
        <div style={{ maxHeight: '60vh', overflowY: 'scroll' }}>
          <p>Check to region to restrict access</p>
          <CountryPicker
            country={country}
            selected={restricted}
            setSelected={setRestricted}
            save={show}
          />
        </div>
      </Modal>
      <Modal
        show={open}
        setShow={setOpen}
        header='Pick 3 Genres'
        action={() => setOpen(false)}
      >
        <GenrePicker data={genres} />
      </Modal>
      <Modal
        show={view}
        setShow={setView}
        header='Choose Author'
        action={() => setView(false)}
      >
        <AsyncCreatableSelect
          isMulti
          name='author'
          cacheOptions
          loadOptions={getAuthors}
          onChange={setAuthor}
          className='select'
        />
      </Modal>
    </>
  );
}
