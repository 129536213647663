import {
  CONTENTRECIEVED,
  AUTHORSRECIEVED,
  BOOKREPORTGENERATED,
  FINANCEREPORTGENERATED,
  DASHBOARDDATARECIEVED,
  GENRESELECTED,
  BANKSRECIEVED,
  CLEARGENRE,
  LOGOUT,
} from '../actions/Types';
import storage from '../components/helpers/StorageHelper';

const Initial = storage.get('content') || {
  books: {
    data: [],
  },
  authors: {
    data: [],
  },
  reports: {
    book: [],
    finance: [],
  },
  dashboard: {},
  genres: [],
  banks: [],
};

export default (state = Initial, action) => {
  let data = {};
  const { type, payload } = action;

  switch (type) {
    case CONTENTRECIEVED:
      data =
        payload.type === 'new'
          ? {
              ...state,
              books: { ...payload },
            }
          : {
              ...state,
              books: { ...payload, data: [...state.books.data, ...payload.data] },
            };
      storage.store('content', data);
      return data;
    case AUTHORSRECIEVED:
      data =
        payload.type === 'new'
          ? {
              ...state,
              authors: { ...payload },
            }
          : {
              ...state,
              authors: { ...payload, data: [...state.authors.data, ...payload.data] },
            };
      storage.store('content', data);
      return data;
    case BOOKREPORTGENERATED:
      data = {
        ...state,
        reports: {
          ...state.reports,
          book: payload,
        },
      };
      storage.store('content', data);
      return data;
    case FINANCEREPORTGENERATED:
      data = {
        ...state,
        reports: {
          ...state.reports,
          finance: payload,
        },
      };
      storage.store('content', data);
      return data;
    case DASHBOARDDATARECIEVED:
      data = {
        ...state,
        dashboard: payload,
      };
      storage.store('content', data);
      return data;
    case GENRESELECTED:
      if (state.genres.some((e) => e.name === payload.name)) {
        return {
          ...state,
          genres: state.genres.filter((e) => e.name !== payload.name),
        };
      }
      return {
        ...state,
        genres: [...state.genres, payload],
      };
    case CLEARGENRE:
      return {
        ...state,
        genres: [],
      };
    case BANKSRECIEVED:
      return {
        ...state,
        banks: payload,
      };
    case LOGOUT:
      return {
        books: {
          data: [],
        },
        authors: {
          data: [],
        },
        reports: {
          book: [],
          finance: [],
        },
        dashboard: {},
        genres: [],
        banks: [],
      };
    default:
      return { ...state };
  }
};
