import React from 'react';
import { useSpring, animated, useTransition } from 'react-spring'

export default function Modal({ show, setShow, header, action, children }) {
  const transitions = useTransition(show, null, {
    from: { position: 'fixed', zIndex: '1500', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0, position: 'static' },
  })

  const main = useSpring({
    transform: show ? `translateY(0)` : `translateY(-200%)`,
  })

  const bgAnimated = useSpring({
    opacity: show ? 1 : 0
  })

  // eslint-disable-next-line no-undef
  document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') setShow(false);
  });

  return transitions.map(({ item, key, props }, i) => item && (
    <div key={i}>
      <animated.div key={key} style={props}>
        <div
          className={'modal'}
          style={{ display: 'block' }}
          onClick={() => setShow(false)}
        >
          <animated.div className="modal-dialog modal-lg" onClick={(e) => e.stopPropagation()} style={main}>
            <div className="modal-content">
              {
                header ?
                  <div className="modal-header">
                    <h4 className="modal-title font-weight-bold">{header}</h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      onClick={() => setShow(false)}
                    >
                      <span aria-hidden="true">×</span>
                      <span className="sr-only">close</span>
                    </button>
                  </div> : ''
              }
              <div className="modal-body">
                <div className="container">
                  {children}
                </div>
              </div>
              {
                action ?
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-shadow"
                      onClick={() => setShow(false)}
                    >
                      Close
                      </button>
                    <button type="button" className="btn btn-primary" onClick={action}>
                      Submit
                    </button>
                  </div> : ''
              }
             </div>
          </animated.div>
        </div>
      </animated.div>
      <animated.div
        style={bgAnimated}
        className="modal-backdrop"
      />
    </div>
  ))
}