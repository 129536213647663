import storage from '../components/helpers/StorageHelper';
import {
  USERLOGGEDIN,
  LOGOUT,
  USERUPDATED,
  BANKDETAILSRECIEVED,
  PASSWORDCHANGED,
} from '../actions/Types';

const initial = storage.get('user') || {};

export default (state = initial, action) => {
  let data = {};
  switch (action.type) {
    case USERLOGGEDIN:
      storage.store('token', action.payload.token);
      storage.store('refresh_token', action.payload.refresh_Token);
      storage.store('user', { ...action.payload });
      return {
        ...action.payload,
      };
    case USERUPDATED:
      storage.store('user', { ...state, ...action.payload });
      return {
        ...state,
        ...action.payload,
      };
    case BANKDETAILSRECIEVED:
      data = {
        ...state,
        bank: action.payload,
      };
      storage.store('user', data);
      return data;
    case PASSWORDCHANGED:
      data = {
        ...state,
        change_password: false,
      };
      storage.store('user', data);
      return data;
    case LOGOUT:
      storage.clear();
      return {};
    default:
      return state;
  }
};
