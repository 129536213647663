/* eslint-disable react/no-unescaped-entities */
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect, Link, useLocation } from 'react-router-dom';

// Components
import Home from './Home';
import FAQs from './FAQs';
import Modal from './Modal';
import Header from './Header';
import Spinner from './Spinner';
import Settings from './Settings';
import ListBooks from './Books/ListBooks';
import EditBooks from './Books/EditBooks';
import CreateBook from './Books/CreateBook';
import ErrorBoundary from './ErrorBoundary';
import ViewAuthor from './Authors/ViewAuthor';
import BookReports from './Reports/BookReports';
import ListAuthors from './Authors/ListAuthors';
import FinanceReports from './Reports/FinanceReports';
import { sendSupportMessage } from '../actions/Content';

export default function AppMain() {
  const loc = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register, errors } = useForm();
  const { username, change_password } = useSelector((s) => s.User || {});
  document.getElementById('page-top').className = '';

  if (!username) return <Redirect to='/login' />;
  if (change_password && !loc.pathname.includes('settings'))
    return <Redirect to='/settings' />;

  const submit = (v) => {
    if (!v.bubbles) {
      setLoading(true);
      sendSupportMessage(
        v,
        (s, m) => {
          if (s) {
            toast.success('Support message sent successfully', {
              hideProgressBar: true,
            });
            setLoading(false);
            setModal(false);
          } else {
            toast.error(m);
            setLoading(false);
          }
        },
        dispatch,
      );
    }
  };

  return (
    <ErrorBoundary>
      <Header setOpen={setOpen} open={open} setModal={setModal} />
      <div className={`content-inner ${open ? '' : 'active'} modal-open`}>
        <div className='container-fluid'>
          <Switch>
            <Route path='/authors/view' component={ViewAuthor} />
            <Route path='/authors' component={ListAuthors} />
            <Route path='/books/add' component={CreateBook} />
            <Route path='/books/edit' component={EditBooks} />
            <Route path='/books' component={ListBooks} />
            <Route path='/reports/book' component={BookReports} />
            <Route path='/reports/finance' component={FinanceReports} />
            <Route path='/faqs' component={FAQs} />
            <Route path='/settings' component={Settings} />
            <Route path='/' exact component={Home} />
            <Route render={() => <Redirect to='/404' />} />
          </Switch>
        </div>
        <div
          style={{
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100%',
            position: 'fixed',
            display: !open ? 'block' : 'none',
          }}
          onClick={() => setOpen(!open)}
        />
        <Modal
          header='Contact Bambooks Support'
          show={modal}
          setShow={setModal}
          action={handleSubmit(submit)}
        >
          {loading ? (
            <div>
              <Spinner />
            </div>
          ) : (
            <>
              <div className='row mb-5 mt-4'>
                <div className='col-md-12'>
                  <select
                    name='category'
                    className='custom-select form-control'
                    ref={register({ required: true })}
                  >
                    <option value=''>Select Problem Category</option>
                    <option value='Change Password'>Change Password</option>
                    <option value='Upload New Book'>Upload New Book</option>
                    <option value='Get status of uploaded new book'>
                      Get status of uploaded new book
                    </option>
                    <option value='Change Account Number'>
                      Change Account Number
                    </option>
                  </select>
                  {errors.category && (
                    <small className='text-danger' style={{ marginTop: '5px' }}>
                      Please select the category of the problem you're facing.
                    </small>
                  )}
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  <div className='form-group row d-flex align-items-center mb-5'>
                    <textarea
                      rows='10'
                      name='comment'
                      className='form-control'
                      placeholder='Type your message here ...'
                      required=''
                      ref={register({ required: true })}
                    ></textarea>
                    {errors.book_type && (
                      <small
                        className='text-danger'
                        style={{ marginTop: '5px' }}
                      >
                        Please add additional comments
                      </small>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal>
        {/* Begin Page Footer*/}
        <footer className='main-footer'>
          <div className='row'>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-xl-start justify-content-lg-start justify-content-md-start justify-content-center'>
              <p className='text-gradient-02'>&copy; Bambooks Limited</p>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-center'>
              <ul className='nav'>
                <li className='nav-item'>
                  <a className='nav-link' target='_blank' href='/Bambooks_Service_Brief.pdf'>
                    Service guide
                  </a>
                </li>
                <li className='nav-item'>
                  <Link to='/content-policy' className='nav-link'>
                    Content Policy
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/terms' className='nav-link'>
                    Terms of Service
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/privacy-policy' className='nav-link'>
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </div>
    </ErrorBoundary>
  );
}
