import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { errorInfo: null };
  }

  componentDidCatch(_, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    const { children } = this.props;
    const { errorInfo } = this.state;
    if (errorInfo) {
      // Error path
      return (
        <div className="container">
          <h2>Oops!... An error occurred</h2>
          <p>
            {errorInfo}
          </p>
          <div style={{ whiteSpace: 'pre-wrap' }}>
            <Link to="/">
              <button
                type="button"
                onClick={() => {
                  this.setState({ errorInfo: null });
                }}
              >
                Remove error
              </button>
            </Link>
          </div>
        </div>
      );
    }
    // Normally, just render children
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
};
