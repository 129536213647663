import { nanoid } from 'nanoid';
import { chunkUpload, multipartUpload } from '../actions/Content';

export const readFile = (file, type, callback) => {
  if (typeof file === 'object') {
    const reader = new FileReader();

    reader.addEventListener('loadend', (e) => callback(e, file));

    if (type === 'url') reader.readAsDataURL(file);
    if (type === 'buffer') reader.readAsArrayBuffer(file);
  }
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export class ChunkUploader {
  constructor() {
    this.start = 0;
    this.currentIndex = 0;
    this.chunkSize = 5250880;
  }

  async createChunk(file) {
    let chunkEnd = Math.min(this.start + this.chunkSize, file.size);

    const chunk = {
      chunkIndex: this.currentIndex,
      chunk: file.slice(this.start, chunkEnd),
    };

    this.currentIndex++;
    this.start += this.chunkSize;

    return chunk;
  }

  async uploadFiles(file, setFiles) {
    file.failed = false;
    file.uploading = true;
    this.currentIndex = file.chunksUploaded;
    this.start = this.chunkSize * file.chunksUploaded;
    const chunks = Math.ceil(file.size / this.chunkSize);

    if (file.size >= this.chunkSize) {
      const formData = new FormData();

      formData.append('file_size', file.size);
      formData.append('chunkMax', `${chunks}`);
      formData.append('fileName', parseTitle(`${nanoid()}-${file.name}`));

      while (this.currentIndex < chunks) {
        try {
          const { chunk, chunkIndex } = await this.createChunk(file);

          formData.delete('stream');
          formData.delete('uploadId');
          formData.delete('prevETags');
          formData.delete('chunkIndex');

          formData.append('stream', chunk);
          formData.append('prevETags', file.eTags);
          formData.append('uploadId', file.uploadId);
          formData.append('chunkIndex', `${chunkIndex}`);

          const res = await chunkUpload(formData, (e) => {
            if (e.lengthComputable) {
              file.progress =
                ((e.loaded + this.start - this.chunkSize) / file.size) * 100;
              setFiles(file);
            }
          });

          if (!res.error_message) {
            file.chunksUploaded = this.currentIndex;
            if (res.eTags) file.eTags = `${res.eTags}`;
            if (res.uploadId) file.uploadId = res.uploadId;
            if (res.url) {
              file.path = res.url;
              file.completed = true;
              file.uploading = false;
              setFiles(file);
            }
          } else {
            throw new Error('Upload failed');
          }
        } catch (e) {
          file.failed = true;
          this.currentIndex = chunks;
          setFiles(file);

          break;
        }
      }

      this.start = 0;
      this.currentIndex = 0;
    } else {
      const formData = new FormData();
      formData.append('stream', file, file.name);

      const upload = await multipartUpload(formData, (e) => {
        if (e.lengthComputable) {
          file.progress = (e.loaded / e.total) * 100;
          setFiles(file);
        }
      });

      if (upload.status === 'success') {
        file.completed = true;
        file.uploading = false;
        file.path = upload.data;
        setFiles(file);
      } else {
        file.failed = true;
        setFiles(file);
      }
    }

    return;
  }
}

export const parsedBookName = (v) => {
  return v
    ? v
        .trim()
        .toLowerCase()
        .replace(/[^a-zA-Z0-9 ]/g, '')
        .replaceAll(' ', '-')
    : '';
};

export const parseTitle = (v) => v.replaceAll(' ', '_');
