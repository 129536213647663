import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

// Images
import logo from '../../assets/images/logo-text.png';
import { createPassword } from '../../actions/Auth';

export default function CreatePassword() {
  const history = useHistory();
  const dispatch = useDispatch();
  const partner_id = 1 * localStorage.getItem('id');

  if (!partner_id) history.push('/register');

  const { handleSubmit, errors, register, watch } = useForm();
  const pw = watch('password');
  const [loading, setLoading] = useState();
  document.getElementById('page-top').className = 'bg-fixed-02';

  const changePass = (v) => {
    setLoading(true);
    createPassword(
      {
        partner_id,
        password: v.password,
      },
      (status) => {
        if (status) {
          setLoading(false);
          document.getElementById('modal-opener').click();
        }
      },
      dispatch,
    );
  };

  return (
    <>
      {/* Begin Container */}
      <div className="container-fluid h-100 overflow-y">
        <div className="row flex-row h-100">
          <div className="col-12 my-auto">
            <div className="password-form mx-auto">
              <div className="logo-centered">
                <img style={{ marginBottom: '20px' }} src={logo} alt="logo" width="100%" />
              </div>
              <div>
                <p className="text-center">Welcome!</p>
              </div>
              <h3 className="lead font-weight-bold mb-5">Create a new password</h3>
              <form onSubmit={handleSubmit(changePass)}>
                {loading ? (
                  <div className="spinner"></div>
                ) : (
                  <>
                    <div className="group material-input">
                      <input
                        className="mt-5"
                        type="password"
                        required
                        name="password"
                        ref={register({
                          required: true,
                        })}
                      />
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <label>Enter your new password</label>
                    </div>
                    <div className="group material-input">
                      <input
                        className="mt-5"
                        type="password"
                        required
                        name="confirm"
                        ref={register({
                          required: true,
                          validate: (v) => v === pw,
                        })}
                      />
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <label>Confirm your new password</label>
                      {errors.confirm && <span className="text-danger">Passwords must match</span>}
                    </div>
                    <div className="button text-center mt-5">
                      <button type="submit" className="btn btn-lg btn-gradient-01 text-light">
                        Proceed
                      </button>
                    </div>
                  </>
                )}
              </form>
              <Link
                data-toggle="modal"
                data-target="#success-modal"
                className="btn btn-lg btn-gradient-01 text-light"
                id="modal-opener"
                hidden
              >
                Proceed
              </Link>
            </div>
          </div>
          {/* End Col */}
        </div>
        {/* End Row */}

        {/* Begin Success Modal */}
        <div id="success-modal" className="modal fade">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <div className="sa-icon sa-success animate" style={{ display: 'block' }}>
                  <span className="sa-line sa-tip animateSuccessTip"></span>
                  <span className="sa-line sa-long animateSuccessLong"></span>
                  <div className="sa-placeholder"></div>
                  <div className="sa-fix"></div>
                </div>
                <div className="section-title mt-5 mb-2">
                  <h2 className="text-gradient-02">Great!</h2>
                </div>
                <p className="mb-5">Your password was created successfully</p>
                <form action="/">
                  <button type="submit" className="btn btn-shadow mb-3">
                    Launch profile
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* End Success Modal */}
      </div>
      {/* End Container */}
    </>
  );
}
