import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import SelectFiles from './Step2';
import FillBookDetails from './Step1';
import UploadSuccessful from './step4';

function CreateBook() {
  const [tab, setTab] = useState(1);
  const [book, setBook] = useState({});
  const saveBook = (data) => {
    setBook(data);
    setTab(tab + 1);
  };

  return (
    <>
      <div className='col-xl-12'>
        <div className='flex mb-3'>
          <h2>Upload new book</h2>
          <div>
            <Link to='/books'> {'<'} back to book list</Link>
          </div>
        </div>
        <div className='widget'>
          <div className='widget-header col-xl-11 mh-auto bordered no-actions'>
            <div className='flex'>
              <div className='f-grow center'>
                <div className='p-rel'>
                  <div className={'full line p-abs green-bg'} />
                  <span className='line-num mh-auto p-rel green'>1</span>
                </div>
                <span className='green'>Step 1</span>
              </div>
              <div className='f-grow center'>
                <div className='p-rel'>
                  <div
                    className={`full line p-abs ${
                      tab >= 2 ? 'green-bg' : 'grey-bg'
                    }`}
                  />
                  <span
                    className={`line-num mh-auto p-rel ${tab >= 2 && 'green'} `}
                  >
                    2
                  </span>
                </div>
                <span className={`${tab >= 2 && 'green'}`}>Step 2</span>
              </div>
              <div className='f-grow center'>
                <div className='p-rel'>
                  <div
                    className={`full line p-abs ${
                      tab === 3 ? 'green-bg' : 'grey-bg'
                    }`}
                  />
                  <span
                    className={`line-num mh-auto p-rel ${tab === 3 && 'green'}`}
                  >
                    3
                  </span>
                </div>
                <span className={`${tab === 3 && 'green'}`}>Step 3</span>
              </div>
            </div>
          </div>
          <div className='widget-body'>
            <div className='row flex-row justify-content-center'>
              <div className='col-xl-11'>
                <div id='rootwizard'>
                  <div className='tab-content'>
                    <div className='tab-pane active show'>
                      <FillBookDetails
                        hidden={tab !== 1}
                        setValues={saveBook}
                      />
                      {tab === 2 && (
                        <SelectFiles
                          book={book}
                          next={setTab}
                          hidden={tab !== 2}
                        />
                      )}
                      <UploadSuccessful hidden={tab !== 3} setTab={setTab} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateBook;
