import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { PasswordInput } from '../../Login';

export default function UserInfo({ nextStep }) {
  const { handleSubmit, register, watch, errors } = useForm();

  const createUser = (v) => {
    nextStep(v);
  };

  return (
    <>
      <div className='wizard-form px-md-5 py-5'>
        <div className='row mb-5'>
          <div className='col'>
            <h1 className='form-title'>Enter your personal details</h1>
          </div>
        </div>
        <form onSubmit={handleSubmit(createUser)}>
          <div className='row mb-5 fold-x'>
            <div className='col-md-6 space-x'>
              <label className='label'>Name *</label>
              <input
                placeholder='Enter Your Full Name'
                name='name'
                className='publish-input form-control'
                ref={register({
                  required: true,
                })}
              />
            </div>
            <div className='col-md-6'>
              <label className='label'>Email *</label>
              <input
                type='email'
                placeholder='Enter Your Email Address'
                name='username'
                className='publish-input form-control'
                ref={register({
                  required: true,
                  pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                })}
              />
            </div>
          </div>

          <div className='row mb-5'>
            <div className='col-md-6 space-x'>
              <label className='label'>Phone Number *</label>
              <input
                name='contactPhone'
                placeholder='Enter Your Phone Number'
                className='publish-input form-control'
                ref={register({
                  required: true,
                })}
              />
            </div>
            <div className='col-md-6 space-x'>
              <label className='label'>Address *</label>
              <input
                type='text'
                name='address'
                placeholder='Enter your address'
                className='publish-input form-control'
                ref={register({
                  required: true,
                })}
              />
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-md-6 space-x'>
              <label className='label'>Password *</label>
              <PasswordInput
                type={2}
                name='password'
                register={register}
                label='Enter your password'
                errors={errors}
                msg='Password must be at least 6 characters long'
              />
            </div>
            <div className='col-md-6 space-x'>
              <label className='label'>Confirm Password *</label>
              <PasswordInput
                type={2}
                name='confirm'
                register={register}
                label='Confirm your password'
                errors={errors}
                msg='Confirmation must match password'
                val={{
                  validate: (v) => v === watch('password'),
                }}
              />
            </div>
          </div>
          <div className='row justify-content-center'>
            <button type='submit' className='wizard-button primary'>
              Proceed
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

UserInfo.propTypes = {
  nextStep: PropTypes.element.isRequired,
};
