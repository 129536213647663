import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, Link } from 'react-router-dom';

// Images
import logo from '../assets/images/logo-text.png';
import { forgotPassword } from '../actions/Auth';

function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { handleSubmit, errors, register } = useForm();

  document.getElementById('page-top').className = 'bg-fixed-02';

  const reset = (v) => {
    setLoading(true);
    forgotPassword(v, (state) => {
      if (state)
        history.push('/confirm', {
          email: v.username,
        });
      if (!state) setLoading(false);
    });
  };

  return (
    <>
      {/* Begin Container */}
      <div
        className='container-fluid h-100 overflow-y'
        style={{ padding: 'unset' }}
      >
        <div className='row flex-row h-100'>
          <div className='col-12 my-auto'>
            <div className='password-form mx-auto'>
              <div className='logo-centered'>
                <Link to='/'>
                  <img src={logo} alt='logo' width='100%' />
                </Link>
              </div>
              <h3>Password Recovery</h3>
              {loading ? (
                <div className='spinner'></div>
              ) : (
                <form onSubmit={handleSubmit(reset)}>
                  <div className='group material-input'>
                    <input
                      type='email'
                      required
                      name='username'
                      ref={register({
                        required: true,
                        pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                      })}
                    />
                    <span className='highlight'></span>
                    <span className='bar'></span>
                    <label>Email</label>
                  </div>
                  {errors.username && (
                    <span className='text-danger'>
                      Please enter a valid email
                    </span>
                  )}
                  <div className='button text-center'>
                    <button
                      type='submit'
                      className='btn btn-lg btn-gradient-01'
                    >
                      Reset Password
                    </button>
                  </div>
                  <div className='back'>
                    <Link to='/login'>Back to Login</Link>
                  </div>
                </form>
              )}
            </div>
          </div>
          {/* End Col */}
        </div>
        {/* End Row */}
      </div>
      {/* End Container */}
    </>
  );
}

export default ResetPassword;
