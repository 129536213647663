import React from 'react';
import { Link, useLocation, Redirect } from 'react-router-dom';

function ConfirmPassword() {
  const loc = useLocation();
  document.getElementById('page-top').className = 'bg-fixed-02';

  if (!loc.state) return <Redirect to='/reset' />;

  return (
    <>
      {/* Begin Container */}
      <div
        className='container-fluid h-100 overflow-y'
        style={{ padding: 'unset' }}
      >
        <div className='row flex-row h-100'>
          <div className='col-12 my-auto'>
            <div className='mail-confirm mx-auto'>
              <div className='animated-icon'>
                <div className='gradient'></div>
                <div className='icon'>
                  <i className='la la-envelope'></i>
                </div>
              </div>
              <h3>Check your mail box!</h3>
              <p>
                A password reset email has been sent to{' '}
                <Link>{loc.state.email}</Link>
              </p>
              <p>
                Check your inbox and click on the link to reset your password.
              </p>
              <div className='button text-center'>
                <Link to='/login' className='btn btn-lg btn-gradient-01'>
                  Back to Login
                </Link>
              </div>
            </div>
          </div>
          {/* End Col */}
        </div>
        {/* End Row */}
      </div>
      {/* End Container */}
    </>
  );
}

export default ConfirmPassword;
