import React, { useState } from 'react';
import qs from 'query-string';
import { useForm } from 'react-hook-form';
// Images
import logo from '../assets/images/logo-text.png';
import { resetPassword, setPassword } from '../actions/Auth';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PASSWORDCHANGED } from '../actions/Types';
import { PasswordInput } from './Login';

function ChangePassword() {
  const loc = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const q = qs.parse(loc.search);
  const [loading, setLoading] = useState();
  const { handleSubmit, errors, register, watch } = useForm();
  const pw = watch('password');
  document.getElementById('page-top').className = 'bg-fixed-02';

  const changePass = (v) => {
    setLoading(true);
    if (q.reset) {
      resetPassword(
        {
          token: q.reset,
          password: v.password,
        },
        (s) => {
          if (s) {
            document.getElementById('modal-opener').click();
          } else {
            toast.error('Failed to reset password');
          }
          setLoading(false);
        },
        dispatch,
      );
    } else {
      delete v.confirm;
      setPassword(
        v,
        (status) => {
          console.log(status);
          if (status) {
            dispatch({ type: PASSWORDCHANGED });
            document.getElementById('modal-opener').click();
            toast.success('Password changed successfully');
          } else {
            toast.error('Failed to change password');
          }
          setLoading(false);
        },
        dispatch,
      );
    }
  };

  return (
    <>
      {/* Begin Container */}
      <div className='container-fluid h-100 overflow-y'>
        <div className='row flex-row h-100'>
          <div className='col-12 my-auto'>
            <div className='password-form mx-auto'>
              <div className='logo-centered'>
                <img
                  style={{ marginBottom: '20px' }}
                  src={logo}
                  alt='logo'
                  width='100%'
                />
              </div>
              <div>
                <p className='text-center'>Welcome!</p>
              </div>
              <h3 className='lead font-weight-bold mb-5'>
                Create a new password
              </h3>
              <form onSubmit={handleSubmit(changePass)}>
                {loading ? (
                  <div className='spinner' />
                ) : (
                  <>
                    {!q.reset && (
                      <>
                        <div className='group material-input'>
                          <input
                            className='mt-5'
                            type='email'
                            required
                            name='email'
                            ref={register({
                              required: true,
                            })}
                          />
                          <span className='highlight'></span>
                          <span className='bar'></span>
                          <label>Email</label>
                        </div>
                        <PasswordInput
                          name='old_password'
                          register={register}
                          label='Current Password'
                        />
                      </>
                    )}
                    <PasswordInput
                      name='password'
                      register={register}
                      label='Enter your new password'
                    />
                    <PasswordInput
                      name='confirm'
                      register={register}
                      label='Confirm your new password'
                      errors={errors}
                      msg='Passwords must match'
                      val={{
                        validation: (v) => v === pw,
                      }}
                    />
                    <div className='button text-center mt-5'>
                      <button
                        type='submit'
                        className='btn btn-lg btn-gradient-01 text-light'
                      >
                        Proceed
                      </button>
                    </div>
                  </>
                )}
              </form>
              <button
                data-toggle='modal'
                data-target='#success-modal'
                className='btn btn-lg btn-gradient-01 text-light'
                id='modal-opener'
                hidden
              >
                Proceed
              </button>
            </div>
          </div>
          {/* End Col */}
        </div>
        {/* End Row */}

        {/* Begin Success Modal */}
        <div id='success-modal' className='modal fade'>
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
              <div className='modal-body text-center'>
                <div
                  className='sa-icon sa-success animate'
                  style={{ display: 'block' }}
                >
                  <span className='sa-line sa-tip animateSuccessTip'></span>
                  <span className='sa-line sa-long animateSuccessLong'></span>
                  <div className='sa-placeholder'></div>
                  <div className='sa-fix'></div>
                </div>
                <div className='section-title mt-5 mb-2'>
                  <h2 className='text-gradient-02'>Great!</h2>
                </div>
                <p className='mb-5'>Your password was created successfully</p>
                <form action='/'>
                  <button
                    type='submit'
                    className='btn btn-shadow mb-3'
                    onClick={() => {
                      history.push('/');
                    }}
                  >
                    Launch profile
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* End Success Modal */}
      </div>
      {/* End Container */}
    </>
  );
}

export default ChangePassword;
