import Axios from 'axios';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { animated, useTransition } from 'react-spring';

import Share from './Share';
import { parsedBookName } from '../Helpers';

function BookCard({ book, view, id, setId, setComments }) {
  const [img, setImg] = useState('');
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [copied, setCopied] = useState(false);

  const transitions = useTransition(open, null, {
    from: { opacity: 0, height: 0, overflow: 'hidden' },
    enter: { opacity: 1, height: 'auto' },
    leave: { opacity: 0, height: 0, overflow: 'hidden' },
  });

  const msg = 'Read my book on bambooks';
  const base = 'https://bambooks.io/book/share';
  const { name = '', cover_path = '', status, content_id } = book;
  const link = `${base}/${parsedBookName(name)}/${content_id}`;

  useEffect(() => {
    if (cover_path && cover_path.includes('https')) {
      Axios.get(
        `https://bdplive-be.bambooks.io/api/getfile?filename=${
          cover_path.split('/bambooks/')[1]
        }`,
      )
        .then((res) => setImg(`data:image/png;base64,${res.data}`))
        .catch(() => '');
    } else {
      setImg(
        `https://admin.bambooks.io/Public/files/cover/${
          book.content.logo || (cover_path && cover_path)
        }`,
      );
    }
  }, [cover_path, book.content.logo]);

  const onCopy = () => {
    var copyText = document.getElementById(`share${id}`);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  return (
    <div className=' col-md-4 col-sm-6 col-remove'>
      {/* Begin Card */}
      <div className='widget widget-19 has-shadow'>
        {/* Begin Widget Header */}
        <div className='widget-header bordered d-flex align-items-center'>
          <h2
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            onClick={() => view({ ...book, img })}
          >
            {name}
          </h2>
          <div className='widget-options'>
            <div className='dropdown'>
              <button
                type='button'
                className='dropdown-toggle'
                onClick={() => setOpen(!open)}
              >
                <i className='la la-ellipsis-h font-weight-bold'></i>
              </button>
              <div
                style={{
                  top: '0px',
                  left: '0px',
                  width: '100%',
                  height: '100%',
                  position: 'fixed',
                  display: open ? 'block' : 'none',
                }}
                onClick={() => setOpen(!open)}
              />
              {transitions.map(
                ({ item, key, props }) =>
                  item && (
                    <animated.div key={key} style={props}>
                      <div
                        className='dropdown-menu'
                        style={{
                          top: '0px',
                          left: '0px',
                          zIndex: 1500,
                          display: 'block',
                          overflow: 'hidden',
                          position: 'absolute',
                          willChange: 'transform',
                          transform: 'translate3d(-130px, 36px, 0px)',
                        }}
                      >
                        <span
                          className='dropdown-item pointer'
                          onClick={() => {
                            setOpen(!open);
                            view({ ...book, img });
                          }}
                        >
                          <i className='la la-book'></i>Book Details
                        </span>
                        <Link
                          to={{
                            pathname: status !== 'in review' && '/books/edit',
                            state: book,
                          }}
                          className={`dropdown-item ${
                            status === 'in review' && 'noHover'
                          }`}
                          // className={'dropdown-item noHover'}
                        >
                          <i className='la la-edit'></i>Edit
                        </Link>
                        <span
                          className={`dropdown-item pointer ${
                            status !== 'pending' && 'noHover'
                          }`}
                          onClick={() => setId(book.id)}
                        >
                          <i className='la la-trash'></i>Remove
                        </span>
                      </div>
                    </animated.div>
                  ),
              )}
            </div>
          </div>
        </div>
        {/* End Widget Header */}
        <div className='widget-body p-0'>
          <div className='d-flex'>
            <div className='book-image-container' style={{ width: '40%' }}>
              <span
                className='pointer'
                data-toggle='modal'
                data-target='#book-details-modal'
                href='#'
              >
                <img
                  style={{ width: '100%' }}
                  src={img}
                  alt={`${name} cover`}
                  onClick={() => view({ ...book, img })}
                />
              </span>
            </div>
            <div className='form-group flex-grow-1 row mt-3 mr-0 mb-3 ml-0'>
              <div className='col-xl-12'>
                <p>
                  Status:{' '}
                  {status === 'approved' && (
                    <b className='text-success status'>{status}</b>
                  )}
                  {(status === 'pending' || !status) && (
                    <b className='text-warning status'>{status || 'Pending'}</b>
                  )}
                  {status === 'awaiting approval' && (
                    <b className='text-warning status'>{status}</b>
                  )}
                  {(status === 'rejected' || status === 'action required') && (
                    <b className='text-danger status'>{status}</b>
                  )}
                  {status === 'in review' && (
                    <b className='text-info status'>{status}</b>
                  )}
                </p>
                <input
                  readOnly
                  type='text'
                  id={`share${id}`}
                  className='w-100 form-control'
                  value={status === 'approved' ? link : ''}
                  placeholder='Link wil be here'
                />
                <div className='text-left mt-4 mb-3'>
                  {status === 'rejected' || status === 'action required' ? (
                    <button
                      type='button'
                      className='btn btn-outline-secondary btn-width'
                      onClick={() => setComments(book.publisherContentComments)}
                    >
                      <i className='la la-th-list'></i> View Reasons
                    </button>
                  ) : (
                    <button
                      type='button'
                      className='btn btn-outline-secondary btn-width'
                      disabled={status !== 'approved'}
                      onClick={onCopy}
                    >
                      <i className='la la-copy'></i>{' '}
                      {copied ? 'Copied' : 'Copy Link'}
                    </button>
                  )}
                  <br />
                  <br />
                  <button
                    type='button'
                    disabled={status !== 'approved'}
                    onClick={() => setShow(!show)}
                    className='btn btn-outline-secondary btn-width'
                    style={{ zIndex: 100 }}
                  >
                    <i className='la la-share'></i> Share Book
                  </button>
                  <Share link={link} msg={msg} open={show} close={setShow} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Card */}
    </div>
  );
}

export default BookCard;
