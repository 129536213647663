import React from 'react';
import { connect } from 'react-redux';

function FAQs() {
  return (
    <>
      <div className="jumbotron jumbotron-fluid d-flex justify-content-center align-items-center text-center">
        <div className="container">
          <h2>Help Center</h2>
        </div>
      </div>
      <div className="row">
        <div className="page-header">
          <div className="d-flex align-items-center">
            <h2 className="page-header-title">FAQs</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="widget has-shadow">
            <div className="row">
              <div className="col-xl-3">
                <div className="widget">
                  <div className="widget-body">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a className="nav-link" href="#Introduction">
                          <i className="la la-user la-2x align-middle pr-2"></i>Intro
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#Payouts">
                          <i className="la la-puzzle-piece la-2x align-middle pr-2"></i>Payouts
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#Books">
                          <i className="la la-spinner la-2x align-middle pr-2"></i>Books
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-9">
                <div className="widget">
                  <div className="widget-body pt-2">
                    {/* Begin Accordion */}
                    <div id="accordion" className="accordion">
                      {/* Begin Widget */}
                      <div className="widget" id="Introduction">
                        <div className="widget-header no-actions d-flex align-items-center">
                          <h2>Introduction</h2>
                        </div>
                        <a
                          className="card-header collapsed d-flex align-items-center"
                          data-toggle="collapse"
                          href="#Introduction_1"
                        >
                          <div className="card-title">How Do I Change My Password?</div>
                        </a>
                        <div
                          id="Introduction_1"
                          className="card-body bg-grey collapse"
                          data-parent="#accordion"
                        >
                          <p>
                            To change your password, please click on the &apos;Forgot Password&apos; on the
                            login page. Enter the email linked to your profile and click on &apos;Reset
                            Password&apos;.A password reset email will then be sent to your email. Check
                            your inbox and click on the link to retrieve your password.
                          </p>
                        </div>
                      </div>
                      {/* End Widget */}
                      {/* Begin Widget */}
                      <div className="widget" id="Payouts">
                        <div className="widget-header no-actions d-flex align-items-center">
                          <h2>Payouts</h2>
                        </div>
                        <a
                          className="card-header collapsed d-flex align-items-center"
                          data-toggle="collapse"
                          href="#Payouts_1"
                        >
                          <div className="card-title">Where Can I Get My Book Reports?</div>
                        </a>
                        <div
                          id="Payouts_1"
                          className="card-body bg-grey collapse"
                          data-parent="#accordion"
                        >
                          <p>
                            Your &apos;Total Pages&apos; and &apos;Total Books&apos; read information can be viewed
                            directly from the &apos;Book Reports&apos; page. These information can also be
                            generated and downloaded in the report format of your choice (PDF, CSV,
                            XLS) by filtering the &apos;date range&apos; and clicking on the &apos;Generate report&apos;
                            button.
                          </p>
                        </div>
                      </div>
                      {/* End Widget */}
                      {/* Begin Widget */}
                      <div className="widget" id="Books">
                        <div className="widget-header no-actions d-flex align-items-center">
                          <h2>Books</h2>
                        </div>
                        <a
                          className="card-header collapsed d-flex align-items-center"
                          data-toggle="collapse"
                          href="#Books_1"
                        >
                          <div className="card-title">How Do I Upload Another Book?</div>
                        </a>
                        <div
                          id="Books_1"
                          className="card-body bg-grey collapse"
                          data-parent="#accordion"
                        >
                          <p>
                            Go to &apos;My Books&apos; page and click on &apos;Upload New Book&apos;, input the required
                            information in all fields and submit. After submitting, you will get an
                            email confirming the successful upload of your book.
                          </p>
                        </div>
                        <a
                          className="card-header collapsed d-flex align-items-center"
                          data-toggle="collapse"
                          href="#Books_2"
                        >
                          <div className="card-title">
                            How Do I Know The Status Of My Uploaded Book?
                          </div>
                        </a>
                        <div
                          id="Books_2"
                          className="card-body bg-grey collapse"
                          data-parent="#accordion"
                        >
                          <p>
                            The status of all uploaded books are visible on the &apos;My Books&apos; page.
                          </p>
                        </div>
                        <a
                          className="card-header collapsed d-flex align-items-center"
                          data-toggle="collapse"
                          href="#Books_3"
                        >
                          <div className="card-title">How Do I Add My Bio?</div>
                        </a>
                        <div
                          id="Books_3"
                          className="card-body bg-grey collapse"
                          data-parent="#accordion"
                        >
                          <p>
                            To add a Bio for a new Author, please go to the &apos;My Authors&apos; page. Click
                            on the respective Author&apos;s card, fill in the required details and save.
                          </p>
                        </div>
                      </div>
                      {/* End Widget */}
                    </div>
                    {/* End Accordion */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect()(FAQs);
