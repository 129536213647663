import React, { useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

export default function Track({ index, file, remove, update }) {
  const nameRef = useRef();
  const [editing, setEditing] = useState();
  const {
    title = '',
    progress = 0,
    duration = '',
    failed = false,
    uploading = false,
    completed = false,
  } = file;

  const updateName = () => {
    const isEditable = nameRef.current.isContentEditable;
    if (isEditable) {
      nameRef.current.contentEditable = !isEditable;
      setEditing(!isEditable);
      update(nameRef.current.innerHTML, index);
    } else {
      nameRef.current.contentEditable = !isEditable;
      setEditing(!isEditable);
      nameRef.current.focus();
    }
  };

  return (
    <>
      <Draggable
        index={index}
        key={file.key}
        draggableId={file.key}
        isDragDisabled={uploading}
      >
        {(dragprops) => {
          return (
            <div
              className='audio-book'
              ref={dragprops.innerRef}
              {...dragprops.draggableProps}
              {...dragprops.dragHandleProps}
            >
              <div className='ab-icon'>
                <i
                  className='las la-grip-lines'
                  style={{
                    fontSize: '24px',
                  }}
                />
              </div>
              <div style={{ flexGrow: 1 }}>
                <div>
                  <h4 ref={nameRef} autoCapitalize='words'>
                    {title}
                  </h4>
                  <p
                    style={{
                      margin: 0,
                      padding: '4px 0',
                    }}
                  >
                    <i className='lar la-clock' style={{ fontSize: '16px' }} />{' '}
                    {duration && duration}
                    {completed && (
                      <small style={{ color: '#1d7b85' }}>Uploaded</small>
                    )}
                    {failed && <small style={{ color: '#e23f83 ' }}>Failed</small>}
                  </p>
                </div>
                {uploading && (
                  <div
                    style={{
                      height: '4px',
                      overflow: 'hidden',
                      borderRadius: '2px',
                      background: '#f2f3f8',
                    }}
                  >
                    <div
                      style={{
                        height: '100%',
                        width: `${progress}%`,
                        background: failed ? '#e23f83 ' : '#1d7b85',
                      }}
                    />
                  </div>
                )}
              </div>
              {!uploading && !completed && !failed && (
                <>
                  <div className='ab-icon' onClick={updateName}>
                    <i
                      className={`la la-${editing ? 'save' : 'edit'}`}
                      style={{ fontSize: '24px' }}
                    />
                  </div>
                  <div className='ab-icon' onClick={() => remove(index)}>
                    <i className='la la-trash' style={{ fontSize: '24px' }} />
                  </div>
                </>
              )}
            </div>
          );
        }}
      </Draggable>
    </>
  );
}
