import React from 'react';

export default function ContentPolicy() {
	return (
		<div>
			<div>
				<div className="container mb-5">
					<div className="row mt-5">
						<div className="col-md-12">
							{/* <h4>Bambooks Content Policy and Guidelines</h4> */}
							<p>
								At Bambooks, we set stories free with the goal of creating a
								safe and comfortable space for everyone to discover great
								content. In order to create an inclusive and respectful space,
								we want to ensure our readers know what type of content they
								will discover in the course of using the service. This will also
								guide our authors and publishers on our the kind of content
								permitted on Bambooks.
							</p>
							<br />
							<br />

							<h5>1. Mature Rating</h5>

							<p>
								Mature stories are intended for an audience that is 21 and up.
								There are restrictions on discovering mature stories on Bambooks
								that help ensure they reach the correct audience. A story may be
								rated Mature if it contains any of the following:
							</p>
							<ul>
								<ol>
									<li>Explicit sex scenes</li>
									<li>Self-harm themes or scenes</li>
									<li>Graphic depictions of violence</li>
								</ol>
							</ul>
							<br />

							<h5>2. Prohibited Content</h5>
							<p>
								The following guidelines list content that is NOT allowed on
								Bambooks. This is not an exhaustive list, and we reserve the
								right to remove any content that we deem inappropriate.
							</p>

							<div className="deci-lists">
								<ul>
									<ol>
										<li style={{ display: 'table-column' }}></li>
										<li>
											<ol>
												<li>
													<b>Age of Consent:</b> The age of consent is 21+ on
													Bambooks. Any sexual content between characters must
													abide by this age of consent and not be in violation
													of the Laws of the Federal Republic of Nigeria.
													Content that depicts sexual exploitation of a minor is
													strictly prohibited. Content depicting children or
													characters resembling children in a sexually
													suggestive manner is prohibited. Content intended for
													use by children that contains adult content, profanity
													and/or sexual references are also prohibited and will
													be deleted immediately they are discovered.
												</li>
												<li>
													<b>Non-Consensual Sexual Content:</b> Bambooks
													classifies non-consensual sexual acts to include rape,
													sexual assault, kidnapping for sexual purposes, or
													sexual slavery. The content cannot be encouraging or
													promoting of non-consensual sex acts.
												</li>
												<li>
													<b>Illegal Sex Acts:</b> In accordance with the Laws
													of the Federal Republic of Nigeria, any content which
													encourages illegal sex acts including, but not limited
													to, bestiality or necrophilia, will be removed.
												</li>
												<li>
													<b>Child Abuse: </b>Content depicting child abuse and
													child exploitation is strictly prohibited and will be
													taken down as soon as they are discovered.
												</li>
												<li>
													<b>Illegal or Infringing Content:</b> We take
													violations of laws and proprietary rights very
													seriously. It is your responsibility to ensure that
													your content doesn't violate laws or copyright,
													trademark, privacy, publicity, or other rights. Just
													because content is freely available does not mean you
													are free to copy and sell it.
												</li>
											</ol>
										</li>
									</ol>
								</ul>
							</div>
							<br />

							<h5>3. Poor Customer Experience</h5>
							<p>
								Content available on Bambooks should provide a positive customer
								experience. We reserve the right to determine whether content
								provides a poor customer experience and remove that content.
							</p>
							<br />

							<h5>4. Violence and Hatred</h5>
							<p>
								The promotion of hatred on the basis of race, tribe, ethnicity,
								religion, disability, gender, age, sexual orientation, content
								that is intended to bully, threaten, or harass others, is also
								strictly prohibited.
							</p>

							<br />

							<h5>5. All Rights Reserved</h5>
							<p>
								Bambooks reserves the right to take further action and to report
								any erring content to the appropriate authorities.{' '}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
