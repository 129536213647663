/*
This file contains all actions types used in the app. Declarations 
here are exported and used in action and reducers as a SSOT for all
action types.
*/

export const LOGOUT = 'User logged out';
export const CLEARGENRE = 'Clear genres';
export const BANKSRECIEVED = 'Banks recieved';
export const GENRESELECTED = 'Genre selected'; // Genre selected
export const USERUPDATED = 'User info updated';
export const AUTHORSRECIEVED = 'Author content recieved'; // When authors are retrieved
export const USERLOGGEDIN = 'User logged in successfully'; // When a user logs in
export const CONTENTRECIEVED = 'Publisher content recieved'; // When books are retrieved from db
export const BOOKREPORTGENERATED = 'Book report generated'; // When book reports are retrieved
export const DASHBOARDDATARECIEVED = 'Dashboard data recieved'; // Dashboard data recieved
export const FINANCEREPORTGENERATED = 'Finance report generated'; // When finance retieved
export const BANKDETAILSRECIEVED = 'Bank details retrieved successfully';
export const PASSWORDCHANGED = 'User changed their password';