import React from 'react';
import logo2 from '../assets/images/logo.png';

export default function ({ full = true }) {
  return full ? (
    <div id='preloader'>
      <div className='canvas'>
        <img src={logo2} alt='logo' className='loader-logo' />
        <div className='spinner'></div>
      </div>
    </div>
  ) : (
    <div
      className='spinner'
      style={{
        width: '24px',
        height: '24px',
        border: 'solid 2px transparent',
        borderTop: 'solid 5px #fff',
      }}
    />
  );
}
