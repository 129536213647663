import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import logo2 from '../assets/images/logo.png';
import logo from '../assets/images/logo.svg';
import avatar from '../assets/images/blank.png';

function Header({ open, setOpen, setModal }) {
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  const { image, name } = useSelector((s) => s.User);

  return (
    <>
      <header className='header'>
        <nav className='navbar fixed-top'>
          {/* Begin Topbar */}
          <div className='navbar-holder'>
            <div className='row align-items-center'>
              <div className='col-xl-4 col-2'>
                {/* Toggle Button */}
                <button
                  className={`menu-btn ${open ? '' : 'active'} ml-2`}
                  style={{ background: 'none', border: 'none' }}
                  onClick={() => setOpen(!open)}
                >
                  <span />
                  <span />
                  <span />
                </button>
                {/* End Toggle */}
              </div>
              <div className='col-xl-4 col-3 d-flex justify-content-center'>
                <div className='navbar-header'>
                  <Link to='/' className='navbar-brand'>
                    <div className='brand-image brand-big'>
                      <img src={logo} alt='logo' className='logo-big' />
                    </div>
                    <div className='brand-image brand-small'>
                      <img src={logo2} alt='logo' className='logo-small' />
                    </div>
                  </Link>
                </div>
              </div>
              <div className='col-xl-4 col-7'>
                {/* Begin Navbar Menu */}
                <ul className='nav-menu list-unstyled d-flex flex-md-row align-items-md-center pull-right'>
                  {/* {/* User  */}
                  <li
                    className='nav-item dropdown'
                    onClick={() => setShow(!show)}
                  >
                    {image ? (
                      <img
                        src={image || avatar}
                        alt='...'
                        className='avatar rounded-circle'
                      />
                    ) : (
                      <h3
                        style={{
                          padding: '15px',
                          backgroundColor: '#17a2b8',
                          color: '#fff',
                          fontWeight: 'normal',
                          fontSize: '12px',
                          textAlign: 'center',
                          border: 'none',
                        }}
                        className='avatar rounded-circle d-block mx-auto align-text-center'
                      >
                        {name.substring(0, 1).toUpperCase()}
                      </h3>
                    )}
                    <ul
                      aria-labelledby='user'
                      className={`user-size dropdown-menu ${show && 'show'}`}
                    >
                      <li className='welcome'>
                        <Link to='/settings' className='edit-profil'>
                          <i className='la la-gear'></i>
                        </Link>
                        <img
                          src={image || avatar}
                          alt='...'
                          className='rounded-circle'
                        />
                      </li>

                      <li>
                        <Link
                          to='/settings'
                          className='dropdown-item no-padding-bottom'
                        >
                          Settings
                        </Link>
                      </li>
                      <li className='separator'></li>
                      <li>
                        <Link
                          to='/faqs'
                          className='dropdown-item no-padding-top'
                        >
                          FAQs
                        </Link>
                      </li>
                      <li>
                        <Link
                          to='/logout'
                          className='dropdown-item logout text-center'
                        >
                          <i className='ti-power-off'></i>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {/* End User */}
                </ul>
                {/* End Navbar Menu */}
              </div>
            </div>
          </div>
          {/* End Topbar */}
        </nav>
      </header>
      <div className='default-sidebar'>
        {/* Begin Side Navbar */}
        <nav
          className={`side-navbar ${
            open ? '' : 'shrinked'
          } box-scroll sidebar-scroll`}
        >
          {/* Begin Main Navigation */}
          <ul className='list-unstyled'>
            <li className={pathname === '/' ? 'styled-active' : ''}>
              <Link to='/'>
                <i className='la la-home text-light'></i>
                <span>Dashboard</span>
              </Link>
            </li>
          </ul>

          <span className='heading'>Content Management</span>
          <ul className='list-unstyled'>
            <li className={pathname.includes('books') ? 'styled-active' : ''}>
              <Link to='/books'>
                <i className='la la-book'></i>
                <span>My Books</span>
              </Link>
            </li>
            <li className={pathname.includes('authors') ? 'styled-active' : ''}>
              <Link to='/authors'>
                <i className='la la-user'></i>
                <span>My Authors</span>
              </Link>
            </li>
            {/*<li><a href="#"><i className="la la-area-chart"></i><span>Promotions</span></a></li>*/}
          </ul>
          <span className='heading'>Reports</span>
          <ul className='list-unstyled'>
            {/*<li><a href="#"><i className="la la-file-word-o"></i><span>Book Reports</span></a></li>*/}
            <li
              className={
                pathname.includes('reports/book') ? 'styled-active' : ''
              }
            >
              <Link to='/reports/book'>
                <i className='la la-book'></i>
                <span>Book Reports</span>
              </Link>
            </li>
            <li className={pathname.includes('finance') ? 'styled-active' : ''}>
              <Link to='/reports/finance'>
                <i className='la la-money'></i>
                <span>Financial Reports</span>
              </Link>
            </li>
          </ul>

          <span className='heading'>Support</span>
          <ul className='list-unstyled'>
            <li className={pathname.includes('faqs') ? 'styled-active' : ''}>
              <Link to='/faqs'>
                <i className='la la-question'></i>
                <span>FAQs</span>
              </Link>
            </li>
            <li onClick={() => setModal(true)}>
              {
                // eslint-disable-next-line
                <a href='#'>
                  <i className='la la-envelope'></i>
                  <span>Contact Support</span>
                </a>
              }
            </li>
          </ul>
          {/* End Main Navigation */}
        </nav>
        {/* End Side Navbar */}
      </div>
    </>
  );
}

export default Header;
