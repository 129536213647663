/* eslint-disable react/no-unescaped-entities */
import Axios from 'axios';
import { useForm } from 'react-hook-form';
import React, { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';

// Images
import Modal from './Modal';
import avatar from '../assets/images/blank.png';
import { BANKSRECIEVED, PASSWORDCHANGED } from '../actions/Types';
import {
  setPassword,
  updatePartner,
  getPartnerBank,
  saveAccountDetails,
} from '../actions/Auth';
import { toast } from 'react-toastify';
import { PasswordInput } from './Login';

function Settings() {
  const dispatch = useDispatch();
  const [pic, setPic] = useState('');
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pwLoading, setPwLoading] = useState(false);
  const [picLoading, setPicLoading] = useState(false);
  const { banks = [] } = useSelector((s) => s.Content);
  const [bankLoading, setBankLoading] = useState(false);
  const { handleSubmit, errors, register, watch } = useForm();
  const {
    id,
    name,
    image,
    location,
    username,
    bank = [],
    contactEmail,
    contactPhone,
    change_password,
  } = useSelector((s) => s.User);
  const [open, setOpen] = useState(change_password);

  useEffect(() => {
    if (!banks.length) {
      Axios.get('https://api.paystack.co/bank?perPage=200').then((res) => {
        dispatch({
          type: BANKSRECIEVED,
          payload: res.data.data,
        });
      });
    }
    getPartnerBank(id, () => {}, dispatch);
  }, [banks.length, id, dispatch]);

  // Form  handler for user update form
  const {
    handleSubmit: userSubmit,
    errors: userErrors,
    register: userRegister,
  } = useForm();

  const {
    handleSubmit: bankSubmit,
    errors: bankErrors,
    register: bankRegister,
  } = useForm();

  const readFile = (file) => {
    if (typeof file[0] === 'object') {
      const reader = new FileReader();
      reader.addEventListener('loadend', (e) => {
        setPic(e.target.result);
      });
      reader.readAsDataURL(file[0]);
    }
  };

  const changePass = (v) => {
    setPwLoading(true);
    delete v.confirm;
    setPassword(
      { ...v, email: username },
      (status) => {
        if (status) {
          dispatch({ type: PASSWORDCHANGED });
          setOpen(false);
          toast.success('Password changed successfully');
        } else {
          toast.error('Failed to change password');
        }
        setPwLoading(false);
      },
      dispatch,
    );
  };

  const updateUser = (v) => {
    if (!change_password) {
      setLoading(!v.image);
      setPicLoading(!!v.image);
      updatePartner(
        { ...v, id },
        (status) => {
          if (status) {
            setPic('');
            toast.success('Details updated successfully');
          } else {
            toast.error('Failed to update information');
          }
          setLoading(false);
          setPicLoading(false);
        },
        dispatch,
      );
    }
  };

  const saveBankDetails = (v) => {
    setBankLoading(true);
    const bank = v.bank_name.split('/');
    saveAccountDetails(
      {
        ...v,
        bank_name: bank[0],
        bank_id: `${bank[1]}`,
      },
      (s) => {
        if (s) {
          toast.success('Account details saved successfully');
        } else {
          toast.error('failed to save details');
        }
        setBankLoading(false);
      },
      dispatch,
    );
  };

  return (
    <>
      {/* Begin Page Header*/}
      <div className='row'>
        <div className='page-header'>
          <div className='d-flex align-items-center'>
            <h5 className='page-header-title'>Welcome, {name}</h5>
          </div>
        </div>
      </div>
      <div className='row flex-row'>
        <div className='col-xl-3'>
          {/* Begin Widget */}
          <div className='widget has-shadow'>
            <div className='widget-body'>
              <div className='mt-5'>
                <div
                  style={{
                    width: 150,
                    height: 150,
                    marginBottom: '10px',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundImage: `url(${pic || image || avatar})`,
                  }}
                  className='rounded-circle mx-auto'
                />
                <input
                  type='file'
                  className='d-none'
                  id='avatar-input'
                  accept='.jpg, .png'
                  onChange={(e) => readFile(e.target.files)}
                />
              </div>
              <h3 className='text-center mt-3 mb-1'>{name}</h3>
              <p className='text-center'>{contactEmail}</p>
              {picLoading ? (
                <div className='spinner' />
              ) : (
                <div className='text-center'>
                  {pic && (
                    <div>
                      <button
                        id='saveAvatar'
                        style={{ flex: '0 0 auto' }}
                        className='btn btn-primary'
                        onClick={() => updateUser({ image: pic.trim() })}
                      >
                        Save
                      </button>{' '}
                      <button
                        id='saveAvatar'
                        style={{ flex: '0 0 auto' }}
                        className='btn btn-primary'
                        onClick={() => setPic('')}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                  <button
                    style={{
                      wordWrap: 'break-word',
                      width: '100%',
                      maxWidth: '220px',
                    }}
                    id='selectPic'
                    className='btn btn-outline-light text-center mt-2 mb-3'
                    onClick={() =>
                      document.getElementById('avatar-input').click()
                    }
                  >
                    <i className='ti-image'></i>&nbsp;Change picture
                  </button>
                </div>
              )}
              <div className='em-separator separator-dashed'></div>
              <ul className='nav flex-column'>
                <li className='nav-item'>
                  <a className='nav-link' href='/faqs'>
                    <i className='la la-question-circle la-2x align-middle pr-2'></i>
                    FAQs
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* End Widget */}
        </div>
        <div className='col-xl-9'>
          <div className='widget has-shadow'>
            <div className='widget-header bordered no-actions d-flex align-items-center'>
              <h4>Update Profile</h4>
            </div>
            <div className='widget-body'>
              <div className='col-10 ml-auto'>
                <div className='section-title mt-3 mb-5'>
                  <h4>Personal Information</h4>
                </div>
              </div>
              {loading ? (
                <div className='spinner' />
              ) : (
                <form
                  className='form-horizontal'
                  onSubmit={userSubmit(updateUser)}
                >
                  <div className='form-group row d-flex align-items-center mb-5'>
                    <label className='col-lg-2 form-control-label d-flex justify-content-lg-end'>
                      Full Name
                    </label>
                    <div className='col-lg-6'>
                      <input
                        type='text'
                        defaultValue={name}
                        className='form-control'
                        placeholder='Honourable Ganduje'
                        name='name'
                        ref={userRegister({
                          required: true,
                        })}
                      />
                      {userErrors.name && 'This field is required'}
                    </div>
                  </div>
                  <div className='form-group row d-flex align-items-center mb-5'>
                    <label className='col-lg-2 form-control-label d-flex justify-content-lg-end'>
                      Email
                    </label>
                    <div className='col-lg-6'>
                      <input
                        type='text'
                        defaultValue={contactEmail}
                        className='form-control'
                        placeholder='ganduje@scammers.com'
                        name='contactEmail'
                        ref={userRegister({
                          required: true,
                        })}
                      />
                    </div>
                  </div>
                  <div className='form-group row d-flex align-items-center mb-5'>
                    <label className='col-lg-2 form-control-label d-flex justify-content-lg-end'>
                      Phone
                    </label>
                    <div className='col-lg-6'>
                      <input
                        type='text'
                        defaultValue={contactPhone}
                        className='form-control'
                        placeholder='0709 000 0000'
                        name='contactPhone'
                        ref={userRegister({
                          required: true,
                        })}
                      />
                    </div>
                  </div>
                  <div className='form-group row d-flex align-items-center mb-5'>
                    <label className='col-lg-2 form-control-label d-flex justify-content-lg-end'>
                      Address
                    </label>
                    <div className='col-lg-6'>
                      <input
                        type='text'
                        name='location'
                        ref={userRegister({
                          required: true,
                        })}
                        defaultValue={location}
                        className='form-control'
                        placeholder='123 Century Blvd'
                      />
                    </div>
                  </div>
                  <div>
                    <button type='submit' className='btn btn-primary'>
                      Save Changes
                    </button>
                  </div>
                </form>
              )}

              <div className='em-separator separator-dashed'></div>

              <div className='col-10 ml-auto'>
                <div className='section-title mt-5 mb-3'>
                  <h4>Change Password</h4>
                </div>
                <button
                  className='btn btn-outline-primary mb-5'
                  data-toggle='modal'
                  data-target='#password-modal'
                  onClick={() => setOpen(true)}
                >
                  Change Password
                </button>
              </div>
              {/*<div className="text-left mb-5 mt-5">*/}
              {/*</div>*/}
              {!change_password && (
                <>
                  <div className='em-separator separator-dashed'></div>
                  <div className='col-10 ml-auto'>
                    <div className='section-title mt-4 mb-4'>
                      <h4>Bank Information</h4>
                    </div>
                  </div>
                  <div className='form-group row d-flex align-items-center mb-5'>
                    <label className='col-lg-2 form-control-label d-flex justify-content-lg-end'>
                      Account Number
                    </label>
                    <div className='col-lg-6'>
                      <input
                        readOnly
                        disabled
                        type='text'
                        className='form-control'
                        placeholder='000XXXXXXX'
                        value={bank[0] && bank[0].account_number}
                      />
                    </div>
                  </div>
                  <div className='form-group row d-flex align-items-center mb-5'>
                    <label className='col-lg-2 form-control-label d-flex justify-content-lg-end'>
                      Account Name
                    </label>
                    <div className='col-lg-6'>
                      <input
                        readOnly
                        disabled
                        type='text'
                        className='form-control'
                        placeholder='Enter name associated with account'
                        value={bank[0] && bank[0].account_name}
                      />
                    </div>
                  </div>
                  <div className='form-group row d-flex align-items-center mb-5'>
                    <label className='col-lg-2 form-control-label d-flex justify-content-lg-end'>
                      Bank Name
                    </label>
                    <div className='col-lg-6'>
                      <input
                        disabled
                        readOnly
                        type='text'
                        className='form-control'
                        placeholder='Enter name associated with account'
                        value={bank[0] && bank[0].bank_name}
                      />
                    </div>
                  </div>

                  <div className='form-group row d-flex align-items-center mb-5'>
                    <div className='col-md-4'>
                      <button
                        className='btn btn-outline-info pull-right'
                        data-toggle='modal'
                        data-target='#bank-modal'
                        onClick={() => setShow(true)}
                      >
                        Edit Acct Details
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* End Row */}

      {/* Begin Modal */}
      <Modal
        header='Change your password'
        show={open}
        setShow={setOpen}
        action={handleSubmit(changePass)}
      >
        {pwLoading ? (
          <div className='spinner'></div>
        ) : (
          <>
            <div className='form-group row d-flex align-items-center '>
              <label className='col-lg-3 form-control-label'>
                Current Password
              </label>
              <div className='col-lg-9'>
                <PasswordInput
                  type={3}
                  name='old_password'
                  register={register}
                  label='Enter current password'
                  errors={errors}
                  msg={'Current password is required'}
                />
              </div>
            </div>
            <div className='form-group row d-flex align-items-center'>
              <label className='col-lg-3 form-control-label'>
                New Password
              </label>
              <div className='col-lg-9'>
                <PasswordInput
                  type={3}
                  name='password'
                  register={register}
                  label='Enter your new password'
                  errors={errors}
                  val={{
                    minLength: 6,
                  }}
                  msg={'New password is required'}
                />
              </div>
            </div>
            <div className='form-group row d-flex align-items-center'>
              <label className='col-lg-3 form-control-label'>
                Confirm New Password
              </label>
              <div className='col-lg-9'>
                <PasswordInput
                  type={3}
                  name='confirm'
                  errors={errors}
                  register={register}
                  msg={"Passwords don't match"}
                  label='Enter your new password again'
                  val={{
                    minLength: 6,
                    validate: (v) => v === watch('password'),
                  }}
                />
              </div>
            </div>
          </>
        )}
      </Modal>
      {/* End Modal */}

      {/* Begin Modal */}
      <Modal
        header='Edit Account Details'
        show={show}
        setShow={setShow}
        action={bankSubmit(saveBankDetails)}
      >
        {bankLoading ? (
          <div className='spinner' />
        ) : (
          <>
            <div className='row'>
              <div className='col-md-12'>
                <div className='form-group row d-flex align-items-center'>
                  <label className='col-lg-3 form-control-label'>
                    Account Number
                  </label>
                  <input
                    className='form-control'
                    type='text'
                    name='account_number'
                    placeholder='Enter Acct Number'
                    ref={bankRegister({
                      required: true,
                      minLength: 10,
                      maxLength: 10,
                      pattern: /^\d+$/,
                    })}
                  />
                  {bankErrors.account_number && (
                    <span className='text-danger'>
                      Please enter your 10 digit account number
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='form-group row d-flex align-items-center'>
                  <label className='col-lg-3 form-control-label'>
                    Account Name
                  </label>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='Enter Acct Name'
                    name='account_name'
                    ref={bankRegister({
                      required: true,
                    })}
                  />
                  {bankErrors.account_name && (
                    <span className='text-danger'>
                      Please enter the name associated with your bank account
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='form-group row d-flex align-items-center'>
                  <label className='col-lg-3 form-control-label'>
                    Select Bank
                  </label>
                  <select
                    className='custom-select form-control'
                    name='bank_name'
                    ref={bankRegister({
                      required: true,
                    })}
                  >
                    {banks.map((e, i) => {
                      return (
                        <option key={i} value={`${e.name}/${e.code}`}>
                          {e.name}
                        </option>
                      );
                    })}
                  </select>
                  {bankErrors.bank_name && (
                    <span className='text-danger'>Please choose your bank</span>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </Modal>
      {/* End Modal */}
    </>
  );
}

export default connect(null)(Settings);
