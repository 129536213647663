import ReactGA from 'react-ga';
import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Switch, Route, useLocation } from 'react-router-dom';

import Login from './components/Login';
import Logout from './components/Logout';
import AppMain from './components/AppMain';
import NotFound from './components/NotFound';
import ResetPassword from './components/ResetPassword';
import Onboarding from './components/Onboarding/Index';
import ChangePassword from './components/ChangePassword';
import ConfirmPassword from './components/ConfirmPassword';
import CreatePassword from './components/Onboarding/CreatePassword';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGA.pageview(pathname);
  }, [pathname]);

  return (
    <div className="App">
      {/* <ScrollToTop /> */}
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/404" component={NotFound} />
        <Route path="/logout" component={Logout} />
        <Route path="/terms" component={Onboarding} />
        <Route path="/reset" component={ResetPassword} />
        <Route path="/register" component={Onboarding} />
        <Route path="/conversion" component={Onboarding} />
        <Route path="/confirm" component={ConfirmPassword} />
        <Route path="/content-policy" component={Onboarding} />
        <Route path="/privacy-policy" component={Onboarding} />
        <Route path="/change-password" component={ChangePassword} />
        <Route path="/create-password" component={CreatePassword} />
        <Route path="/" component={AppMain} />
      </Switch>
      <ToastContainer hideProgressBar={true} />
    </div>
  );
}

export default App;
