import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgressbar } from 'react-circular-progressbar';

import Spinner from './Spinner';
import { getDashboardData } from '../actions/Content';
import { getPartnerBank } from '../actions/Auth';

let settingsComplete = false;
// let hasBank = false;

export default function Home() {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [hasBank, setHasBank] = useState(true);
  const { dashboard = {} } = useSelector((s) => s.Content);
  const { id, name, location, contactPhone } = useSelector((s) => s.User);

  useEffect(() => {
    settingsComplete = !!(name && location && contactPhone);
    // hasBank = !!bank.length;
  }, [name, location, contactPhone]);

  const {
    month_trend = [],
    today_read_count = 0,
    total_read_count = 0,
    total_book_count = 0,
    new_percentage_read = 0,
    this_week_read_count = 0,
  } = dashboard;

  useEffect(() => {
    getDashboardData({}, () => setLoading(false), dispatch);
    getPartnerBank(
      id,
      (s) => {
        if (s) {
          setHasBank(true);
        } else {
          setHasBank(false);
        }
      },
      dispatch,
    );
  }, [dispatch, id]);

  useEffect(() => {
    let labels = [];
    let datasets = [
      {
        label: 'Reads',
        data: [],
        borderColor: '#fff',
        borderRadius: '10px',
        backgroundColor: '#5d5386',
        hoverBackgroundColor: '#483d77',
      },
    ];

    month_trend.forEach((e) => {
      labels.push(e.key);
      datasets[0].data.push(e.value);
    });

    setData({
      labels,
      datasets,
      options,
    });
    // eslint-disable-next-line
  }, [month_trend.length]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      {/* Begin Page Header*/}
      <div className='row'>
        <div className='page-header'>
          <div className='d-flex align-items-center'>
            <div className='page-header-title'>
              {!settingsComplete && (
                <div
                  className='alert alert-outline-danger dotted fade show'
                  role='alert'
                >
                  You need to complete your personal information -{' '}
                  <Link to='/settings'>click here to complete setup</Link>
                </div>
              )}
              {!hasBank && (
                <div
                  className='alert alert-outline-danger dotted fade show'
                  role='alert'
                >
                  You need to add your bank account information -{' '}
                  <Link to='/settings'>click here to complete setup</Link>
                </div>
              )}
            </div>

            <div>
              <h5>Welcome, {name}</h5>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Header */}

      {/* Begin Row */}
      <div className='row flex-row'>
        {/* Begin Face */}
        <div className='col-xl-4 col-md-6 col-sm-6'>
          <div className='widget widget-12 has-shadow widget-custom'>
            <div className='widget-body'>
              <div className='media'>
                <div className='align-self-center ml-5 mr-5'>
                  <i className='ti-book text-bambooks'></i>
                </div>
                <div className='media-body align-self-center'>
                  <div className='title text-blacked'>
                    {today_read_count.toLocaleString()}
                  </div>
                  <div className='number'>Pages read today</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End stat 01 */}
        {/* Begin stat 02 */}
        <div className='col-xl-4 col-md-6 col-sm-6'>
          <div className='widget widget-12 has-shadow widget-custom'>
            <div className='widget-body'>
              <div className='media'>
                <div className='align-self-center ml-5 mr-5'>
                  <i className='ti-layout-media-center-alt text-bambooks'></i>
                </div>
                <div className='media-body align-self-center'>
                  <div className='title text-blacked'>
                    {this_week_read_count.toLocaleString()}
                  </div>
                  <div className='number'>Pages read this week</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-4 col-md-6 col-sm-6'>
          <div className='widget widget-12 has-shadow widget-custom'>
            <div className='widget-body'>
              <div className='media'>
                <div className='align-self-center ml-5 mr-5'>
                  <i className='ti-layout-media-center-alt text-bambooks'></i>
                </div>
                <div className='media-body align-self-center'>
                  <div className='title text-blacked'>
                    {total_read_count.toLocaleString()}
                  </div>
                  <div className='number'>Overall Pages read</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row flex-row'>
        <div className='col-xl-12 col-md-12'>
          <div className='widget widget-09 has-shadow'>
            <div className='widget-header d-flex align-items-center'>
              <h2>Monthly Book Stats</h2>
            </div>
            <div className='widget-body'>
              <div className='row'>
                <div className='col-xl-10 col-12 no-padding'>
                  <Bar data={data} />
                </div>
                <div className='col-xl-2 col-12 d-flex flex-column my-auto no-padding text-center'>
                  <div className='new-orders'>
                    <div className='title'>New Reads</div>
                    <CircularProgressbar
                      value={new_percentage_read}
                      text={`${new_percentage_read.toFixed(1)}%`}
                      styles={{
                        root: {
                          height: 120,
                          width: 120,
                        },
                        path: {
                          stroke: '#08a6c3',
                        },
                        text: {
                          fill: '#111',
                        },
                      }}
                    />
                  </div>
                  <div className='some-stats mt-5'>
                    <div className='title'>Total Reads</div>
                    <div className='number text-blue'>
                      {total_read_count.toLocaleString()}
                    </div>
                  </div>
                  <div className='some-stats mt-3'>
                    <div className='title'>Total Books</div>
                    <div className='number text-blue'>
                      {total_book_count.toLocaleString()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Widget 09 */}
        </div>
      </div>
      {/* End Row */}
    </>
  );
}

export const options = {
  responsive: true,
  barRoundness: 1,
  tooltips: {
    backgroundColor: 'rgba(47, 49, 66, 0.8)',
    titleFontSize: 13,
    titleFontColor: '#fff',
    caretSize: 0,
    cornerRadius: 4,
    xPadding: 5,
    displayColors: false,
    yPadding: 5,
  },
  legend: {
    display: true,
    position: 'bottom',
    labels: {
      fontColor: '#2e3451',
      usePointStyle: true,
      padding: 50,
      fontSize: 13,
    },
  },
  scales: {
    xAxes: [
      {
        barThickness: 20,
        stacked: false,
        gridLines: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          display: true,
        },
      },
    ],
    yAxes: [
      {
        stacked: false,
        gridLines: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    ],
  },
};
