/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { createBook } from '../../../actions/Content';
import { PasswordInput } from '../../Login';

export default function ConfirmBooksAdded({
  books,
  user,
  removeBook,
  setTab,
  setBook,
}) {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register, watch, errors } = useForm();
  const uploadBooks = (v) => {
    setLoading(true);
    delete v.confirm;
    const data = {
      ...v,
      books,
    };
    createBook(
      {
        data,
        progressEvt,
      },
      (s, m) => {
        if (s) {
          setLoading(false);
          setTab(
            {
              email: v.email,
              password: v.password,
            },
            4,
          );
        } else {
          setLoading(false);
          toast.error(m);
        }
      },
      dispatch,
    );
  };

  const progressEvt = (e) => {
    if (e.lengthComputable) {
      setProgress((e.loaded / e.total) * 100);
    }
  };

  return loading ? (
    <>
      <div className='spinner' />
      Uploading Files {Math.floor(progress)}%
      <div
        style={{
          height: '8px',
          borderRadius: '4px',
          background: '#f2f3f8',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            width: `${progress}%`,
            background: '#1d7b85',
            height: '100%',
          }}
        ></div>
      </div>
    </>
  ) : (
    <div className='wizard-form px-md-5 py-5'>
      <div className='row mb-5'>
        <div className='col'>
          <h3 className='form-title'>Confirm your details</h3>
        </div>
      </div>
      <form onSubmit={handleSubmit(uploadBooks)}>
        <div className='row mb-5 fold-x'>
          <div className='col-md-6 space-x'>
            <label className='label'>Name *</label>
            <input
              placeholder='Enter Your Full Name'
              name='name'
              className='publish-input form-control'
              defaultValue={user.name}
              ref={register({
                required: true,
              })}
            />
          </div>
          <div className='col-md-6'>
            <label className='label'>Email *</label>
            <input
              type='email'
              placeholder='Enter Your Email Address'
              name='email'
              className='publish-input form-control'
              defaultValue={user.username}
              ref={register({
                required: true,
                pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
              })}
            />
          </div>
        </div>

        <div className='row mb-5'>
          <div className='col-md-6 space-x'>
            <label className='label'>Phone Number *</label>
            <input
              name='phone'
              placeholder='Enter Your Phone Number'
              className='publish-input form-control'
              defaultValue={user.contactPhone}
              ref={register({
                required: true,
              })}
            />
          </div>
          <div className='col-md-6 space-x'>
            <label className='label'>Address *</label>
            <input
              type='text'
              name='address'
              placeholder='Enter your address'
              className='publish-input form-control'
              defaultValue={user.address}
              ref={register({
                required: true,
              })}
            />
          </div>
        </div>
        <div className='row mb-5'>
          <div className='col-md-6 space-x'>
            <label className='label'>Password *</label>
            <PasswordInput
              type={2}
              name='password'
              errors={errors}
              defaultValue={user.password}
              register={register}
              label='Enter your password'
              msg='Password must be at least 6 characters long'
            />
          </div>
          <div className='col-md-6 space-x'>
            <label className='label'>Confirm Password</label>
            <PasswordInput
              type={2}
              name='confirm'
              errors={errors}
              register={register}
              defaultValue={user.confirm}
              label='Confirm your password'
              msg='Confirmation must match password'
              val={{
                validate: (v) => v === watch('password'),
              }}
            />
          </div>
        </div>
        {books.map((e, i) => {
          return (
            <ShowBookItem
              book={e}
              index={i}
              key={i}
              remove={removeBook}
              edit={setBook}
            />
          );
        })}
        <div className='row'>
          <div className='col-md-6 mb-3 text-center text-md-right'>
            <button
              type='button'
              className='wizard-button outline primary'
              onClick={() => setBook([{}, false])}
            >
              Add Another Book
            </button>
          </div>
          <div className='col-md-6 text-center mb-3'>
            <button className='wizard-button primary' type='submit'>
              Proceed
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

function ShowBookItem({ book, index, remove, edit }) {
  const { name, cover_base64, author } = book;

  return (
    <>
      <div className='books mb-5' onClick={() => edit([book, index])}>
        <div>
          <div className='row book-preview'>
            <div className='col-2 col-md-2 col-lg-1'>
              <img
                alt={name}
                src={cover_base64}
                width='50'
                className='preview-img'
              />
            </div>

            <div className='col pr-5'>
              <span className='book-title'>{name} </span>-
              <span className='book-author font-weight-bold'> {author}</span>
              <button
                style={{ right: '8px', top: 0 }}
                className='btn btn-clear position-absolute text-danger'
                onClick={() => remove(index)}
              >
                <i className='la la-times' style={{ color: 'red' }}></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr className='mb-5' />
    </>
  );
}
