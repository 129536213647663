import React from 'react';
import { Link, Switch, Route } from 'react-router-dom';

// Images
import logo from '../../assets/images/logo.svg';
// import terms from '../../assets/images/terms.jpg';
import enter from '../../assets/images/enter_green.svg';
import publish from '../../assets/images/publish.jpeg';
import CreateAccount from './Register';
import ContentPolicy from './ContentPolicy';
import PrivacyPolicy from './PrivacyPolicy';
import Terms from './Terms';
import CreatePassword from './CreatePassword';
import Conversion from '../Conversion';

export default function Onboarding() {
  return (
    <div>
      <div id='__layout'>
        <div>
          <div>
            <div
              className='header-big'
              style={{ backgroundImage: `url(${publish})` }}
            >
              <div className='overlay'></div>

              <nav
                id='topNav'
                className='navbar nav-top px-4 py-2 fixed-top nav-white navbar-expand-md'
              >
                <Link to='/' className='navbar-brand mx-auto mx-lg-0'>
                  <img
                    src={logo}
                    className='logo-green ml-3'
                    width='180'
                    alt='Bambooks'
                  />
                </Link>

                <ul className='navbar-nav ml-auto d-none d-md-block'>
                  <Link to='/login'>
                    <button
                      type='button'
                      className='btn auth-button btn-outline-light'
                    >
                      <img
                        src={enter}
                        className='logo-green mr-2'
                        width='20'
                        alt=''
                        style={{ verticalAlign: 'middle' }}
                      />
                      <span style={{ verticalAlign: 'middle' }}>Sign In</span>
                    </button>
                  </Link>
                </ul>
              </nav>

              <div className='row'>
                <div className='col-12'>
                  <div className='text-white'>
                    <h1>Publish With Us</h1>
                  </div>
                </div>
              </div>
            </div>
            <Switch>
              <Route path='/terms' component={Terms} />
              <Route path='/conversion' component={Conversion} />
              <Route path='/register' component={CreateAccount} />
              <Route path='/content-policy' component={ContentPolicy} />
              <Route path='/privacy-policy' component={PrivacyPolicy} />
              <Route path='/create-password' component={CreatePassword} />
            </Switch>
            <div className='bg-footer p-5'>
              <div className='row justify-content-center mb-5'>
                <div className='col-md-6'>
                  <ul className='footer-links d-flex justify-content-around'>
                    <li>
                      <Link to='/faqs'>FAQ</Link>
                    </li>
                    <li>
                      <Link to='/content-policy' target='_blank'>
                        Content Policy
                      </Link>
                    </li>
                    <li>
                      <Link to='/terms' target='_blank'>
                        Terms and Conditions
                      </Link>
                    </li>
                    <li>
                      <Link to='/privacy-policy' target='_blank'>
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className='row justify-content-center'>
                <div className='col-md-3'>
                  <ul className='socials d-flex justify-content-around'>
                    <li>
                      <a
                        target='_blank'
                        rel='noreferrer noopener'
                        href='https://facebook.com/bambooks.io'
                      >
                        <i className='fa fa-facebook'></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target='_blank'
                        rel='noreferrer noopener'
                        href='https://twitter.com/bambooks_io'
                      >
                        <i className='fa fa-twitter'></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target='_blank'
                        rel='noreferrer noopener'
                        href='https://instagram.com/bambooks.io'
                      >
                        <i className='fa fa-instagram'></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className='row'>
                <div className='col'>
                  <hr />
                </div>
              </div>
              <div className='row'>
                <div className='col text-center copyright'>
                  Copyright © 2018 Bambooks.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
