import React from 'react';
import PropTypes from 'prop-types';

export default function UploadSuccessful({ hidden, setTab }) {
  return (
    <div hidden={hidden}>
      <div className="section-title mt-5 mb-5">
        <h4>Success!</h4>
        <p className="lead">Book uploaded successfully</p>
      </div>
      <ul className="pager wizard text-right">
        <li className="previous d-inline-block">
          <button type="button" className="btn btn-secondary ripple" onClick={() => setTab(1)}>
            Upload New Book
          </button>
        </li>
      </ul>
    </div>
  );
}

UploadSuccessful.propTypes = {
  hidden: PropTypes.element.isRequired,
  setTab: PropTypes.element.isRequired,
};
