/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { logUserIn } from '../../../actions/Auth';
import success from '../../../assets/images/success.svg';

export default function LaunchProfile({ det }) {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <div className='container'>
      <div className='row justify-content-center mt-30'>
        <img src={success} alt='' width='120px' />
      </div>
      <div className='row justify-content-center mt-30'>
        <p className='lead text-center'>
          Your account has been created and <br /> your books have been sent for
          review.
        </p>
      </div>
      <div className='row justify-content-center mt-30'>
        <button
          type='submit'
          className='wizard-button primary'
          onClick={() =>
            logUserIn(
              { username: det.email, password: det.password },
              (status, _) => {
                if (status) {
                  history.push('/');
                }
              },
              dispatch,
            )
          }
        >
          Launch Profile
        </button>
      </div>
    </div>
  );
}
