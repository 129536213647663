/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

// Images
import logo from '../assets/images/logo.svg';
import { logUserIn } from '../actions/Auth';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

function Login() {
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { handleSubmit, errors, register } = useForm();

  const login = (v) => {
    if (!v.bubbles) {
      setLoading(true);
      setMsg('');
      logUserIn(
        v,
        (status, m) => {
          if (status) {
            if (m.change_password) history.push('/change-password');
            else history.push('/');
          } else {
            setLoading(false);
            setMsg(m);
          }
        },
        dispatch,
      );
    }
  };

  return (
    <>
      {/* Begin Container */}
      <div className='container-fluid no-padding h-100'>
        <div className='row flex-row h-100 bg-white'>
          {/* Begin Left Content */}
          <div className='col-xl-8 col-lg-6 col-md-5 no-padding'>
            <div className='login-bg'>
              <div className='login-overlay'></div>
              <div className='authentication-col-content mx-auto'>
                <h1 className='gradient-text-01'>
                  Bambooks Digital Publishing
                </h1>
                <span className='description'>
                  Manage your books, generate reports and track your overall
                  performance.
                </span>
              </div>
            </div>
          </div>
          {/* End Left Content */}
          {/* Begin Right Content */}
          <div className='col-xl-4 col-lg-6 col-md-7 my-auto no-padding'>
            {/* Begin Form */}
            <div className='authentication-form mx-auto'>
              <div className='logo-centered'>
                <a href='/'>
                  <img src={logo} width='100%' alt='logo' />
                </a>
              </div>
              <h3>Sign in here</h3>
              {msg && <p className='alert btn-gradient-01 mb-5'>{msg}</p>}
              <form onSubmit={handleSubmit(login)}>
                {loading ? (
                  <div className='spinner'></div>
                ) : (
                  <>
                    <div className='group material-input'>
                      <input
                        type='text'
                        name='username'
                        ref={register({
                          required: true,
                          pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                        })}
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label>Email</label>
                      {errors.username && (
                        <span>Please enter a valid email</span>
                      )}
                    </div>
                    <PasswordInput
                      name='password'
                      register={register}
                      label='Password'
                    />
                    <div className='row'>
                      <div className='col text-left'>
                        <div className='styled-checkbox'>
                          <input
                            type='checkbox'
                            name='checkbox'
                            id='remeber'
                            ref={register}
                            onChange={(e) =>
                              e.target.checked
                                ? // eslint-disable-next-line no-undef
                                  localStorage.setItem('rememberMe', '1')
                                : // eslint-disable-next-line no-undef
                                  localStorage.setItem('rememberMe', '0')
                            }
                          />
                          <label htmlFor='remeber'>Keep me signed in</label>
                        </div>
                      </div>
                      <div className='col text-right'>
                        <Link to='/reset'>Forgot Password ?</Link>
                      </div>
                    </div>
                    <div className='sign-btn text-center'>
                      <button
                        type='submit'
                        className='btn btn-lg btn-gradient-01'
                      >
                        Sign in
                      </button>
                    </div>
                  </>
                )}
              </form>
              <div className='register mb-5'>
                Don't have an account?
                <br />
                <Link to='/register'>Create an account</Link>
              </div>
              <Link
                style={{ background: '#f9f9f9', padding: '10px' }}
                to='/conversion'
              >
                <u>To access our ePub Conversion Services, please click here</u>
              </Link>
              <a
                target='_blank'
                href='/Bambooks_Service_Brief.pdf'
                style={{
                  background: '#f9f9f9',
                  padding: '10px',
                  margin: '4px auto',
                  textAlign: 'center',
                  width: '100%'
                }}
              >
                <u>Click here to download our service guide</u>
              </a>
            </div>
            {/* End Form */}
          </div>
          {/* End Right Content */}
        </div>
        {/* End Row */}
      </div>
      {/* End Container */}
    </>
  );
}

export const PasswordInput = ({
  name,
  label,
  register,
  type = 1,
  msg = '',
  val = {},
  errors = {},
  defaultValue = '',
}) => {
  const [show, setShow] = useState();

  return (
    <>
      {type === 1 && (
        <div className='group material-input'>
          <input
            type={show ? 'text' : 'password'}
            name={name}
            required
            ref={register({
              required: true,
              minLength: 4,
              ...val,
            })}
          />
          <span className='highlight'></span>
          <span className='bar'></span>
          <label>{label}</label>
          <i
            onClick={() => setShow(!show)}
            className={show ? 'la la-eye-slash' : 'la la-eye'}
            style={{
              fontSize: '16px',
              position: 'absolute',
              top: '50%',
              right: '20px',
              transform: 'translateY(-50%)',
            }}
          />
          {errors[name] && <span className='text-danger'>{msg}</span>}
        </div>
      )}
      {type === 2 && (
        <div style={{ position: 'relative' }}>
          <input
            name={name}
            placeholder={label}
            defaultValue={defaultValue}
            type={show ? 'text' : 'password'}
            className='publish-input form-control'
            ref={register({
              required: true,
              minLength: 6,
              ...val,
            })}
          />
          <i
            onClick={() => setShow(!show)}
            className={show ? 'la la-eye-slash' : 'la la-eye'}
            style={{
              fontSize: '16px',
              position: 'absolute',
              top: '50%',
              right: '20px',
              transform: 'translateY(-50%)',
            }}
          />
          {errors[name] && <span className='text-danger'>{msg}</span>}
        </div>
      )}
      {type === 3 && (
        <div style={{ position: 'relative' }}>
          <input
            name={name}
            placeholder={label}
            className='form-control'
            defaultValue={defaultValue}
            type={show ? 'text' : 'password'}
            ref={register({ required: true, ...val })}
          />
          <i
            onClick={() => setShow(!show)}
            style={{
              top: '50%',
              right: '20px',
              fontSize: '16px',
              position: 'absolute',
              transform: 'translateY(-50%)',
            }}
            className={show ? 'la la-eye-slash' : 'la la-eye'}
          />
          {errors[name] && <span className='text-danger'>{msg}</span>}
        </div>
      )}
    </>
  );
};

export default Login;
