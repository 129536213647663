import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share';
import { animated, useTransition } from 'react-spring';

export default function Share({ open, link, msg }) {
  const transitions = useTransition(open, null, {
    from: { position: 'relative', opacity: 0, transform: 'translateY(-50px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(-50px)' },
  });

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.div key={key} style={props}>
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              padding: '8px',
              marginTop: '8px',
              background: '#fff',
              zIndex: 50,
            }}
            className="has-shadow"
            onClick={(e) => e.stopPropagation()}
          >
            <EmailShareButton subject={msg} body="Checkout my book on bambooks" url={link}>
              <EmailIcon size={32} round={true} />
            </EmailShareButton>{' '}
            <FacebookShareButton url={link} quote={msg}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>{' '}
            <TwitterShareButton url={link} title={msg}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>{' '}
            <WhatsappShareButton url={link} title={msg}>
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>
            <LinkedinShareButton
              title={msg}
              url={link}
              summary="Here's the link to read my book on bamboks"
            >
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
          </div>
        </animated.div>
      ),
  );
}
