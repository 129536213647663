import Axios from 'axios';
import conn from './conn';
import storage from '../components/helpers/StorageHelper';
import {
  USERLOGGEDIN,
  LOGOUT,
  USERUPDATED,
  BANKDETAILSRECIEVED,
} from './Types';

export const errHandler = (err, callback, caller, dispatch) => {
  if (err.response) {
    if (err.response.data.message === 'Invalid Token') {
      storage.clear('token');
      conn
        .post('/RefreshToken', {
          refresh_token: storage.get('refresh_token'),
        })
        .then((res) => {
          storage.store('token', res.data.data.token);
          storage.store('refresh_token', res.data.data.refresh_Token);
          caller(JSON.parse(err.response.config.data), callback, dispatch);
        })
        .catch(() => {
          dispatch(logout());
        });
    } else if (err.response.data.message === 'Refresh Token Expired') {
      dispatch(logout());
    } else {
      callback(false, err.response.data.message);
    }
  } else if (err.request) {
    callback(false, 'Error connecting to the server');
  } else {
    callback(false, 'An error occurred');
  }
};

export const logUserIn = (data, callback, dispatch) => {
  conn
    .post('/Authenticate', data)
    .then((res) => {
      dispatch({
        type: USERLOGGEDIN,
        payload: res.data.data,
      });
      callback(true, res.data.data);
    })
    .catch((err) => {
      if (
        err.response.data.message === 'Password does not exist, create password'
      ) {
        conn
          .get(`/ResetPasswordJob/?email=${data.username}`)
          .then(() => {
            callback(
              false,
              'Please reset your password via the link provided in the email sent to you.',
            );
          })
          .catch(() => {});
      } else {
        errHandler(err, callback, logUserIn, dispatch);
      }
    });
};

export const createAccount = (data, callback, dispatch) => {
  conn
    .post('/Partner', data)
    .then((res) => {
      dispatch({
        type: USERLOGGEDIN,
        payload: res.data.data,
      });
      callback(true);
    })
    .catch((err) => {
      errHandler(err, callback, createAccount, dispatch);
    });
};

export const updatePartner = (data, callback, dispatch) => {
  conn
    .put(`/Partner/${data.id}`, data)
    .then(() => {
      dispatch({
        type: USERUPDATED,
        payload: data,
      });
      callback(true);
    })
    .catch((err) => {
      errHandler(err, callback, updatePartner, dispatch);
    });
};

export const createPassword = (data, callback, dispatch) => {
  conn
    .post('/CreatePassword', data)
    .then(() => {
      callback(true);
    })
    .catch((err) => errHandler(err, callback, createPassword, dispatch));
};

export const forgotPassword = (data, callback) => {
  conn
    .post('/ForgetPassword', data)
    .then(() => {
      callback(true);
    })
    .catch((err) => {
      errHandler(err, callback, forgotPassword);
    });
};

export const changePassword = (data, callback) => {
  conn
    .post('/ChangePassword', data)
    .then(() => {
      callback();
    })
    .catch((err) => {
      errHandler(err, callback, changePassword);
    });
};

export const resetPassword = (data, callback, dispatch) => {
  conn
    .post('/ResetPassword', data)
    .then((r) => {
      dispatch({
        type: USERLOGGEDIN,
        payload: r.data.data,
      });
      callback(true, r.data.data);
    })
    .catch((err) => {
      errHandler(err, callback, changePassword);
    });
};

export const setPassword = (data, callback, dispatch) => {
  conn
    .post('/SetPassword', data)
    .then(() => {
      logUserIn({
        password: data.password,
        username: data.email,
      }, callback, dispatch);
      // callback(true);
    })
    .catch((err) => {
      errHandler(err, callback, setPassword, dispatch);
    });
};

export const getPartnerBank = (data, callback, dispatch) => {
  conn
    .get(`/PartnerBankDetail`)
    .then((res) => {
      dispatch({
        type: BANKDETAILSRECIEVED,
        payload: res.data.data,
      });
      callback(true);
    })
    .catch((err) => {
      errHandler(err, callback, getPartnerBank, dispatch);
    });
};

export const verifyACcountDetails = (data, callback) => {
  Axios.get(
    `https://api.paystack.co/bank/resolve?account_number=${data.number}&bank_code=${data.bank}`,
  )
    .then((res) => {
      callback(true, res.data.data);
    })
    .catch(() => {
      callback(false, 'Account number not found');
    });
};

export const saveAccountDetails = (data, callback, dispatch) => {
  conn
    .post('/PartnerBankDetail', data)
    .then((res) => {
      dispatch({
        type: BANKDETAILSRECIEVED,
        payload: [res.data.data],
      });
      callback(true);
    })
    .catch((err) => {
      errHandler(err, callback, saveAccountDetails, dispatch);
    });
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};
