/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBisac } from '../../actions/Content';
import { GENRESELECTED } from '../../actions/Types';

export default function GenrePicker({ data, depth = 0 }) {
  const dispatch = useDispatch();
  const genre = useSelector((s) => s.Content.genres);
  return depth === 0 ? (
    <div style={{ maxHeight: '60vh', overflowY: 'scroll' }}>
      <p>
        <span style={{ color: '#1e7b85' }}>Choose at most 3 genres:</span>{' '}
        {genre.map((e, i) => (
          <span
            key={i}
            style={{
              background: '#eee',
              borderRadius: '4px',
              padding: '4px',
              marginRight: '8px',
            }}
            onClick={() => dispatch({ type: GENRESELECTED, payload: e })}
          >
            {`${e.name}`} <i className='la la-times'></i>
          </span>
        ))}
      </p>
      <div
        style={{
          paddingLeft: depth > 0 ? '16px' : '0',
          borderLeft: depth > 0 ? '1px solid lightgrey' : 'none',
        }}
      >
        {data.length === 0
          ? 'loading'
          : data.map((e, i) => <Item e={e} key={i} i={i} depth={depth} />)}
      </div>
    </div>
  ) : (
    <div
      style={{
        paddingLeft: depth > 0 ? '16px' : '0',
        borderLeft: depth > 0 ? '1px solid lightgrey' : 'none',
      }}
    >
      {data.length === 0
        ? 'loading'
        : data.map((e, i) => <Item e={e} key={i} i={i} depth={depth} />)}
    </div>
  );
}

const Item = ({ e, i, depth }) => {
  let { text, category, code } = e;
  const dispatch = useDispatch();
  const [children, setChildren] = useState([]);
  const [isExpanded, setExpanded] = useState(false);
  const genre = useSelector((s) => s.Content.genres);

  const cat = category.split('/');
  text = text.includes('~') ? text.substring(0, text.length - 1) : text;

  const hasChildren =
    `${cat[cat.length - 1].trim()}`.toLowerCase() !== text.toLowerCase();

  const fetchChildren = () => {
    setExpanded(!isExpanded);
    if (children.length === 0) {
      getBisac(
        { id: cat.filter((e, i) => i < depth + 1).map((e) => e.trim()) },
        (status, data) => {
          if (status) {
            setChildren(data);
          }
        },
        dispatch,
      );
    }
  };

  const selectGenre = () =>
    dispatch({ type: GENRESELECTED, payload: { code, category, name: text } });

  return (
    <div>
      {hasChildren ? (
        <i
          className={`pointer la la-caret-${isExpanded ? 'down' : 'right'}`}
          onClick={fetchChildren}
        ></i>
      ) : (
        <input
          type='checkbox'
          onChange={selectGenre}
          disabled={genre.length === 3 && !genre.some((e) => e.name === text)}
          checked={genre.some((e) => e.name === text)}
          id={`${text}${i}`}
        />
      )}
      <p
        style={{ display: 'inline-block', marginLeft: '8px' }}
        onClick={
          hasChildren
            ? fetchChildren
            : () => document.getElementById(`${text}${i}`).click()
        }
        className='pointer'
      >
        {text}
      </p>
      {isExpanded ? <GenrePicker data={children} depth={depth + 1} /> : ''}
    </div>
  );
};
