import Iframe from 'react-iframe';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BookCard from './BookCard';

// Components
import Modal from '../Modal';
import Spinner from '../Spinner';
import {
  getBooks,
  deleteBook,
  getCountries,
  getRestrictionsById,
} from '../../actions/Content';

export default function ListBooks() {
  const dispatch = useDispatch();
  const [id, setId] = useState(0);
  const [book, setBook] = useState({});
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [view, setView] = useState(false);
  const [country, setCountry] = useState([]);
  const [loading, setLoading] = useState(true);
  const { handleSubmit, register } = useForm();
  const [fetching, setFetching] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const books = useSelector((s) => s.Content.books.data || []);
  const [comments, setComments] = useState([]);
  const [showComments, setShowComments] = useState(false);
  const { total = 0, offset = 0 } = useSelector((s) => s.Content.books || {});

  const {
    img = '',
    name = '',
    author = '',
    status = '',
    synopsis = '',
    book_type = '',
    file_path = '',
    book_genre = '',
    num_of_pages = '',
    year_of_release = '',
    age_restriction = '',
    country_restriction_id = null,
  } = book;

  useEffect(() => {
    const data = {
      limit: 48,
      offset: 1,
      type: 'new',
    };
    // Update callback to handle status &  message
    getBooks(data, (s, m) => setLoading(false), dispatch);
    getCountries(
      '',
      (s, m) => {
        if (s) {
          setCountry(m);
        }
      },
      dispatch,
    );
  }, [dispatch]);

  useEffect(() => {
    if (comments.length) {
      setShowComments(true);
    }
  }, [comments]);

  useEffect(() => {
    setOpen(!!Object.keys(book).length);
  }, [book]);

  useEffect(() => {
    setShow(!!id);
  }, [id]);

  const loadMore = () => {
    const data = {
      limit: 48,
      offset: offset + 1,
    };
    setFetching(true);
    // Update callback to handle status &  message
    getBooks(data, (s, m) => setFetching(false), dispatch);
  };

  const removeBook = (v) => {
    if (!v.bubbles) {
      setDeleting(true);
      deleteBook(
        {
          id,
          ...v,
        },
        (s, m) => {
          if (s) {
            toast.success('Book deleted successfully');
            setShow(false);
          } else {
            toast.error('Failed to delete book');
          }
          setDeleting(false);
        },
        dispatch,
      );
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <>
      {/* Begin Page Header*/}
      <div className='row mb-5'>
        <div className='page-header'>
          <div className='d-flex align-items-center'>
            <div className='page-header-title'>
              <h3 className='font-weight-bold'>
                Showing {books.length} of {total} books
              </h3>
            </div>
            <div></div>

            <div>
              <Link to='/books/add'>
                <button className='btn btn-gradient-01'>Upload New Book</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Header */}

      {/* Begin Row */}
      <div className='row flex-row'>
        {books.map((e, i) => {
          return (
            <BookCard
              key={i}
              id={i}
              book={e}
              view={setBook}
              setId={setId}
              setComments={setComments}
            />
          );
        })}
      </div>
      {fetching ? (
        <div className='spinner' />
      ) : books.length > 47 ? (
        <div style={{ textAlign: 'center' }}>
          <button
            className='btn btn-outline-primary mb-5'
            type='button'
            onClick={loadMore}
          >
            Load more
          </button>
        </div>
      ) : (
        ''
      )}
      {/* End Row */}

      {/* Begin Modal */}
      <Modal
        show={open}
        setShow={(v) => {
          setOpen(v);
          setView(false);
        }}
        header={name}
      >
        {view ? (
          <>
            <Iframe
              url={`/fancy-reader/index.html?file=${
                status === 'approved'
                  ? file_path
                  : `https://adminapi.bambooks.io/api/getfile?filename=${
                      file_path.split('/')[file_path.split('/').length - 1]
                    }`
              }&title=${name}&status=${status}`}
              width='100%'
              height='500px'
            />
            <div className='row mt-5 mb-5'>
              <div className='col-xl-12'>
                <button
                  type='button'
                  className='btn btn-primary btn-block mb-2'
                  onClick={() => setView(false)}
                >
                  View book details
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='row mb-3 mt-3'>
              <div className='col-md-4'>
                <img
                  className='mb-4'
                  width='100px'
                  src={img}
                  alt={`${name} cover`}
                />
              </div>
              <div className='col-md-8'>
                <h5>Book Synopsis</h5>
                <div
                  className='bg-grey p-4'
                  style={{ overflowY: 'scroll', height: '100px' }}
                >
                  <p>{synopsis}</p>
                </div>
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col'>
                <h5 className='mb-3'>Rating:</h5>
                <div className='book-rating'>
                  {book.content && book.content.rating
                    ? (() => {
                        let stars = [];
                        let i = 1;
                        for (i; i < 6; i++) {
                          if (i <= book.content.rating) {
                            stars.push(
                              <i
                                className='la la-star'
                                key={i}
                                style={{ color: 'gold' }}
                              ></i>,
                            );
                          } else if (
                            i > book.content.rating &&
                            book.content.rating % 1 &&
                            i - book.content.rating < 1
                          ) {
                            stars.push(
                              <i
                                className='la la-star-half-o'
                                key={i}
                                style={{ color: 'gold' }}
                              ></i>,
                            );
                          } else {
                            stars.push(
                              <i
                                className='la la-star-o'
                                key={i}
                                style={{ color: 'lightgrey' }}
                              ></i>,
                            );
                          }
                        }
                        return stars;
                      })()
                    : (() => {
                        let i = 1;
                        let stars = [];
                        for (i; i < 6; i++) {
                          stars.push(
                            <i
                              className='la la-star-o'
                              key={i}
                              style={{ color: 'lightgrey' }}
                            ></i>,
                          );
                        }
                        return stars;
                      })()}
                </div>
              </div>

              <div className='col'>
                <h5>Author(s):</h5>
                <p>{author}</p>
              </div>

              <div className='col'>
                <h5>Publisher:</h5>
                <p>{book.content && book.content.publisher}</p>
              </div>
            </div>

            <div className='em-separator separator-dashed mb-4'></div>

            <div className='row mb-3'>
              <div className='col'>
                <h5>Released:</h5>
                <p>{year_of_release}</p>
              </div>

              <div className='col'>
                <h5>Book Type:</h5>
                <p>{book_type}</p>
              </div>

              <div className='col'>
                <h5>Book Length:</h5>
                <p>{num_of_pages} pages</p>
              </div>
            </div>

            <div className='em-separator separator-dashed mb-4'></div>

            <div className='row mb-3'>
              <div className='col'>
                <h5>Genre(s):</h5>
                <p>{book_genre}</p>
              </div>
              <div className='col'>
                <h5>Age Restriction:</h5>
                <p>{age_restriction}</p>
              </div>

              <div className='col'>
                <h5>Territories Restricted:</h5>
                <ViewReistrictedCountries
                  id={country_restriction_id}
                  country={country}
                />
              </div>
            </div>
            {book_type !== 'audiobook' && (
              <div className='row mt-5 mb-5'>
                <div className='col-xl-12'>
                  <button
                    type='button'
                    className='btn btn-primary btn-block mb-2'
                    onClick={() => setView(true)}
                  >
                    Preview this Book
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </Modal>
      <Modal
        show={show}
        setShow={setShow}
        header='Book removal'
        action={handleSubmit(removeBook)}
      >
        {deleting ? (
          <div className='spinner' />
        ) : (
          <>
            <div className='row mb-5 mt-4'>
              <div className='col-md-12'>
                <select
                  name='account'
                  className='custom-select form-control'
                  placeholder='Select Reasons'
                  ref={register({ required: true })}
                >
                  <option value=''>Select Reasons</option>
                  <option value='Restrictions due to copyright/licensing'>
                    Restrictions due to copyright/licensing
                  </option>
                  <option value='Unsatisfied with Royalties/Payments'>
                    Unsatisfied with Royalties/Payments
                  </option>
                  <option value='Product/Technical Issues'>
                    Product/Technical Issues
                  </option>
                  <option value='Poor customer service/support'>
                    Poor customer service/support
                  </option>
                  <option value='Others (please explain)'>
                    Others (please explain)
                  </option>
                </select>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12'>
                <div className='form-group row d-flex align-items-center mb-5'>
                  <textarea
                    rows='10'
                    name='message'
                    className='form-control'
                    placeholder='Add a message'
                    ref={register({ required: true })}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </Modal>
      <Modal
        show={showComments}
        setShow={setShowComments}
        header='Rejection reasons'
      >
        {comments.map((e, i) => (
          <div key={i}>
            <h4>{e.rejection_reason}</h4>
            <p>{e.comment_text}</p>
          </div>
        ))}
      </Modal>
      {/* End Modal */}
    </>
  );
}

const ViewReistrictedCountries = ({ id, country }) => {
  const [loading, setLoading] = useState();
  const [restricted, setRestricted] = useState({
    country_name: '',
    country_code: [],
  });

  const cb = (s, m) => {
    if (s) {
      let names = '';
      const codes = m.country.split(',');
      setRestricted({
        country_code: codes,
        country_name: (() => {
          country.forEach((e) => {
            e.countries.forEach((c) => {
              if (codes.includes(c.country_code)) {
                names += `${c.country_name}, `;
              }
            });
          });
          console.log(names);
          return names;
        })(),
      });
    }
  };

  return id ? (
    <p
      style={{
        cursor: 'pointer',
      }}
      onClick={() => {
        console.log(id);
        setLoading(true);
        getRestrictionsById(id, cb);
      }}
    >
      {restricted.country_name ? (
        <p>{restricted.country_name}</p>
      ) : loading ? (
        <p>loading...</p>
      ) : (
        <p>View restricted areas</p>
      )}
    </p>
  ) : (
    <p>No countries restricted</p>
  );
};
