import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';

import avatar from '../../assets/images/blank.png';
import { updateAuthor } from '../../actions/Content';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

function ViewAuthor() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [pic, setPic] = useState(location.state.photo || '');
  const [name, setName] = useState(location.state.name || '');
  const [about, setAbout] = useState(location.state.about || '');
  const [show, setShow] = useState(false);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register, errors } = useForm();

  const editAuthor = (v) => {
    if (v.photo) setLoading(true);
    if (v.name) setUpdate(true);
    updateAuthor(
      { ...v, id },
      (s, m) => {
        if (s) {
          toast.success('Details updated successfully');
          setName(v.name);
          setShow(false);
          setAbout(v.about);
          setPic(v.photo);
        } else {
          toast.error(m);
        }
        setUpdate(false);
        setLoading(false);
      },
      dispatch,
    );
  };

  const readFile = (file) => {
    if (typeof file[0] === 'object') {
      const reader = new FileReader();
      reader.addEventListener('loadend', (e) => {
        setPic(e.target.result);
        setShow(true);
      });
      reader.readAsDataURL(file[0]);
    }
  };

  const { id = 0 } = location.state || {};

  return (
    <>
      {/* Begin Page Header*/}
      <div className='row mb-5'>
        <div className='page-header'>
          <div className='d-flex align-items-center'>
            <h5 className='page-header-title'>My Authors</h5>
            <div>
              {/*<div className="page-header-tools">*/}
              {/*<a className="btn btn-gradient-01" href="#">bambooks.com</a>*/}
              {/*</div>*/}
            </div>
            <div>
              <Link to='/authors'> back to author list</Link>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Header */}
      {/* Begin Row */}
      <div className='row flex-row'>
        <div className='col-md-3'>
          <div className='card'>
            {loading ? (
              <div className='spinner' />
            ) : (
              <>
                <div className='cover-image mx-auto p-4'>
                  <div
                    style={{
                      height: 150,
                      width: 150,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center center',
                      backgroundImage: pic ? `url(${pic})` : `url(${avatar})`,
                    }}
                    className='rounded-circle mx-auto'
                    hidden={show}
                  />
                  {/*<button id="selectPic" className="btn btn-primary">Change</button>*/}
                  <input
                    type='file'
                    className='d-none'
                    id='avatar-input'
                    accept='image/*'
                    onChange={(e) => readFile(e.target.files)}
                  />
                </div>
                <div
                  style={{ display: 'flex', flexDirection: 'column' }}
                  className='croppington align-items-center'
                  hidden={!show}
                >
                  <div
                    style={{
                      width: 150,
                      height: 150,
                      marginBottom: '10px',
                      backgroundSize: 'cover',
                      backgroundImage: `url(${pic})`,
                      backgroundPosition: 'center center',
                    }}
                    className='rounded-circle mx-auto'
                  />
                  <button
                    id='saveAvatar'
                    style={{ flex: '0 0 auto' }}
                    className='btn btn-primary'
                    onClick={() => editAuthor({ photo: pic.trim() })}
                  >
                    Save picture
                  </button>
                </div>
                <div className='text-center'>
                  <button
                    style={{
                      wordWrap: 'break-word',
                      width: '100%',
                      maxWidth: '220px',
                    }}
                    id='selectPic'
                    href='#'
                    className='btn btn-outline-light text-center mt-2 mb-3'
                    onClick={() =>
                      document.getElementById('avatar-input').click()
                    }
                  >
                    <i className='ti-image'></i>&nbsp;Tap to change avatar
                  </button>
                </div>
              </>
            )}
          </div>
        </div>

        <div className='col-md-8'>
          <div className='card'>
            <div className='card-header'>
              <div className='card-title'>Edit Author Details</div>
            </div>
            <div className='card-body'>
              {update ? (
                <div className='spinner' />
              ) : (
                <form onSubmit={handleSubmit(editAuthor)}>
                  <div className='row'>
                    <div className='col-md-12 text-center'>
                      <div className='job text-left'>Author Name</div>
                      <input
                        className='name mb-4 form-control'
                        defaultValue={name}
                        name='name'
                        placeholder="Author's name"
                        ref={register({
                          required: true,
                        })}
                      />
                      {errors.name && (
                        <span className='text-danger'>
                          Authors name cannot be empty
                        </span>
                      )}
                      <div className='social-friends owl-carousel mb-3'>
                        <div className='item'>
                          <div className='quick-about'>
                            <div className='row mb-3 d-flex align-items-center'>
                              <div className='col-12 text-left'>
                                <div className='job'>Author Bio</div>
                                <textarea
                                  rows='8'
                                  cols='9'
                                  name='about'
                                  defaultValue={about}
                                  placeholder='Author bio'
                                  className='form-control mb-4'
                                  ref={register({
                                    required: true,
                                  })}
                                />
                                {errors.about && (
                                  <span className='text-danger'>
                                    Please enter a bio for this author
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row pull-right mr-1'>
                        <button
                          type='button'
                          className='btn btn-shadow'
                          data-dismiss='modal'
                          onClick={() => history.goBack()}
                        >
                          Discard
                        </button>
                        <button type='submit' className='btn btn-primary'>
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
        {/* End Row */}
      </div>
    </>
  );
}

export default connect(null)(ViewAuthor);
