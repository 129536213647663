export function getDurationInHMS(timeSeconds) {
  timeSeconds = Number(timeSeconds);
  var h = Math.floor(timeSeconds / 3600);
  var m = Math.floor((timeSeconds % 3600) / 60);
  var s = Math.floor((timeSeconds % 3600) % 60);

  let mStr = (m < 9 ? '0' : '') + m;
  let sStr = (s < 9 ? '0' : '') + s;
  return `${h}:${mStr}:${sStr}`;
}
