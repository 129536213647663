import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AuthorCard from './AuthorCard';

import { getAuthors, searchAuthors } from '../../actions/Content';
import Spinner from '../Spinner';

let noBio = [];

export default function ListAuthors() {
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const { authors = { data: [], offset: 0 } } = useSelector((s) => s.Content);

  useEffect(() => {
    const data = {
      limit: 48,
      offset: 1,
      type: 'new',
    };
    getAuthors(data, () => setLoading(false), dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (query === '') setResult([]);
  }, [query]);

  useEffect(() => {
    noBio = authors.data.filter((e) => !e.about);
  }, [authors]);

  const searchForAuthor = () => {
    const data = {
      partner_id: 7,
      text: query,
    };
    setLoading(true);
    searchAuthors(data, (result) => {
      setResult(result);
      setLoading(false);
    });
  };

  const loadMore = () => {
    const data = {
      limit: 48,
      offset: authors.offset + 1,
    };
    setFetching(true);
    getAuthors(data, () => setFetching(false), dispatch);
  };

  return loading ? (
    <Spinner />
  ) : (
    <>
      {noBio.length > 0 && (
        <div className='page-header'>
          <div className='d-flex align-items-center'>
            <div className='page-header-title'>
              <div
                className='alert alert-outline-danger dotted fade show'
                role='alert'
              >
                <strong>Hey!</strong> You need to add bios for {noBio.length}{' '}
                authors
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='faq'>
        <div
          style={{ marginBottom: '100px' }}
          className=' d-flex justify-content-center align-items-center text-center'
        >
          <div className='container'>
            <div className='searchbox'>
              <input
                type='text'
                name='search'
                value={query}
                onChange={(v) => setQuery(v.target.value)}
                placeholder="Search in authors' list ..."
              />
              <button
                type='submit'
                className='btn btn-primary ml-3'
                style={{ height: '49px' }}
                onClick={searchForAuthor}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Jumbotron --> */}

      {/* Begin Row */}
      <div className='row flex-row mt-5'>
        {query ? (
          result.length === 0 ? (
            <div style={{ textAlign: 'center', width: '100%' }}>
              Press the search button to search
            </div>
          ) : (
            result.map((e, i) => {
              return <AuthorCard author={e} key={i} />;
            })
          )
        ) : authors.data.length === 0 ? (
          <div style={{ textAlign: 'center', width: '100%' }}>
            No authors have been added yet
          </div>
        ) : (
          <>
            {authors.data.map((e, i) => {
              return <AuthorCard author={e} key={i} />;
            })}
            <div style={{ textAlign: 'center', width: '100%' }}>
              {fetching ? (
                <div className='spinner' />
              ) : authors.data.length > 47 ? (
                <button
                  className='btn btn-outline-primary mb-5'
                  type='button'
                  onClick={loadMore}
                >
                  Load more
                </button>
              ) : (
                ''
              )}
            </div>
          </>
        )}
      </div>
      {/* End Row */}
    </>
  );
}
