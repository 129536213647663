import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Datetime from 'react-datetime';
import { Bar } from 'react-chartjs-2';
import { connect, useSelector, useDispatch } from 'react-redux';

import options from '../Home';
import Spinner from '../Spinner';
import { getFinanceReport } from '../../actions/Content';

function FinanceReports() {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [payouts, setPayouts] = useState(0);
  const [earnings, setEarnings] = useState(0);
  const [loading, setLoading] = useState(true);
  const [enddate, setEndDate] = useState(moment());
  const { finance } = useSelector((s) => s.Content.reports);
  const [startdate, setStartDate] = useState(moment('Sep 2016', 'MMM YYYY'));

  useEffect(() => {
    const data = {
      enddate,
    };
    if (startdate) data.startdate = startdate.format('YYYY-MM');
    getFinanceReport(
      data,
      (s, m) => {
        // if (s) {}
        setLoading(false);
      },
      dispatch,
    );
  }, [startdate, enddate, dispatch]);

  useEffect(() => {
    let TE = 0;
    let TP = 0;
    let labels = [];
    let datasets = [
      {
        label: 'Earnings',
        data: [],
        borderColor: '#fff',
        backgroundColor: '#5d5386',
        hoverBackgroundColor: '#483d77',
      },
      {
        label: 'Payouts',
        data: [],
        borderColor: '#fff',
        backgroundColor: '#e4e8f0',
        hoverBackgroundColor: '#dde1e9',
      },
    ];

    finance.forEach((e) => {
      labels.push(moment(e.report_date).format("MMM 'YY"));
      datasets[0].data.push(e.earnings);
      datasets[1].data.push(e.is_payout ? e.earnings : 0);
      TE += e.is_payout ? 0 : e.earnings;
      TP += e.is_payout ? e.earnings : 0;
    });

    setData({
      labels,
      datasets,
      options,
    });
    setEarnings(TE);
    setPayouts(TP);
  }, [finance]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      {/* Begin Page Header*/}
      <div className='row'>
        <div className='page-header'>
          <div className='d-flex align-items-center'>
            <h5 className='page-header-title'>Financial Reports</h5>
          </div>
        </div>
      </div>
      {/* End Page Header */}

      <div className='row flex-row align-items-center justify-content-center mb-5'>
        <div className='col-md-12'>
          <div className='widget has-shadow'>
            <div className='widget-body'>
              <div>
                <div className='form-group row mb-3'>
                  <div className='col-xl-6 mb-3'>
                    <label className='form-control-label'>
                      Start Date <span className='text-danger ml-2'>*</span>
                    </label>
                    <Datetime
                      dateFormat='MMM YYYY'
                      timeFormat={false}
                      defaultValue={startdate.format('MMM YYYY')}
                      isValidDate={(date) => {
                        return (
                          moment().isSameOrAfter(date.toISOString(true)) &&
                          moment(date).isSameOrBefore(enddate)
                        );
                      }}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>
                  <div className='col-xl-6 mb-3'>
                    <label className='form-control-label'>
                      End Date <span className='text-danger ml-2'>*</span>
                    </label>
                    <Datetime
                      dateFormat='MMM YYYY'
                      defaultValue={enddate.format('MMM YYYY')}
                      timeFormat={false}
                      isValidDate={(date) => {
                        return (
                          moment().isSameOrAfter(date) &&
                          moment(date).isSameOrAfter(startdate)
                        );
                      }}
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Begin Row */}
      <div className='row flex-row'>
        <div className='col-xl-4 col-md-6 col-sm-6'>
          <div className='widget has-shadow'>
            <div className='widget-header bordered no-actions d-flex align-items-center'>
              <h4 className='font-weight-bold'>Total Earnings</h4>
            </div>
            <div className='widget-body'>
              <h4>₦ {earnings}</h4>
            </div>
          </div>
        </div>
        {/* End stat 01 */}
        {/* Begin stat 02 */}
        <div className='col-xl-4 col-md-6 col-sm-6'>
          <div className='widget has-shadow'>
            <div className='widget-header bordered no-actions d-flex align-items-center'>
              <h4 className='font-weight-bold'>Total Payouts</h4>
            </div>
            <div className='widget-body'>
              <h4>₦ {payouts}</h4>
            </div>
          </div>
        </div>
        {/* End Twitter */}
      </div>
      {/* End Row */}
      {/* Begin Row */}

      <div className='row flex-row'>
        <div className='col-xl-12 col-md-12'>
          {/* Begin Widget 09 */}
          <div className='widget widget-09 has-shadow'>
            {/* Begin Widget Header */}
            <div className='widget-header d-flex align-items-center'>
              <h2>Financial Stats</h2>
              {/* <div className="selector">
                <select id="graph1-selector" className="">
                  <option value="monthly">Monthly</option>
                </select>
              </div> */}
              {/*<div className="widget-options">*/}
              {/*<button type="button" className="btn btn-shadow">View all</button>*/}
              {/*</div>*/}
            </div>
            {/* End Widget Header */}
            {/* Begin Widget Body */}
            <div className='widget-body'>
              <div className='row'>
                <div className='col-xl-12 col-12 no-padding'>
                  <div>
                    <Bar data={data} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Widget 09 */}
        </div>
      </div>
      {/* End Row */}
    </>
  );
}

export default connect(null)(FinanceReports);
