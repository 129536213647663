/* eslint-disable react/no-unescaped-entities */
import moment from 'moment';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import { useForm } from 'react-hook-form';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { searchAuthors, getBisac, getCountries } from '../../actions/Content';

import Modal from '../Modal';
import GenrePicker from './GenrePicker';
import CountryPicker from './CountryPicker';

function FillBookDetails({ setValues, hidden }) {
  const dispatch = useDispatch();
  const [type, setType] = useState('');
  const [view, setView] = useState(false);
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [author, setAuthor] = useState([]);
  const [genres, setGenres] = useState([]);
  const [country, setCountry] = useState([]);
  const [restricted, setRestricted] = useState({
    country_name: '',
    country_code: [],
  });
  const genre = useSelector((s) => s.Content.genres);
  const authorBooks = useSelector((s) => s.Content.books);
  const { handleSubmit, errors, register, watch } = useForm();

  const bType = watch('book_type');

  useEffect(() => {
    setType(bType);
  }, [bType]);

  useEffect(() => {
    getBisac(
      {
        id: [],
      },
      (s, m) => {
        if (s) {
          setGenres(m);
        }
      },
      dispatch,
    );
    getCountries(
      '',
      (s, m) => {
        if (s) {
          setCountry(m);
        }
      },
      dispatch,
    );
  }, [dispatch]);

  const getAuthors = (value) =>
    new Promise(
      (res) => {
        searchAuthors({ text: value, partner_id: 7 }, (data) => {
          res(data?.map((e) => ({ label: e.name, value: e.name })) || []);
        });
      },
      (rej) => {
        rej([]);
      },
    );

  const onSubmit = (v) => {
    if (!v.bubbles) {
      // eslint-disable-next-line no-param-reassign
      delete v.book;
      // eslint-disable-next-line no-param-reassign
      delete v.cover;

      console.log(v);

      const bisac = `${genre[0].code} ${(genre[1] && genre[1].code) || ''} ${
        (genre[2] && genre[2].code) || ''
      }`;

      const data = {
        ...v,
        author_identifier: author[0].id || 0,
        book_genre: genre[0].name,
        specific_bisac_code: genre[0].code,
        related_bisac_code: bisac.trim(),
        ...(v.related_book_id && {
          related_book_id: v.related_book_id,
          related_book_type: v.book_type === 'audiobook' ? 'book' : 'audiobook',
        }),
        ...(!v.related_book_id && {
          related_book_id: null,
          related_book_type: null,
        }),
        country_restriction: restricted.country_code,
        restricted_country: restricted.country_name,
      };

      console.log(data);
      setValues(data);
    }
  };

  return (
    <form className='mt-4' onSubmit={handleSubmit(onSubmit)} hidden={hidden}>
      <div className='form-group row mb-3'>
        <div className='col-xl-6 mb-3'>
          <label className='form-control-label'>
            Book Title <span className='text-danger ml-2'>*</span>
          </label>
          <input
            type='text'
            placeholder='Enter book title'
            className='form-control'
            name='name'
            ref={register({
              required: true,
            })}
          />
          {errors.name && 'Please enter a title for the book'}
        </div>
        <div className='col-xl-6 mb-3'>
          <div className='form-group'>
            <label className='form-control-label'>
              Author's Name
              <span className='text-danger ml-2'>*</span>
            </label>
            <div className='input-group'>
              <span className='input-group-btn'>
                <button
                  type='button'
                  className='btn btn-primary ripple'
                  onClick={() => setView(true)}
                >
                  Add
                </button>
              </span>
              <input
                readOnly
                type='text'
                name='author'
                className='form-control'
                placeholder='Select authors name'
                value={author.map((e) => `${e.value}`)}
                ref={register({
                  required: true,
                })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='form-group row mb-3'>
        <div className='col-xl-6 mb-3'>
          <label className='form-control-label'>
            Publisher's Name <span className='text-danger ml-2'>*</span>
          </label>
          <input
            type='text'
            name='publisher'
            ref={register({
              required: true,
            })}
            className='form-control'
            placeholder="Enter publishers' name"
          />
        </div>
        <div className='col-xl-6 mb-3'>
          <label className='form-control-label'>
            Release year <span className='text-danger ml-2'>*</span>
          </label>
          <Datetime
            closeOnSelect
            dateFormat='YYYY'
            inputProps={{
              readOnly: true,
              ref: register({
                required: true,
              }),
              name: 'year_of_release',
              placeholder: 'Select release year',
            }}
            timeFormat=''
            initialValue={moment('', 'YYYY')}
            isValidDate={(c) => c.isBefore(moment())}
          />
        </div>
      </div>
      <div className='form-group row mb-3'>
        <div className='col-xl-6 mb-3'>
          <label className='form-control-label'>
            Age Restriction <span className='text-danger ml-2'>*</span>
          </label>
          <select
            name='age_restriction'
            ref={register({ required: true })}
            className='custom-select form-control'
          >
            <option></option>
            <option value='Adult'>Adult (18 and above)</option>
            <option value='Kids'>Kids (11 and Under)</option>
            <option value='Teen'>Teens (12yrs - 17yrs)</option>
          </select>
        </div>
        <div className='col-md-6 mb-3'>
          <div className='form-group'>
            <label className='form-control-label'>
              Genres <span className='text-danger ml-2'>*</span>
            </label>
            <div className='input-group'>
              <span className='input-group-btn'>
                <button
                  type='button'
                  className='btn btn-primary ripple'
                  onClick={() => setOpen(true)}
                >
                  Add
                </button>
              </span>
              <input
                readOnly
                type='text'
                name='book_genre'
                className='form-control'
                value={genre.map((e) => ` ${e.name}`)}
                ref={register({
                  require: true,
                  validate: () => !!genre[0],
                })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='form-group row mb-3'>
        <div className='col-md-6 mb-3'>
          <label className='form-control-label'>
            Book Type
            <span className='text-danger ml-2'>*</span>
          </label>
          <select
            name='book_type'
            ref={register({ required: true })}
            className='custom-select form-control'
          >
            <option></option>
            <option value='book'>Book</option>
            <option value='magazine'>Magazine</option>
            <option value='comic'>Comics</option>
            <option value='audiobook'>Audiobook</option>
          </select>
        </div>
        <div className='col-md-6 mb-3'>
          <label className='form-control-label'>Content Restriction</label>
          <div className='input-group'>
            <span className='input-group-btn'>
              <button
                type='button'
                className='btn btn-primary ripple'
                onClick={() => setShow(!show)}
              >
                Add
              </button>
            </span>
            <input
              type='text'
              className='form-control'
              readOnly
              name='country_restriction'
              value={restricted.country_name}
              ref={register()}
            />
          </div>
        </div>
      </div>

      <div className='form-group row mb-3'>
        <div className='col-md-6 mb-3'>
          <label className='form-control-label'>Related content</label>
          <select
            ref={register()}
            required={false}
            name='related_book_id'
            className='custom-select form-control'
          >
            <option value='' />
            {authorBooks.data?.map((e) =>
              e.book_type.toLowerCase() !== type && e.status === 'approved' ? (
                <option value={e.content.id}>{e.name}</option>
              ) : (
                ''
              ),
            )}
          </select>
        </div>
        {type === 'audiobook' && (
          <div className='col-md-6 mb-3'>
            <label className='form-control-label'>
              Narrated by:
              <span className='text-danger ml-2'>*</span>
            </label>
            <input
              type='text'
              name='narrated_by'
              ref={register({
                required: true,
              })}
              className='form-control'
              placeholder='Enter the narrators name'
            />
          </div>
        )}
      </div>

      <div className='form-group row mb-3'>
        <div className='col-md-12 mb-3'>
          <label className='form-control-label'>
            Book Synopsis
            <span className='text-danger ml-2'>*</span>
          </label>
          <textarea
            className='form-control'
            placeholder='Copy and paste your synopsis here'
            name='synopsis'
            ref={register({ required: true, minLength: 150, maxLength: 1000 })}
            id='synopsis'
            cols='30'
            rows='10'
          />
          <small className='text-info'>Character limit: 150 - 1000.</small>
        </div>
      </div>

      <ul className='pager wizard text-right'>
        <li className='previous d-inline-block'>
          <button type='button' className='btn btn-secondary ripple'>
            Cancel
          </button>
        </li>{' '}
        <li className='next d-inline-block'>
          <button type='submit' className='btn btn-gradient-01'>
            Next
          </button>
        </li>
      </ul>
      <Modal
        show={show}
        setShow={setShow}
        header='Content restriction'
        action={() => {
          setShow(false);
        }}
      >
        <div style={{ maxHeight: '60vh', overflowY: 'scroll' }}>
          <p>Check region to restrict access</p>
          <CountryPicker
            country={country}
            selected={restricted}
            setSelected={setRestricted}
            save={show}
          />
        </div>
      </Modal>
      <Modal
        show={open}
        setShow={setOpen}
        header='Pick 3 genres'
        action={() => setOpen(false)}
      >
        <GenrePicker data={genres} />
      </Modal>
      <Modal
        show={view}
        setShow={setView}
        header='Choose Author'
        action={() => setView(false)}
      >
        <AsyncCreatableSelect
          isMulti
          name='author'
          cacheOptions
          loadOptions={getAuthors}
          onChange={setAuthor}
          className='select'
        />
      </Modal>
    </form>
  );
}

FillBookDetails.propTypes = {
  setValues: PropTypes.func.isRequired,
};

const mapState = (state) => {
  const { authors } = state.Content;
  return {
    authors: authors.data,
  };
};

export default connect(mapState)(FillBookDetails);
