/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';

// Images
import avatar from '../../assets/images/blank.png';

function AuthorCard({ author }) {
  const { name, about, image, photo } = author;

  return (
    <>
      <div
        className="col-xl-3 col-md-4 col-sm-6 col-remove"
        style={{ marginBottom: '50px' }}
      >
        {/* Begin Card */}
        <div className="widget-image has-shadow">
          <div className="contact-card-2">
            {/* Begin Widget Body */}
            <div className="widget-body">
              <div className="cover-image mx-auto">
                <div
                  style={{
                    backgroundImage: image
                      ? `url(data:image/png;base64,${photo})`
                      : `url(${avatar})`,
                    height: 100,
                    backgroundSize: 'cover',
                    border: '5px solid white',
                  }}
                  className="rounded-circle mx-auto"
                />
              </div>
              <Link
                to={{ pathname: '/authors/view', state: author }}
                style={{ display: 'block' }}
              >
                <h4 className="name" style={{ textTransform: 'capitalize' }}>
                  {name}
                </h4>
                <div className="social-friends owl-carousel mb-3">
                  <div className="item">
                    <div className="quick-about">
                      <div className="row d-flex align-items-center">
                        <div className="col-12">
                          {/*<h4>About Author</h4>*/}
                          {about ? (
                            <p className="hide-text">{about}</p>
                          ) : (
                              <p className="text-danger btn btn-outline-danger">
                                click here to add bio
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            {/* End Widget Body */}
          </div>
        </div>
        {/* End Card */}
      </div>
    </>
  );
}

export default AuthorCard;
