import moment from 'moment';
import Datetime from 'react-datetime';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import React, { useState, useEffect } from 'react';
import { useLocation, Redirect, Link, useHistory } from 'react-router-dom';
import AsyncCreatableSelect from 'react-select/async-creatable';

import Modal from '../Modal';
import GenrePicker from './GenrePicker';
import CountryPicker from './CountryPicker';
import {
  editBook,
  getBisac,
  getCountries,
  searchAuthors,
  getRestrictionsById,
} from '../../actions/Content';

export default function EditBooks() {
  const history = useHistory();
  const loc = useLocation();
  const [files, setFile] = useState({
    book_base64: '',
    cover_base64: '',
    original_book_name: '',
    original_cover_name: '',
  });
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [view, setView] = useState(false);
  const [genres, setGenres] = useState([]);
  const [loading, setLoading] = useState();
  const [authors, setAuthor] = useState([]);
  const [terms, setTerms] = useState(false);
  const [country, setCountry] = useState([]);
  const { handleSubmit, register } = useForm();
  const [restricted, setRestricted] = useState({
    country_name: '',
    country_code: [],
  });
  const genre = useSelector((s) => s.Content.genres);

  useEffect(() => {
    getBisac(
      {
        id: [],
      },
      (s, m) => {
        if (s) {
          setGenres(m);
        }
      },
      dispatch
    );

    getCountries(
      '',
      (s, m) => {
        if (s) {
          setCountry(m);
        }
      },
      dispatch
    );
  }, [dispatch]);

  useEffect(() => {
    if (country.length && loc.state.country_restriction_id) {
      getRestrictionsById(
        loc.state.country_restriction_id,
        (s, m) => {
          if (s) {
            let names = '';
            const codes = m.country.split(',');
            setRestricted({
              country_code: codes,
              country_name: (() => {
                country.forEach((e) => {
                  e.countries.forEach((c) => {
                    if (codes.includes(c.country_code)) {
                      names += `${c.country_name}, `;
                    }
                  });
                });
                return names;
              })(),
            });
          }
        },
        dispatch
      );
    }
    // eslint-disable-next-line
  }, [country.length, loc.state.country_restriction_id, dispatch]);

  if (!loc.state) return <Redirect to='/books' />;

  const {
    name = '',
    status = '',
    author = '',
    synopsis = '',
    book_type = '',
    book_genre = '',
    num_of_pages = '',
    year_of_release = '',
    age_restriction = '',
  } = loc.state;

  console.log(book_type);

  const { publisher = '', publishedDate = '' } = loc.state.content;

  const readFile = (file, type) => {
    if (typeof file[0] === 'object') {
      const reader = new FileReader();
      reader.addEventListener('loadend', (e) => {
        if (type === 'cover')
          setFile({
            ...files,
            original_cover_name: file[0].name,
            cover_base64: e.target.result,
          });
        if (type === 'book')
          setFile({
            ...files,
            original_book_name: file[0].name,
            book_base64: e.target.result,
          });
      });
      reader.readAsDataURL(file[0]);
    }
  };

  const getAuthors = (value) =>
    new Promise(
      (res) => {
        searchAuthors({ text: value }, (data) => {
          res(
            data.map((e) => {
              return { label: e.name, value: e.name };
            })
          );
        });
      },
      (rej) => {
        rej([]);
      }
    );

  const submit = (v) => {
    if (!v.bubbles) {
      delete v.book;
      delete v.cover;
      setLoading(true);
      editBook(
        {
          ...loc.state,
          ...files,
          ...v,
          content: {
            ...loc.state.content,
            publisher: v.publisher,
          },
          country_restriction: [...new Set(restricted.country_code)],
        },
        (s, m) => {
          if (s) {
            history.push('/books');
          } else {
            setLoading(false);
          }
        },
        dispatch
      );
    }
  };

  return (
    <>
      <div className='row'>
        <div className='page-header'>
          <div className='d-flex align-items-center'>
            <h2 className='page-header-title'>Edit Book</h2>
            <div>
              <Link to='/books'>&lt; back to book list</Link>
            </div>
          </div>
        </div>
      </div>

      <div className='row flex-row'>
        <div className='col-12'>
          <div className='widget has-shadow'>
            <div className='widget-header bordered no-actions d-flex align-items-center'>
              <h4 className='font-weight-bold'>{name}</h4>
            </div>
            <div className='widget-body'>
              {loading ? (
                <div className='spinner' />
              ) : (
                <form onSubmit={handleSubmit(submit)}>
                  <div className='form-group row mb-3 mt-4'>
                    <div className='col-xl-6 mb-3'>
                      <label className='form-control-label'>
                        Book Title<span className='text-danger ml-2'>*</span>
                      </label>
                      <input
                        type='text'
                        placeholder='add book title'
                        className='form-control'
                        name='name'
                        defaultValue={name}
                        ref={register({
                          required: true,
                        })}
                      />
                    </div>

                    <div className='col-xl-6 mb-3'>
                      <div className='form-group'>
                        <label className='form-control-label'>
                          Author&apos;s Name
                          <span className='text-danger ml-2'>*</span>
                        </label>
                        <div className='input-group'>
                          <span className='input-group-btn'>
                            <button
                              type='button'
                              className='btn btn-primary ripple'
                              onClick={() => setView(true)}
                            >
                              Add
                            </button>
                          </span>
                          <input
                            type='text'
                            value={authors.length ? authors.map((e) => ` ${e.value}`) : author}
                            className='form-control'
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {status === 'pending' && (
                    <div className='form-group row mb-3'>
                      <div className='col-xl-6 mb-3'>
                        <div className='form-group'>
                          <label className='form-control-label'>
                            Book Cover<span className='text-danger ml-2'>*</span>
                          </label>
                          <div className='input-group'>
                            <span className='input-group-btn'>
                              <button
                                type='button'
                                className='btn btn-primary ripple'
                                onClick={() => {
                                  document.getElementById('cover').click();
                                }}
                              >
                                Browse
                              </button>
                            </span>
                            <input
                              disabled
                              type='text'
                              className='form-control'
                              value={files.original_cover_name || ''}
                            />
                            <input
                              hidden
                              id='cover'
                              type='file'
                              name='cover'
                              accept='image/*'
                              ref={register({
                                validate: (e) => e[0] && e[0].size < 10485760,
                              })}
                              onChange={(e) => readFile(e.target.files, 'cover')}
                            />
                          </div>
                          <small className='text-info'>
                            Only JPG, PNG formats are allowed; File Size limit - 10MB
                          </small>
                        </div>
                      </div>

                      {book_type !== 'audiobook' && (
                        <div className='col-xl-6 mb-3'>
                          <div className='form-group'>
                            <label className='form-control-label'>
                              Select Book
                              <span className='text-danger ml-2'>*</span>
                            </label>
                            <div className='input-group'>
                              <span className='input-group-btn'>
                                <button
                                  type='button'
                                  className='btn btn-primary ripple'
                                  onClick={() => {
                                    document.getElementById('book').click();
                                  }}
                                >
                                  Browse
                                </button>
                              </span>
                              <input
                                disabled
                                type='text'
                                className='form-control'
                                value={files.original_book_name || ''}
                              />
                              <input
                                hidden
                                id='book'
                                name='book'
                                type='file'
                                accept='.epub, .zip'
                                ref={register({
                                  validate: (e) => e[0] && e[0].size < 52428800,
                                })}
                                onChange={(e) => readFile(e.target.files, 'book')}
                              />
                            </div>
                            <small className='text-info'>
                              Only EPUB format is allowed; File Size limit - 50MB
                            </small>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  <div className='form-group row mb-3'>
                    <div className='col-xl-6 mb-3'>
                      <div className='form-group'>
                        <label className='form-control-label'>
                          {`Publisher's Name`}
                          <span className='text-danger ml-2'>*</span>
                        </label>
                        <input
                          type='text'
                          name='publisher'
                          defaultValue={publisher}
                          className='form-control'
                          ref={register({
                            required: true,
                          })}
                        />
                      </div>
                    </div>

                    <div className='col-xl-6 mb-3'>
                      <label className='form-control-label'>
                        Year of Book Release
                        <span className='text-danger ml-2'>*</span>
                      </label>
                      <Datetime
                        closeOnSelect
                        dateFormat='YYYY'
                        inputProps={{
                          readOnly: true,
                          ref: register({
                            required: true,
                          }),
                          name: 'year_of_release',
                        }}
                        timeFormat=''
                        initialValue={moment(year_of_release || publishedDate, 'YYYY')}
                        isValidDate={(c) => c.isBefore(moment())}
                      />
                    </div>
                  </div>

                  <div className='form-group row mb-3'>
                    <div className='col-xl-6 mb-3'>
                      <label className='form-control-label'>
                        Age Restriction
                        <span className='text-danger ml-2'>*</span>
                      </label>
                      <select
                        name='age_restriction'
                        defaultValue={age_restriction}
                        ref={register({ required: true })}
                        className='custom-select form-control'
                      >
                        <option value='Adult'>Adult (18 and above)</option>
                        <option value='Kids'>Kids (11 and Under)</option>
                        <option value='Teens'>Teens (12yrs - 17yrs)</option>
                      </select>
                    </div>

                    <div className='col-xl-6 mb-3'>
                      <div className='form-group'>
                        <label className='form-control-label'>
                          Genre<span className='text-danger ml-2'>*</span>
                        </label>
                        <div className='input-group'>
                          <span className='input-group-btn'>
                            <button
                              type='button'
                              className='btn btn-primary ripple'
                              onClick={() => setOpen(true)}
                            >
                              Add
                            </button>
                          </span>
                          <input
                            readOnly
                            type='text'
                            name='book_genre'
                            className='form-control'
                            value={genre.length ? genre.map((e) => ` ${e.name}`) : book_genre}
                            ref={register({
                              require: true,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {book_type !== 'audiobook' && (
                    <div className='form-group row mb-3'>
                      <div className='col-xl-6 mb-3'>
                        <div className='form-group'>
                          <label className='form-control-label'>
                            Length of Book
                            <span className='text-danger ml-2'>*</span>
                          </label>
                          <input
                            type='text'
                            name='num_of_pages'
                            className='form-control'
                            defaultValue={num_of_pages}
                            ref={register({ required: true })}
                            placeholder='specify book length'
                          />
                        </div>
                      </div>

                      <div className='col-xl-6 mb-3'>
                        <label className='form-control-label'>
                          Book Type<span className='text-danger ml-2'>*</span>
                        </label>
                        <select
                          name='book_type'
                          defaultValue={book_type}
                          ref={register({ required: true })}
                          className='custom-select form-control'
                        >
                          <option value='book'>Book</option>
                          <option value='magazine'>Magazine</option>
                          <option value='comic'>Comics</option>
                        </select>
                      </div>
                    </div>
                  )}

                  <div className='form-group'>
                    <label className='form-control-label'>Content Restriction</label>
                    <div className='input-group'>
                      <span className='input-group-btn'>
                        <button
                          type='button'
                          className='btn btn-primary ripple'
                          onClick={() => setShow(!show)}
                        >
                          Add
                        </button>
                      </span>
                      <input
                        type='text'
                        className='form-control'
                        readOnly
                        name='country_restriction'
                        value={restricted.country_name || 'Available worldwide'}
                        ref={register()}
                      />
                    </div>
                  </div>

                  <div className='form-group row mb-3'>
                    <div className='col-md-12 mb-3'>
                      <label className='form-control-label'>
                        Book Synopsis (About)
                        <span className='text-danger ml-2'>*</span>
                      </label>
                      <textarea
                        cols='30'
                        rows='10'
                        id='synopsis'
                        name='synopsis'
                        defaultValue={synopsis}
                        className='form-control'
                        placeholder='copy and paste your synopsis here'
                        ref={register({
                          required: true,
                          minLength: 150,
                          maxLength: 1000,
                        })}
                      />
                      <small className='text-info'>Character limit - 1000.</small>
                    </div>
                  </div>

                  <div className='form-group row mb-3'>
                    <div className='col-xl-12'>
                      <div className='styled-checkbox'>
                        <input
                          type='checkbox'
                          name='savecard'
                          id='check-card'
                          onChange={(e) => setTerms(e.target.checked)}
                        />
                        <label htmlFor='check-card'>
                          I understand and agree to the{' '}
                          <Link to='/terms'>terms and conditions</Link> and{' '}
                          <Link to='/content-policy'>content policy guidelines</Link>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className='em-separator separator-dashed'></div>
                  <div className='text-right'>
                    <Link to='/books'>
                      <button className='btn btn-shadow' type='reset'>
                        Cancel
                      </button>
                    </Link>
                    <button className='btn btn-gradient-01' type='submit' disabled={!terms}>
                      Save Changes
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        setShow={setShow}
        header='Content restriction'
        action={() => setShow(false)}
      >
        <div style={{ maxHeight: '60vh', overflowY: 'scroll' }}>
          <p>Check to region to restrict access</p>
          <CountryPicker
            country={country}
            selected={restricted}
            setSelected={setRestricted}
            save={show}
          />
        </div>
      </Modal>
      <Modal show={open} setShow={setOpen} header='Pick Genre' action={() => setOpen(false)}>
        <GenrePicker data={genres} />
      </Modal>
      <Modal show={view} setShow={setView} header='Choose Author' action={() => setView(false)}>
        <AsyncCreatableSelect
          isMulti
          name='author'
          cacheOptions
          loadOptions={getAuthors}
          onChange={setAuthor}
          className='select'
        />
      </Modal>
    </>
  );
}
