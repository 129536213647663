import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';

import options from '../Home';
import Spinner from '../Spinner';
import { getBookReport, getBooks } from '../../actions/Content';

export default function BookReports() {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [bookId, setBookId] = useState();
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('monthly');
  const books = useSelector((s) => s.Content.books.data);
  const { book = {} } = useSelector((s) => s.Content.reports);

  const { total_read_count, total_book_count, trend = [] } = book;

  useEffect(() => {
    const data = {
      filter,
    };
    if (bookId) data.book_id = bookId;
    getBookReport(data, () => setLoading(false), dispatch);

    const bkData = {
      limit: 48,
      offset: 1,
    };
    getBooks(bkData, (s, m) => {}, dispatch);
  }, [filter, bookId, dispatch]);

  useEffect(() => {
    let labels = [];
    let datasets = [
      {
        label: 'Reads',
        data: [],
        borderColor: '#fff',
        borderRadius: '10px',
        backgroundColor: '#5d5386',
        hoverBackgroundColor: '#483d77',
      },
    ];

    trend.forEach((e) => {
      labels.push(e.key);
      datasets[0].data.push(e.value);
    });

    setData({
      labels,
      datasets,
      options,
    });
  }, [trend]);

  const selectdata =
    (books &&
      books.map((e) => {
        return { label: e.name, value: e.content_id };
      })) ||
    [];

  return loading ? (
    <Spinner />
  ) : (
    <>
      {/* Begin Page Header*/}
      <div className='row'>
        <div className='page-header'>
          <div className='d-flex align-items-center'>
            <h5 className='page-header-title'>Book Reports</h5>
          </div>
        </div>
      </div>
      {/* End Page Header */}

      <div className='row flex-row mb-5'>
        <div className='col-md-12'>
          <div className='widget has-shadow'>
            <div className='widget-body'>
              {/*<label className="col-lg-5 form-control-label">Standard</label>*/}
              <div>
                <Select
                  options={selectdata}
                  onChange={(s) => setBookId(s.value)}
                  className='select'
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Begin Row */}
      <div className='row flex-row'>
        {/* Begin Face */}
        <div className='col-xl-4 col-md-6 col-sm-6'>
          <div className='widget has-shadow'>
            <div className='widget-header bordered no-actions d-flex align-items-center'>
              <h4 className='font-weight-bold'>Total Pages Read</h4>
            </div>
            <div className='widget-body'>
              <h4>{total_read_count}</h4>
            </div>
          </div>
        </div>
        {/* End stat 01 */}
        {/* Begin stat 02 */}
        <div className='col-xl-4 col-md-6 col-sm-6'>
          <div className='widget has-shadow'>
            <div className='widget-header bordered no-actions d-flex align-items-center'>
              <h4 className='font-weight-bold'>Total Books </h4>
            </div>
            <div className='widget-body'>
              <h4>{total_book_count}</h4>
            </div>
          </div>
        </div>
        {/* End stat 02 */}
      </div>
      {/* End Row */}
      {/* Begin Row */}

      <div className='row flex-row'>
        <div className='col-xl-12 col-md-12'>
          {/* Begin Widget 09 */}
          <div className='widget widget-09 has-shadow'>
            {/* Begin Widget Header */}
            <div className='widget-header d-flex align-items-center'>
              <h2>Book Stats</h2>
              <div className='selector'>
                <select
                  id='graph1-selector'
                  value={filter}
                  onChange={(v) => {
                    setLoading(true);
                    setFilter(v.target.value);
                  }}
                  className=''
                >
                  <option value='daily'>Daily</option>
                  <option value='weekly'>Weekly</option>
                  <option value='monthly'>Monthly</option>
                  <option value='yearly'>Yearly</option>
                </select>
              </div>
              {/*<div className="widget-options">*/}
              {/*<button type="button" className="btn btn-shadow">View all</button>*/}
              {/*</div>*/}
            </div>
            {/* End Widget Header */}
            {/* Begin Widget Body */}
            <div className='widget-body'>
              <div className='row'>
                <Bar data={data} />
              </div>
            </div>
          </div>
          {/* End Widget 09 */}
        </div>
      </div>
      {/* End Row */}
    </>
  );
}
