import conn from './conn';
import {
  CLEARGENRE,
  CONTENTRECIEVED,
  AUTHORSRECIEVED,
  BOOKREPORTGENERATED,
  DASHBOARDDATARECIEVED,
  FINANCEREPORTGENERATED,
} from './Types';
import { errHandler } from './Auth';

// Dashboard actions
export const getDashboardData = (_, callback, dispatch) => {
  conn
    .get('/Dashboard')
    .then((res) => {
      dispatch({
        type: DASHBOARDDATARECIEVED,
        payload: res.data.data,
      });
      callback(true);
    })
    .catch((err) => {
      errHandler(err, callback, getDashboardData, dispatch);
    });
};

// Author actions
export const getAuthors = (data, callback, dispatch) => {
  conn
    .post('/PaginatedAuthor', data)
    .then((res) => {
      dispatch({
        type: AUTHORSRECIEVED,
        payload: {
          ...res.data.data,
          offset: data.offset,
          type: data.type && data.type,
        },
      });
      callback(true);
    })
    .catch((err) => {
      errHandler(err, callback, getAuthors, dispatch);
    });
};

export const createAuthor = (data, callback, dispatch) => {
  conn
    .post('/Author', data)
    .then(() => {
      callback(true);
    })
    .catch((err) => {
      errHandler(err, callback, createAuthor, dispatch);
    });
};

export const fetchAuthors = (data, callback, dispatch) => {
  conn
    .get('/Author')
    .then((res) => {
      callback(
        true,
        res.data.data.map((e) => {
          return { label: e.name, value: e.name };
        }),
      );
    })
    .catch((err) => {
      errHandler(err, callback, fetchAuthors, dispatch);
    });
};

export const searchAuthors = (data, callback, dispatch) => {
  conn
    .get(`/AuthorSearch?text=${data.text}`)
    .then((res) => {
      return callback(res.data.data);
    })
    .catch((err) => {
      errHandler(err, callback, searchAuthors, dispatch);
    });
};

export const updateAuthor = (data, callback, dispatch) => {
  conn
    .put(`/Author/${data.id}`, data)
    .then((res) => {
      callback(true, res.data.data);
    })
    .catch((err) => {
      errHandler(err, callback, updateAuthor, dispatch);
    });
};

// Book Actions
export const getBooks = (data, callback, dispatch) => {
  conn
    .post('/PaginatedPublisherContent', data)
    .then((res) => {
      dispatch({
        type: CONTENTRECIEVED,
        payload: {
          ...res.data.data,
          offset: data.offset,
          type: data.type && data.type,
        },
      });
      callback(true);
    })
    .catch((err) => {
      errHandler(err, callback, getBooks, dispatch);
    });
};

export const createBook = (data, callback, dispatch) => {
  conn
    .post('/PublisherContent', data.data, {
      onUploadProgress: data.progressEvt,
    })
    .then((res) => {
      dispatch({
        type: CLEARGENRE,
      });
      callback(true, res.data.data.partner_id);
    })
    .catch((err) => {
      errHandler(err, callback, createBook, dispatch);
    });
};

export const createSingleBook = (data, callback, dispatch) => {
  console.log(data.file_path);
  conn
    .post('/PublishSingle', data)
    .then((res) => {
      dispatch({
        type: CLEARGENRE,
      });
      callback(true, res.data.data);
    })
    .catch((err) => {
      errHandler(err, callback, createSingleBook, dispatch);
    });
};

export const uploadFile = async (data, progressEvt) => {
  const res = await conn
    .post('/file/upload', data, {
      onUploadProgress: progressEvt,
    })
    .then((r) => {
      console.log(r.data);
      return {
        status: true,
        path: r.data.data,
      };
    })
    .catch((e) => ({
      status: false,
    }));

  return res;
};

export const chunkUpload = async (data, progressEvt) => {
  const res = await conn
    .post('/chunk/upload', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: progressEvt,
    })
    .then((r) => {
      return r.data;
    })
    .catch((e) => ({
      status: false,
      error_message: 'Failed to upload file',
    }));

  return res;
};

export const multipartUpload = async (data, progressEvt) => {
  const res = await conn
    .post('/multipart/upload', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: progressEvt,
    })
    .then((r) => {
      return r.data;
    })
    .catch((e) => ({
      status: false,
    }));

  return res;
};

export const createAudioBook = async (data, callback, dispatch) => {
  const uploads = [];
  if (data.isOneFile) {
    conn
      .post(
        '/upload',
        {
          filename: data.audio[0].filename,
          file_base64: data.audio[0].file_base64,
          content_type: data.audio[0].content_type,
        },
        {
          onUploadProgress: data.progress,
        },
      )
      .then((r) => {
        console.log(r);
        data.audio.forEach((e, i) => {
          uploads.push({
            chapter: e.name,
            path: r.data.data,
            sequence: i + 1,
            start_time: e.start_time,
            duration: e.duration,
            file_size: e.file_size,
          });
        });

        createSingleBook(
          {
            ...data.book,
            file_path: r.data.data,
            audio_files: [...uploads],
            attachment: r.data.data,
          },
          callback,
          dispatch,
        );
      })
      .catch((e) => console.log(e));
  } else {
    data.audio.forEach((e, i) => {
      uploads.push(
        conn
          .post(
            '/upload',
            {
              filename: e.filename,
              file_base64: e.file_base64,
              content_type: e.content_type,
            },
            {
              onUploadProgress: (evt) => data.progress(evt, i),
            },
          )
          .then((r) => ({
            chapter: e.name,
            path: r.data.data,
            sequence: i + 1,
            start_time: e.start_time,
            duration: e.duration,
            file_size: e.file_size,
          }))
          .catch((e) => {
            console.log();
            throw e;
          }),
      );
    });

    Promise.all(uploads)
      .then((res) => {
        // eslint-disable-next-line no-undef
        createSingleBook(
          {
            ...data.book,
            audio_files: [...res],
            file_path: res[0].path,
            attachment: res[0].path,
          },
          callback,
          dispatch,
        );
      })
      .catch();
  }
};

export const getBisac = (data, callback, dispatch) => {
  conn
    .post('/BisacCode', data)
    .then((res) => {
      callback(true, res.data.data);
    })
    .catch((err) => {
      errHandler(err, callback, getBisac, dispatch);
    });
};

export const editBook = (data, callback, dispatch) => {
  conn
    .put(`/PublisherContent/${data.id}`, data)
    .then((res) => {
      callback(true, res.date);
    })
    .catch((err) => errHandler(err, callback, editBook, dispatch));
};

export const deleteBook = (data, callback, dispatch) => {
  conn
    .delete(`/PublisherContent/${data.id}`, data)
    .then(() => {
      callback(true);
    })
    .catch((err) => {
      errHandler(err, callback, deleteBook, dispatch);
    });
};

export const getRestrictionsById = (data, callback, dispatch) => {
  conn
    .get(`/CountryRestriction/${data}`)
    .then((res) => {
      callback(true, res.data.data);
    })
    .catch((err) => {
      errHandler(err, callback, getRestrictionsById, dispatch);
    });
};

// Report Actions
export const getBookReport = (data, callback, dispatch) => {
  conn
    .post('/BookReport', data)
    .then((res) => {
      dispatch({
        type: BOOKREPORTGENERATED,
        payload: res.data.data,
      });
      callback(true);
    })
    .catch((err) => {
      errHandler(err, callback, getBookReport, dispatch);
    });
};

export const getFinanceReport = (data, callback, dispatch) => {
  conn
    .post('/PartnerRevenue', data)
    .then((res) => {
      dispatch({
        type: FINANCEREPORTGENERATED,
        payload: res.data.data,
      });
      callback(true);
    })
    .catch((err) => {
      errHandler(err, callback, getFinanceReport, dispatch);
    });
};

export const sendSupportMessage = (data, callback, dispatch) => {
  conn
    .post('/SupportTicket', data)
    .then((res) => callback(true, res.data.data))
    .catch((err) => {
      errHandler(err, callback, sendSupportMessage, dispatch);
    });
};

// Other Actions
export const getCountries = (data, callback, dispatch) => {
  conn
    .get('/GetCountriesGroupByContinent')
    .then((res) => callback(true, res.data.data))
    .catch((err) => {
      errHandler(err, callback, getCountries, dispatch);
    });
};
