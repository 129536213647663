import React from 'react';

export default function PrivacyPolicy() {
	return (
		<>
			<div>
				<div>
					<div>
						<div className="container mt-5 mb-5">
							<div>
								This privacy policy has been compiled to better serve those who
								are concerned with how their 'Personally Identifiable
								Information' (PII) is being used online. PII, is information
								that can be used on its own or with other information to
								identify, contact, or locate a single person, or to identify an
								individual in context. Please read our privacy policy carefully
								to get a clear understanding of how we collect, use, protect or
								otherwise handle your Personally Identifiable Information in
								accordance with our website.
								<br />
							</div>
							<span id="infoCo"></span>
							<br />
							<div className="grayText">
								<strong>
									What personal information do we collect from the people that
									visit our blog, website or app?
								</strong>
							</div>
							<br />
							<div>
								When ordering or registering on our site, as appropriate, you
								may be asked to enter your name, email address, phone number or
								other details to help you with your experience.
							</div>
							<br />
							<div className="grayText">
								<strong>When do we collect information?</strong>
							</div>
							<br />
							<div>
								We collect information from you when you register on our site,
								subscribe to a newsletter or enter information on our site.
							</div>
							<br />
							<span id="infoUs"></span>
							<br />
							<div className="grayText">
								<strong>How do we use your information? </strong>
							</div>
							<br />
							<div>
								We may use the information we collect from you when you
								register, make a purchase, sign up for our newsletter, respond
								to a survey or marketing communication, surf the website, or use
								certain other site features in the following ways:
								<br />
								<br />
							</div>
							<div>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> To personalize
								your experience and to allow us to deliver the type of content
								and product offerings in which you are most interested.
							</div>
							<div>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> To quickly
								process your transactions.
							</div>
							<div>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> To ask for
								ratings and reviews of services or products
							</div>
							<span id="infoPro"></span>
							<br />
							<div className="grayText">
								<strong>How do we protect your information?</strong>
							</div>
							<br />
							<div>
								Our website is scanned on a regular basis for security holes and
								known vulnerabilities in order to make your visit to our site as
								safe as possible.
								<br />
								<br />
							</div>
							<div>
								We use regular Malware Scanning.
								<br />
								<br />
							</div>
							<div>
								Your personal information is contained behind secured networks
								and is only accessible by a limited number of persons who have
								special access rights to such systems, and are required to keep
								the information confidential. In addition, all sensitive/credit
								information you supply is encrypted via Secure Socket Layer
								(SSL) technology.{' '}
							</div>
							<br />
							<div>
								We implement a variety of security measures when a user enters,
								submits, or accesses their information to maintain the safety of
								your personal information.
							</div>
							<br />
							<div>
								All transactions are processed through a gateway provider and
								are not stored or processed on our servers.
							</div>
							<span id="coUs"></span>
							<br />
							<div className="grayText">
								<strong>Do we use 'cookies'?</strong>
							</div>
							<br />
							<div>
								Yes. Cookies are small files that a site or its service provider
								transfers to your computer's hard drive through your Web browser
								(if you allow) that enables the site's or service provider's
								systems to recognize your browser and capture and remember
								certain information. For instance, we use cookies to help us
								remember and process the items in your shopping cart. They are
								also used to help us understand your preferences based on
								previous or current site activity, which enables us to provide
								you with improved services. We also use cookies to help us
								compile aggregate data about site traffic and site interaction
								so that we can offer better site experiences and tools in the
								future.
							</div>
							<div>
								<br />
								<strong>We use cookies to:</strong>
							</div>
							<div>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Understand and
								save user's preferences for future visits.
							</div>
							<div>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Keep track of
								advertisements.
							</div>
							<div>
								<br />
								You can choose to have your computer warn you each time a cookie
								is being sent, or you can choose to turn off all cookies. You do
								this through your browser settings. Since browser is a little
								different, look at your browser's Help Menu to learn the correct
								way to modify your cookies.
								<br />
							</div>
							<div>
								<br />
								<strong>If users disable cookies in their browser:</strong>
							</div>
							<br />
							<div>
								If you turn cookies off, some features will be disabled. Some of
								the features that make your site experience more efficient and
								may not function properly.
							</div>
							<br />
							<div>
								However, you will still be able to place orders Single sign on,
								Advertisement on social media.
							</div>
							<span id="trDi"></span>
							<br />
							<div className="grayText">
								<strong>Third-party disclosure</strong>
							</div>
							<br />
							<div>
								We do not sell, trade, or otherwise transfer to outside parties
								your Personally Identifiable Information unless we provide users
								with advance notice. This does not include website hosting
								partners and other parties who assist us in operating our
								website, conducting our business, or serving our users, so long
								as those parties agree to keep this information confidential. We
								may also release information when it's release is appropriate to
								comply with the law, enforce our site policies, or protect ours
								or others' rights, property or safety.
								<br />
								<br />
								However, non-personally identifiable visitor information may be
								provided to other parties for marketing, advertising, or other
								uses.
							</div>
							<span id="trLi"></span>
							<br />
							<div className="grayText">
								<strong>Third-party links</strong>
							</div>
							<br />
							<div>
								Occasionally, at our discretion, we may include or offer
								third-party products or services on our website. These
								third-party sites have separate and independent privacy
								policies. We therefore have no responsibility or liability for
								the content and activities of these linked sites. Nonetheless,
								we seek to protect the integrity of our site and welcome any
								feedback about these sites.
							</div>
							<span id="gooAd"></span>
							<br />
							<div className="blueText">
								<strong>Google</strong>
							</div>
							<br />
							<div>
								Google's advertising requirements can be summed up by Google's
								Advertising Principles. They are put in place to provide a
								positive experience for users.
								https://support.google.com/adwordspolicy/answer/1316548?hl=en
								<br />
								<br />
							</div>
							<div>We use Google AdSense Advertising on our website.</div>
							<div>
								<br />
								Google, as a third-party vendor, uses cookies to serve ads on
								our site. Google's use of the DART cookie enables it to serve
								ads to our users based on previous visits to our site and other
								sites on the Internet. Users may opt-out of the use of the DART
								cookie by visiting the Google Ad and Content Network privacy
								policy.
								<br />
							</div>
							<div>
								<br />
								<strong>We have implemented the following:</strong>
							</div>
							<div>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Remarketing
								with Google AdSense
							</div>
							<div>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Google Display
								Network Impression Reporting
							</div>
							<div>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Demographics
								and Interests Reporting
							</div>
							<br />
							<div>
								We, along with third-party vendors such as Google use
								first-party cookies (such as the Google Analytics cookies) and
								third-party cookies (such as the DoubleClick cookie) or other
								third-party identifiers together to compile data regarding user
								interactions with ad impressions and other ad service functions
								as they relate to our website.{' '}
							</div>
							<div>
								<br />
								<strong>Opting out:</strong>
								<br />
								Users can set preferences for how Google advertises to you using
								the Google Ad Settings page. Alternatively, you can opt out by
								visiting the Network Advertising Initiative Opt Out page or by
								using the Google Analytics Opt Out Browser add on.
							</div>
							<div>Cookies are for login detaills</div>
							<span id="coppAct"></span>
							<br />
							<div className="blueText">
								<strong>COPPA (Children Online Privacy Protection Act)</strong>
							</div>
							<br />
							<div>
								When it comes to the collection of personal information from
								children under the age of 13 years old, the Children's Online
								Privacy Protection Act (COPPA) puts parents in control. The
								Federal Trade Commission, United States' consumer protection
								agency, enforces the COPPA Rule, which spells out what operators
								of websites and online services must do to protect children's
								privacy and safety online.
								<br />
								<br />
							</div>
							<div>
								We do not specifically market to children under the age of 13
								years old.
							</div>
							<span id="ftcFip"></span>
							<br />
							<div className="blueText">
								<strong>Fair Information Practices</strong>
							</div>
							<br />
							<div>
								The Fair Information Practices Principles form the backbone of
								privacy law in the United States and the concepts they include
								have played a significant role in the development of data
								protection laws around the globe. Understanding the Fair
								Information Practice Principles and how they should be
								implemented is critical to comply with the various privacy laws
								that protect personal information.
								<br />
								<br />
							</div>
							<div>
								<strong>
									In order to be in line with Fair Information Practices we will
									take the following responsive action, should a data breach
									occur:
								</strong>
							</div>
							<div>We will notify you via email</div>
							<div>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Within 7
								business days
							</div>
							<div>
								<br />
								We also agree to the Individual Redress Principle which requires
								that individuals have the right to legally pursue enforceable
								rights against data collectors and processors who fail to adhere
								to the law. This principle requires not only that individuals
								have enforceable rights against data users, but also that
								individuals have recourse to courts or government agencies to
								investigate and/or prosecute non-compliance by data processors.
							</div>
							<span id="canSpam"></span>
							<br />
							<div className="blueText">
								<strong>CAN SPAM Act</strong>
							</div>
							<br />
							<div>
								The CAN-SPAM Act is a law that sets the rules for commercial
								email, establishes requirements for commercial messages, gives
								recipients the right to have emails stopped from being sent to
								them, and spells out tough penalties for violations.
								<br />
								<br />
							</div>
							<div>We collect your email address in order to:</div>
							<div>
								<br />
								<strong>
									To be in accordance with CANSPAM, we agree to the following:
								</strong>
							</div>
							<div>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Not use false
								or misleading subjects or email addresses.
							</div>
							<div>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Identify the
								message as an advertisement in some reasonable way.
							</div>
							<div>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Include the
								physical address of our business or site headquarters.
							</div>
							<div>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Monitor
								third-party email marketing services for compliance, if one is
								used.
							</div>
							<div>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Honor
								opt-out/unsubscribe requests quickly.
							</div>
							<div>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Allow users to
								unsubscribe by using the link at the bottom of each email.
							</div>
							<div>
								<strong>
									<br />
									If at any time you would like to unsubscribe from receiving
									future emails, you can email us at
								</strong>
							</div>
							<div>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Follow the
								instructions at the bottom of each email.
							</div>
							and we will promptly remove you from <strong>ALL</strong>{' '}
							correspondence.
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
