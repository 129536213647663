const store = (name, data) => {
  const rememberMe = localStorage.getItem('rememberMe') === '1';
  const engine = rememberMe ? localStorage : sessionStorage;
  if (typeof data === 'object') {
    engine.setItem(name, JSON.stringify(data));
  } else {
    engine.setItem(name, data);
  }
};

const get = (name) => {
  const rememberMe = localStorage.getItem('rememberMe') === '1';
  const engine = rememberMe ? localStorage : sessionStorage;
  try {
    return JSON.parse(engine.getItem(name));
  } catch (error) {
    return engine.getItem(name);
  }
};

const clear = (name) => {
  const rememberMe = localStorage.getItem('rememberMe') === '1';
  const engine = rememberMe ? localStorage : sessionStorage;
  if (name) {
    engine.removeItem(name);
  } else engine.clear();
};

export default {
  store,
  clear,
  get,
};
