import React from 'react';

export default function Terms() {
	return (
		<div>
			<div>
				<div className="container mb-5">
					<div className="row mt-5">
						<div className="col-md-12">
							<p>
								This <b>DIGITAL PUBLISHING AGREEMENT</b> was last updated on the
								1st of October 2019
							</p>
							<p>BETWEEN</p>
							<p>
								<b>Bambooks Limited</b>, a limited liability company having its
								registered office at No 3 Maitama Sule Street, Ikoyi, Lagos
								(referred to below as the "Licensee or Bambooks") of the first
								part.
							</p>
							<p>AND</p>
							<p>
								<b>Licensor</b>, the individual or entity identified in your
								Bambooks Digital Publishing (BDP) account (referred to as the
								"Licensor or Content Provider"), which expression shall include
								its successors-in-title and permitted assigns) of the second
								part.
							</p>
							<br />
							<br />
							<p>
								Bambooks and the Licensor shall each be referred to as a ‘Party’
								and jointly as ‘Parties.’
							</p>
							<p>WHEREAS:</p>
							<ul>
								<ol type="A">
									<li>
										Bambooks is a media and technology subsidiary of Imagine
										Concepts Limited (iConcepts) who is licensed by the Nigerian
										Communications Commission to provide value added
										mobile/fixed services, including digital services to mobile
										subscribers and internet users.
									</li>
									<li>
										The Licensor represents and warrants that it is the sole and
										exclusive owner of certain Content submitted to us through
										their BDP account.
									</li>
									<li>
										Bambooks desires to make available, sell, market and promote
										the Content through its delivery channels subject to the
										terms and conditions in this agreement.{' '}
									</li>
									<li>
										The Licensor accepts this Agreement and agrees to be bound
										by its terms by either (a) clicking agree or accept where
										you're given the option to do so or (b) by using the
										service, or any part of it. If you don't accept the terms,
										you are not entitled to use the service. If the Publisher is
										an entity, the individual person who accepts this Agreement
										for the Publisher represents and warrants that he or she is
										entitled to enter this Agreement as an authorized
										representative of Publisher and to bind Publisher to the
										terms of this Agreement.
									</li>
									<li>
										Our publishing service will change over time and the terms
										of this Agreement will need to change over time as well. We
										reserve the right to change the terms of this Agreement at
										any time in our sole discretion. We will give you notice of
										the changes by posting new terms in place of the old with a
										revision date indicated at the top or by sending an email to
										the email address then registered for your publishing
										account.
									</li>
								</ol>
							</ul>
							<br />
							<p>The parties agree as follows:</p> <br /> <br />
							<h4 className="mb-4">1. Interpretation</h4>
							<p>
								In this Agreement, the following words and expressions shall
								have the meanings assigned to them, except where the context
								otherwise requires:
							</p>
							<p>
								<b>“Bamboo Fund”</b> means the pool of money shared amongst
								licensors as royalties every month referenced in Annexure X
							</p>
							<p>
								<b>“Catalogue”</b> means all Content submitted to us which the
								Licensor, its affiliates and agent have valid and subsisting
								rights to market, sell and distribute;
							</p>
							<p>
								<b>“Confidential Information”</b> means all information or data,
								of a commercial, financial, technical, planning, management,
								legal, personal or whatever other nature concerning or relating
								to either of the Parties, their respective staff, contractors,
								officers, shareholders or technical partners in any way
								whatsoever including any information relating to the business
								activities actual or proposed of either of the Parties or any of
								their affiliates or shareholders, which is disclosed directly or
								indirectly by one Party (the Disclosing Party) to the other (the
								Recipient), whether before or after the date of this Agreement,
								whether in writing or drawings, orally or by computer print-out,
								or in any other way whatsoever;
							</p>
							<p>
								<b>“Content”</b> means all the materials/items supplied directly
								or indirectly to Bambooks by the Licensor to make available,
								sell, market and promote through its delivery channels.
							</p>
							<p>
								<b>“Delivery Channels”</b> means Bambooks designated channels
								comprising of Web, mobile apps, internet ready devices or other
								third-party platforms as may be determined by Bambooks;
							</p>
							<p>
								<b>“Delivery Schedule”</b> means the delivery schedule as may be
								communicated by Bambooks to the Licensor for provision and
								commercialization of the Content;
							</p>
							<p>
								<b>“Intellectual Property Rights”</b> means copyright, database
								rights, patents, registered or unregistered design rights,
								registered and unregistered trademarks, and all other
								intellectual property rights or trade secrets existing at any
								time in any jurisdiction and all rights that shall apply to
								them;
							</p>
							<p>
								<b>“Royalty Report”</b> means periodic statements produced by
								Bambooks showing the usage of the supplied Content;
							</p>
							<p>
								<b>“Revenue Distribution Cycle”</b> means quarterly periods when
								licensors receive their royalty payouts after earnings have been
								reconciled according to the Bamboo fund and payout model.
							</p>
							<p>
								<b>“Royalty Payouts”</b> means licensor monthly earnings
								determined by their share of total pages read as referenced in
								Annexure X.
							</p>
							<p>
								<b>“Payout Per Page”</b> means the licensor earnings per page in
								any given month referenced in Annexure X.
							</p>
							<p>
								<b>“Territory”</b> means worldwide
							</p>
							<p>
								<b>“The Service”</b> means commercial digital distribution of
								Content by the Licensee through but not limited to books,
								magazines, comics, audio books, newspapers and documents over
								the internet.
							</p>
							<p>
								<b>“Trademark”</b> means any mark of either of the Parties or
								any third party.
							</p>
							<div className="deci-lists">
								<ul>
									<ol>
										<li>
											The headings in this Agreement are inserted only for
											convenience and shall not affect its construction.
										</li>
										<li>
											Where appropriate, words denoting a singular number only
											shall include the plural and vice versa.
										</li>
										<li>
											The Annexure to this Agreement form an integral part
											hereof; words and expressions defined in this Agreement
											shall bear, unless the context otherwise requires, the
											same meaning in such Annexure.
										</li>
										<li>
											Reference to any statute or statutory provision includes a
											reference to the statute or statutory provisions as from
											time to time amended, extended or re-enacted.
										</li>
									</ol>
								</ul>
							</div>
							<br />
							<h4 className="mb-4">2. Grant of License</h4>
							<div className="deci-lists">
								<ul>
									<ol>
										<li style={{ display: 'table-column' }}></li>
										<li>
											<ol>
												<li>
													The Licensor hereby grants to Bambooks, the
													non-exclusive right and license to use, display,
													publish, transmit, distribute and sublicense the
													Content, or any portion of the Content, in connection
													with The Service within the Territory. The Licensor
													acknowledges that The Service is not exhaustive but
													shall include additional digital content and
													additional modes of transmission and types of
													exploitation through which the Content can be
													utilized.
												</li>
											</ol>
										</li>
									</ol>
								</ul>
							</div>
							<br />
							<h4 className="mb-4">3. Commencement and Duration</h4>
							<div className="deci-lists">
								<ul>
									<ol>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li>
											<ol>
												<li>
													This Agreement shall take effect from the registration
													of your BDP account or submission of any Content and
													will continue until it is terminated by us or by you
													subject to the provisions in clause 15.
												</li>
											</ol>
										</li>
									</ol>
								</ul>
							</div>
							<br />
							<h4 className="mb-4">4. Supply of Content</h4>
							<div className="deci-lists">
								<ul>
									<ol>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li>
											<ol>
												<li>
													The Licensor shall during the term of this Agreement
													update and maintain the Content and provide the same
													to the Licensor for publication and distribution via
													The Service.
												</li>
											</ol>
										</li>
									</ol>
								</ul>
							</div>
							<br />
							<h4 className="mb-4">5. Scope & Obligations of The Parties</h4>
							<div className="deci-lists">
								<ul>
									<ol>
										<li style={{ display: 'table-column' }}>&nbsp;&nbsp;</li>
										<li style={{ display: 'table-column' }}>&nbsp;&nbsp;</li>
										<li style={{ display: 'table-column' }}>&nbsp;&nbsp;</li>
										<li style={{ display: 'table-column' }}>&nbsp;&nbsp;</li>
										<li>
											<ol>
												<li>
													<b>Bambooks Shall:</b>
													<ol>
														<li>
															Obtain all necessary approvals, permissions or
															licenses from any government regulator for The
															Service, as may be required to provide The
															Service;{' '}
														</li>
														<li>
															ensure that The Service provided is not used for
															any illegal purpose, or for the transmission or
															offering of any information or services which are
															unsolicited, libelous, unlawful, abusive,
															threatening, harmful, defamatory in any way;
														</li>
														<li>
															Not assign the rights and obligations of this
															license to any other party without the prior
															express permission in writing of the Licensor;
														</li>
														<li>
															Host Content on its servers and provide
															specifications and support for Content Provider to
															deliver their Content for upload and distribution
															to users in accordance with this Agreement.
														</li>
														<li>
															Provide a technical platform that will perform the
															necessary functions required to deliver the
															Content to subscribers through multiple channels.
														</li>
														<li>
															Make available, market, sell and promote the
															Content to users within the Territory.
														</li>
														<li>
															Share with the Licensor monthly information for
															the Content distributed using The Service.
														</li>
													</ol>
													<li>
														<b>The Licensor shall:</b>
														<ol>
															<li>provide the Content to the Licensee;</li>
															<li>
																Ensure any information provided to Bambooks is
																accurate, complete and true.
															</li>
															<li>
																Ensure that all Content provided to Bambooks
																does not and shall not include any unlawful
																material and/or use expressions which are
																forbidden by law and including inter alia any
																material that impairs or infringes or might
																impair or infringe others’ property rights
																including intellectual property, any computer
																software, code or application that contains
																malicious applications or the like; any material
																that is defamatory or an infringement of
																privacy; any material which is legally
																prohibited and/or may constitute any or all of
																the above.
															</li>
														</ol>
													</li>
												</li>
											</ol>
										</li>
									</ol>
								</ul>
							</div>
							<br />
							<h4 className="mb-4">6. Consideration, Costs & Royalties</h4>
							<div className="deci-lists">
								<ul>
									<ol>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li>
											<ol>
												<li>
													The gross sales revenues generated from the
													distribution of the Content are subject to statutory
													billing deductions and taxes which include but are not
													limited to payment partner fees as contained in
													Annexure “X”, value added tax, withholding tax, and
													other applicable taxes as required during the Initial
													Term of this Agreement.
												</li>
												<li>
													The cost of marketing shall be fully borne by
													Bambooks. However, the Licensor undertakes to ensure
													that The Service and The Content receive maximum
													publicity on the Licensor’s social media pages and
													other avenues that may present itself.{' '}
												</li>
												<li>
													Bambooks shall compute the royalty payout due to
													Licensor pursuant to this Agreement within thirty (30)
													days after the end of a Revenue Distribution. Also,
													Bambooks shall provide the Licensor with access to BDP
													to view royalty reports and consumption of their
													content in real time.
												</li>
												<li>
													All payments under this Agreement shall be payable
													within five (5) working days after the computation of
													royalty reports subject to the Licensor’s receipt of
													settlement from the payment partner.
												</li>
												<li>
													Bambooks shall be responsible for collections from the
													payment partners and making payments to the Licensor
													in respect of sales of Content subject to the
													provisions of this clause 6. All payments made to the
													Licensor shall be subject to withholding tax (“WHT”)
													at the applicable rate.
												</li>
												<li>
													All sums payable under this Agreement shall be paid in
													Nigerian Naira.
												</li>
												<li>
													Bambooks shall not be responsible for any cost outside
													of or not specifically provided for as Bambooks
													obligation in this Agreement.{' '}
												</li>
											</ol>
										</li>
									</ol>
								</ul>
							</div>
							<br />
							<h4 className="mb-4">7. Force Majeure</h4>
							<div className="deci-lists">
								<ul>
									<ol>
										<li style={{ display: 'table-column' }}>&nbsp;</li>
										<li style={{ display: 'table-column' }}>&nbsp;</li>
										<li style={{ display: 'table-column' }}>&nbsp;</li>
										<li style={{ display: 'table-column' }}>&nbsp;</li>
										<li style={{ display: 'table-column' }}>&nbsp;</li>
										<li style={{ display: 'table-column' }}>&nbsp;</li>
										<li>
											<ol>
												<li>
													No Party to this Agreement shall be liable for any
													failure to fulfill its obligations hereunder apart
													from obligations to make payments, where such failure
													is caused by circumstances outside the reasonable
													control of such Party including but not limited to any
													Act of God, insurrection or civil disorder, war or
													military operations, national or local emergency, acts
													or omissions of Government, fire, lightning (each an
													“event of force majeure”). Both Parties agree that any
													of the events mentioned in this clause 7.1 shall
													qualify as an event of force majeure only when it
													cannot be resolved through the exercise of a Party’s
													reasonable effort and the force majeure event is
													unforeseeable.
												</li>
												<li>
													The Party affected by the event of force majeure shall
													promptly notify the other Party in writing of the
													occurrence of the Force Majeure event within five (5)
													days of its occurrence. Such written notice shall
													provide the estimated extent and duration of such
													inability to perform the obligations.
												</li>
												<li>
													Upon cessation of the event of force majeure, the
													Party relying on the relief shall promptly notify the
													other of such cessation.
												</li>
												<li>
													If the force majeure event continues for a period of
													thirty (30) days, the Party not claiming relief under
													this clause 7 shall have the right to terminate this
													Agreement upon giving fourteen (14) days’ written
													notice of such termination to the other Party.
												</li>
											</ol>
										</li>
									</ol>
								</ul>
							</div>
							<br />
							<h4 className="mb-4">8. Waiver</h4>
							<div className="deci-lists">
								<ul>
									<ol>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li>
											<ol>
												<li>
													The failure of either party to exercise any right, or
													claim any remedy, contingent upon the default of the
													other party in performing any of its obligations under
													this Agreement, shall not be deemed or construed as a
													waiver of such right or remedy under this Agreement.
												</li>
											</ol>
										</li>
									</ol>
								</ul>
							</div>
							<br />
							<h4 className="mb-4">9. Confidentiality</h4>
							<div className="deci-lists">
								<ul>
									<ol>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li>
											<ol>
												<li>
													{' '}
													Unless otherwise agreed to in writing, the Parties
													agree to keep the contents of this Agreement and all
													related activities and documents strictly confidential
													unless the disclosure of such information by either
													Party is required under law or the order of any court
													of law, in which case such Party shall first inform
													the other Party before disclosing any of the other
													Party’s information. In respect of certain information
													that either Party may reveal to the other Party, the
													other Party hereby agrees to keep such information
													confidential.
												</li>
											</ol>
										</li>
									</ol>
								</ul>
							</div>
							<br />
							<h4 className="mb-4">10. Notices and Domicilia</h4>
							<div className="deci-lists">
								<ul>
									<ol>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li>
											<ol>
												<li>
													{' '}
													The Parties agree that their respective addresses set
													out in this clause for all purposes arising out of or
													in connection with this Agreement at which addresses
													all processes and notices arising out of or in
													connection with this Agreement, its breach or
													termination may validly be served upon or delivered to
													either of the Parties.
												</li>
												<li>
													{' '}
													For the purposes of this Agreement the Parties'
													respective addresses shall be- <br />
													<br />
													<br />
													<div className="row">
														<div className="col">
															<p>Bambooks Limited </p>
															<p>3 Maitama Sule Street, Ikoyi, Lagos</p>
															<p>Attention: Chief Executive Officer </p>
															<p>Phone: +234 (0) 12918673</p>
															<p>
																Email:{' '}
																<a href="mailto:info@bambooks.io">
																	info@bambooks.io
																</a>{' '}
															</p>
														</div>
														<div className="col">
															<p>As stated in your BDP account </p>
															<hr />
															<p>Attention:</p>
															<p>Phone:</p>
															<p>Email:</p>
														</div>
													</div>
												</li>
											</ol>
										</li>
									</ol>
								</ul>
							</div>
							<br />
							<h4 className="mb-4">11. No Assignment</h4>
							<div className="deci-lists">
								<ul>
									<ol>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li>
											<ol>
												<li>
													{' '}
													Neither Party may assign its rights, title, benefits,
													interests, obligations, and liabilities in this
													Agreement to any third party without the prior
													approval of the other Party.
												</li>
											</ol>
										</li>
									</ol>
								</ul>
							</div>
							<br />
							<h4 className="mb-4">12. Warranties and Representations</h4>
							<p>The Licensor warrants and represents that:</p>
							<div className="deci-lists">
								<ul>
									<ol>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li>
											<ol>
												<li>
													it has full capacity and authority and all necessary
													consents to enter into and perform its obligations and
													grant the rights to the Licensee under this Agreement
													and is not under any disability, restrictions or
													prohibition, court order or judgment that might
													prevent it from performing its obligations under this
													Agreement.
												</li>
												<li>
													in entering this Agreement, it has not breached any
													agreement or infringed on the rights of any third
													party;
												</li>
												<li>
													as at the Commencement date, all information provided
													to Bambooks is true,accurate and not misleading;
												</li>
												<li>
													it is not subject to any contractual obligation,
													compliance with which is likely to have an adverse
													effect on its ability to perform its obligations under
													this Agreement; and
												</li>
												<li>
													it owns and has obtained a valid license for all
													intellectual property rights that are necessary for
													the performance of its obligations under this
													Agreement. Intellectual property rights for the
													purposes of this Agreement shall comprise of all
													copyrights,trademarks proprietary information,
													trademarks, service marks, patent rights, trade
													secrets in relation to the Content;
												</li>
												<li>
													it has made full disclosures on any restrictions to
													which any Content is subject,existing at the of time
													of execution of this Agreement and hereby undertakes
													to continue to make such full disclosure throughout
													the Term as Content is acquired and supplied in
													accordance with the terms and provisions of this
													Agreement.
												</li>
												<li>
													all content to be provided to Bambooks shall not (i)
													invade any right of privacy or publicity (ii) shall
													not be libelous, obscene or defamatory of any person;
												</li>
												<li>
													All Content provided to Bambooks shall be technically
													satisfactory for sale and exploitation on The Service
													according to the format mentioned in Annexure Y.
												</li>
											</ol>
										</li>
									</ol>
								</ul>
							</div>
							<br />
							<h4 className="mb-4">13. Indemnities</h4>
							<div className="deci-lists">
								<ul>
									<ol>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li>
											<ol>
												<li>
													The Licensor shall fully indemnify and hold harmless
													Bambooks, its directors, officers and employees from
													and against any and all losses, liabilities, damages,
													costs or expenses (including reasonable attorney’s
													fees and costs) arising out of a claim,judgment, award
													or by in favor of a third party by reason of a breach
													of any warranty,representation, covenant or obligation
													of the Licensor under this Agreement, or any
													successful claim that any Content or portion thereof
													provided to Bambooks under thisAgreement violates or
													infringes the rights of another third party.
												</li>
												<li>
													Except where directly attributable to the negligent
													acts or omissions of The Licensor,Bambooks shall fully
													indemnify and hold harmless The Licensor from and
													against any and all losses, liabilities, damages,
													costs or expenses (including reasonable attorney’s
													fees and costs) arising out of a claim, judgment,
													award or by in favor of a third party by reason of a
													breach of any warranty, representation, covenant or
													obligation of Bambooks under this Agreement.
												</li>
												<li>
													Bambooks shall not be liable for any claim regarding
													infringement of any intellectual property or
													proprietary right in relation to any Content supplied
													by The Licensor andThe Licensor shall solely be liable
													for such claim.
												</li>
												<li>
													Bambooks, its affiliated companies, suppliers, or any
													of their employees assumes no liability or
													responsibility for the accuracy, timeliness or
													completeness of any Content offered further to this
													Agreement.
												</li>
												<li>
													Neither party shall be liable for any indirect,
													special or consequential damages and/or losses,
													including but not limited to; loss of profit, loss of
													goodwill or reputation etc.
												</li>
												<li>
													Nothing in this clause 13 shall be construed as
													limiting the liability of any Party for personal
													injury or death resulting from the negligence of a
													Party or its employees.
												</li>
											</ol>
										</li>
									</ol>
								</ul>
							</div>
							<br />
							<h4 className="mb-4">
								14. Applicable Law and Dispute Resolution
							</h4>
							<div className="deci-lists">
								<ul>
									<ol>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li style={{ display: 'table-column' }}></li>
										<li>
											<ol>
												<li>
													This Agreement shall be governed and construed in
													accordance with the laws of theFederal Republic of
													Nigeria.
												</li>
												<li>
													In the event that any dispute arises out of or in
													relation to this AGREEMENT, which cannot be amicably
													resolved between the Parties through good faith
													negotiation within fifteen (15) days after the
													occurrence of the dispute, such a dispute shall be
													referred to a court of competent jurisdiction for
													determination.
												</li>
											</ol>
										</li>
									</ol>
								</ul>
							</div>
							<br />
							<h4 className="mb-4">15. Termination</h4>
							<div className="deci-lists">
								<ul>
									<ol>
										<li style={{ display: 'table-column' }}>&nbsp;&nbsp;</li>
										<li style={{ display: 'table-column' }}>&nbsp;&nbsp;</li>
										<li style={{ display: 'table-column' }}>&nbsp;&nbsp;</li>
										<li style={{ display: 'table-column' }}>&nbsp;&nbsp;</li>
										<li style={{ display: 'table-column' }}>&nbsp;&nbsp;</li>
										<li style={{ display: 'table-column' }}>&nbsp;&nbsp;</li>
										<li style={{ display: 'table-column' }}>&nbsp;&nbsp;</li>
										<li style={{ display: 'table-column' }}>&nbsp;&nbsp;</li>
										<li style={{ display: 'table-column' }}>&nbsp;&nbsp;</li>
										<li style={{ display: 'table-column' }}>&nbsp;&nbsp;</li>
										<li style={{ display: 'table-column' }}>&nbsp;&nbsp;</li>
										<li style={{ display: 'table-column' }}>&nbsp;&nbsp;</li>
										<li style={{ display: 'table-column' }}>&nbsp;&nbsp;</li>
										<li style={{ display: 'table-column' }}>&nbsp;&nbsp;</li>
										<li>
											<ol>
												<li>
													During the Term, either Party may terminate this
													Agreement by providing not less than 30 days written
													notice of such intention to terminate to the other
													Party.
												</li>
												<li>
													This Agreement may be terminated immediately by either
													Party if any of the following events occur:
													<ol>
														<li>
															of an event of force majeure which persists for a
															period of more than thirty (30) days; or
														</li>
														<li>
															The other Party enters into a scheme of
															rearrangement (reorganization) with creditors; or
														</li>
														<li>
															The appointment of a receiver or trustees in
															respect of the business of the otherParty; or
														</li>
														<li>
															The making of an assignment by the other Party for
															the benefit of its creditors.
														</li>
														<li>
															Either Party may terminate this Agreement where
															the other Party breaches any of the terms of this
															Agreement and in the case of breaches capable of
															remedy, does not remedy the breach within the
															fourteen (14) days of notice to so remedy.
														</li>
														<li>
															This Agreement shall terminate automatically and
															with immediate effect if the Parties mutually
															agree to terminate this Agreement.
														</li>
													</ol>
												</li>
											</ol>
										</li>
									</ol>
								</ul>
							</div>
							<br />
							<h4 className="mb-4">16. Agency and Partnership</h4>
							<p>
								Nothing in this Agreement shall be construed as establishing or
								implying any partnership or joint venture between the Parties,
								and nothing in this Agreement shall be deemed to constitute
								either of the Parties as the agent of the other Party or
								authorize either Party to incur any expenses on behalf of the
								other party; to enter into any engagement on behalf of the other
								party; or to pledge the credit of the other party.
							</p>
							<br />
							<h4 className="mb-4">ANNEXURE X- FEES & ROYALTIES</h4>
							<p>
								The payment partner fees as at Commencement Date are as follows:
							</p>
							<table className="table table-bordered">
								<tbody>
									<tr>
										<td>Payment Partner</td>
										<td>Payment partner fees(% of gross)</td>
										<td>VAT (% of Gross)</td>
										<td>WHT (% of Gross)</td>
									</tr>
									<tr></tr>
									<tr>
										<td>Credit/Debit Cards</td>
										<td>1.5</td>
										<td>5</td>
										<td>10</td>
									</tr>
								</tbody>
							</table>
							<br />
							<h5 className="mb-4">
								<u>Royalty Payments</u>
							</h5>
							<ul>
								<li>
									Bamboo Fund - Determined monthly from user subscriptions{' '}
								</li>
								<li>Payout per page = Bamboo Fund/Total pages read</li>
								<li>Royalty payout = Authors pages read x Payout per page.</li>
							</ul>
							<p>
								For example, here's how we calculate royalty payout if N10
								million in funds were available ina given month with 1,000,000
								total pages read
							</p>
							<ul>
								<li>
									<b>Author with a 100-page book</b> that was read completely 10
									times would earn <b>N100,000</b> (N10 million divided by
									1,000,000 multiplied by 10,000 pages for this author).
								</li>
							</ul>
							<br />
							<h4 className="mb-4">ANNEXURE Y- DELIVERY SCHEDULE</h4>
							<p>
								The payment partner fees as at Commencement Date are as follows:
							</p>
							<div style={{ overflowX: 'auto' }}>
								<table className="table table-bordered">
									<thead>
										<tr>
											<th>Content Count</th>
											<th>Content Type</th>
											<th>Service Type</th>
											<th>Delivery Format</th>
											<th>Delivery Specifications</th>
											<th>Delivery Channel</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>&nbsp;</td>
											<td>Mobile Books</td>
											<td>Mobile Reading</td>
											<td>EPUB, Mobi,PDF</td>
											<td>
												EPUB, PDF Book Cover, Synopsis, About Author,Bio Picture
											</td>
											<td>Submissions through Bambooks Digital Publishing</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
