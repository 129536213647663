import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { readFile } from '../Helpers';
import AddMultiFileAudioBooks from './AddMultiFileAudioBook';
import { createSingleBook, multipartUpload } from '../../actions/Content';

export default function SelectFiles({ book, hidden, next }) {
  const { book_type = 'book', country_restriction = [] } = book;
  const [files, setFile] = useState({
    cover_base64: '',
    original_cover_name: '',
    // ...(book_type !== 'audiobook' && {
    //   num_of_pages: 0,
    //   book_base64: null,
    //   original_book_name: null,
    // }),
  });

  const dispatch = useDispatch();
  const [terms, setTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const { register, errors, handleSubmit } = useForm();

  const uploadBook = async (b) => {
    console.log(b);
    if (files.original_book_name && files.original_cover_name) {
      setLoading(true);

      const formData = new FormData();
      formData.append('stream', b.book[0], b.book[0].name);

      const upload = await multipartUpload(formData, (e) => {
        // if (e.lengthComputable) {
        // }
      });

      console.log(upload);

      if (upload.status === 'success') {
        createSingleBook(
          {
            ...book,
            ...files,
            file_path: upload.data,
            num_of_pages: Number(b.num_of_pages),
            original_book_name: b.book[0].name,
            country_restriction: [...new Set(country_restriction)],
          },
          // eslint-disable-next-line no-unused-vars
          (s, _) => {
            if (s) {
              next(3);
            } else {
              setLoading(false);
              toast.error('Error creating book.');
            }
          },
          dispatch
        );
      } else {
        setLoading(false);
        toast.error('Failed to upload book. please try again.');
      }
    } else {
      toast.error('Please select a cover image');
    }
  };

  return (
    !hidden &&
    (loading ? (
      <>
        <div className='spinner' hidden={hidden} />
        <div style={{ textAlign: 'center' }}>Creating your book</div>
      </>
    ) : (
      <>
        <div className='mb-3'>
          <div className='form-group'>
            <label className='form-control-label'>
              Book Cover <span className='text-danger'>*</span>
            </label>
            <div className='input-group'>
              <span className='input-group-btn'>
                <button
                  type='button'
                  className='btn btn-primary ripple'
                  onClick={() => {
                    // eslint-disable-next-line no-undef
                    document.getElementById('cover').click();
                  }}
                >
                  Browse
                </button>
              </span>
              <input
                readOnly
                name=''
                type='text'
                className='form-control'
                value={files.original_cover_name || ''}
              />
              <input
                hidden
                id='cover'
                type='file'
                name='cover'
                accept='image/*'
                ref={register({
                  required: true,
                  validate: (e) => e[0].size < 10485760,
                })}
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file.type.includes('image/')) {
                    readFile(file, 'url', (e) => {
                      const img = new Image();
                      img.src = e.target.result;
                      img.onload = function () {
                        if (book_type === 'audiobook') {
                          if (this.height === this.width) {
                            setFile({
                              ...files,
                              original_cover_name: file.name,
                              cover_base64: e.target.result,
                            });
                          } else {
                            toast.error('Audio book covers must be square sized!');
                          }
                        } else {
                          setFile({
                            ...files,
                            original_cover_name: file.name,
                            cover_base64: e.target.result,
                          });
                        }
                      };
                    });
                  } else {
                    toast.error('Invalid file type');
                  }
                }}
              />
            </div>
            <small className='text-info'>
              Only JPG, PNG formats are allowed; File Size limit - 10MB
            </small>
            {errors.img && 'File size too large'}
          </div>
        </div>
        {book_type !== 'audiobook' ? (
          <>
            <div className=' mb-4'>
              <div className=' mb-3'>
                <label className='form-control-label'>
                  Length of Book
                  <span className='text-danger ml-2'>*</span>
                </label>
                <input
                  type='text'
                  name='num_of_pages'
                  className='form-control'
                  ref={register({
                    required: true,
                    validate: (v) => !isNaN(Number(v)),
                  })}
                  placeholder='specify book length'
                />
              </div>
              <div className='mb-3'>
                <div className='form-group'>
                  <label className='form-control-label'>
                    Select Book
                    <span className='text-danger ml-2'>*</span>
                  </label>
                  <div className='input-group'>
                    <span className='input-group-btn'>
                      <button
                        type='button'
                        className='btn btn-primary ripple'
                        onClick={() => {
                          // eslint-disable-next-line no-undef
                          document.getElementById('book').click();
                        }}
                      >
                        Browse
                      </button>
                    </span>
                    <input
                      readOnly
                      type='text'
                      className='form-control'
                      value={files.original_book_name || ''}
                    />
                    <input
                      hidden
                      id='book'
                      name='book'
                      type='file'
                      accept='.epub, .zip'
                      ref={register({
                        required: true,
                        validate: (e) =>
                          e[0].size < 52428800 && e[0].type.includes('application/epub'),
                      })}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        setFile({
                          ...files,
                          original_book_name: file.name,
                        });
                      }}
                    />
                  </div>
                  <small className='text-info'>
                    Only EPUB format is allowed; File Size limit - 50MB
                  </small>
                </div>
              </div>
            </div>
            <div className='flex full f-a-c mb-3'>
              <div>
                <div className='styled-checkbox'>
                  <input
                    type='checkbox'
                    name='savecard'
                    id='check-terms'
                    onChange={(e) => setTerms(e.target.checked)}
                  />
                  <label htmlFor='check-terms'>
                    I understand and agree to the <Link to='/terms'>terms and conditions</Link> and{' '}
                    <Link to='/content-policy'>content policy guidelines</Link>
                  </label>
                </div>
              </div>
              <ul className='pager wizard text-right f-groe'>
                <li className='previous d-inline-block'>
                  <button
                    type='button'
                    className='btn btn-secondary ripple'
                    onClick={() => next(1)}
                  >
                    Back
                  </button>
                </li>{' '}
                <li className='next d-inline-block'>
                  <button
                    type='submit'
                    onClick={handleSubmit(uploadBook)}
                    disabled={!terms}
                    className='btn btn-gradient-01'
                  >
                    Create Book
                  </button>
                </li>
              </ul>
            </div>
          </>
        ) : (
          <AddMultiFileAudioBooks
            book={{ ...book, ...files }}
            next={next}
            setLoading={setLoading}
            dispatch={dispatch}
          />
        )}
      </>
    ))
  );
}

SelectFiles.propTypes = {
  hidden: PropTypes.element.isRequired,
  book: PropTypes.element.isRequired,
  next: PropTypes.element.isRequired,
};
