import React, { useState, useEffect } from 'react';

export default function CountryPicker({ country, selected, setSelected }) {
  const [restricted, setRestricted] = useState(selected);

  useEffect(() => {
    setSelected(restricted);
  }, [setSelected, restricted]);

  const getRestictions = (name, code) => {
    const cache = restricted;
    const ind = cache.country_code.findIndex((item) => item === code);
    if (ind > -1) {
      cache.country_code.splice(ind, 1);
      setRestricted({
        country_code: cache.country_code,
        country_name: cache.country_name.replace(`${name}, `, ''),
      });
    } else {
      setRestricted({
        country_code: [...cache.country_code, code],
        country_name: `${cache.country_name}${name}, `,
      });
    }
  };

  const getContinentRestriction = (checked, index) => {
    const newList = restricted;
    if (checked) {
      newList.country_name += `${country[index].continent}, `;

      country[index].countries.forEach((e) => {
        newList.country_code.push(e.country_code);
      });
    } else {
      console.log(country[index].continent);
      newList.country_name = newList.country_name.replace(
        `${country[index].continent}, `,
        '',
      );

      country[index].countries.forEach((e) => {
        const ind = newList.country_code.findIndex(
          (item) => item === e.country_code,
        );
        newList.country_code.splice(ind, 1);
      });
    }
    setRestricted({ ...newList });
  };

  return country.map((e, i) => {
    const isContinentExcluded = restricted.country_name.includes(e.continent);
    return (
      <div key={i}>
        <h4>
          <input
            type='checkbox'
            id={e.continent}
            checked={isContinentExcluded}
            value={e.continent}
            onChange={(e) => getContinentRestriction(e.target.checked, i)}
            style={{ marginRight: '8px' }}
          />
          <label htmlFor={e.continent}>
            <b>{e.continent}</b>
          </label>
        </h4>
        <hr />
        <div
          style={{
            display: 'flex',
            alignItems: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          {e.countries.map((el, i) => {
            return (
              <div
                key={i}
                style={{
                  width: '30%',
                  display: 'flex',
                  alignItems: 'first baseline',
                }}
              >
                <input
                  type='checkbox'
                  id={`${e.continent}${el.country_code}`}
                  checked={
                    restricted.country_code.includes(el.country_code) ||
                    isContinentExcluded
                  }
                  value={el.country_code}
                  onChange={() =>
                    getRestictions(el.country_name, el.country_code)
                  }
                  disabled={isContinentExcluded}
                  style={{ marginRight: '8px' }}
                />
                <label htmlFor={`${e.continent}${el.country_code}`}>
                  {el.country_name}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    );
  });
}
