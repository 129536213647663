import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  useEffect(() => {
    document.getElementById('page-top').className = 'bg-error-01';
  }, []);

  return (
    <>
      <div className="container-fluid h-100 error-01">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-12">
            {/* Begin Container */}
            <div className="error-container mx-auto text-center">
              <h1>404</h1>
              <h2>This page cannot be found! </h2>
              <p className="text-light">
                But we have lots of other pages for you to see.
              </p>
              <Link to="/" className="btn btn-shadow">
                Go Back
              </Link>
            </div>
            {/* End Container */}
          </div>
          {/* End Col */}
        </div>
      </div>
    </>
  );
}

export default NotFound;
