import React, { useState, useEffect } from 'react';
import AddBook from './Register/Step2';
import UserInfo from './Register/Step1';
import LaunchProfile from './Register/Step4';
import ConfirmBooksAdded from './Register/Step3';

export default function CreateAccount() {
  const [tab, setTab] = useState(1);
  const [det, setDet] = useState({});
  const [user, setUser] = useState({});
  const [books, setBooks] = useState([]);
  const [book, setBook] = useState([{}, false]);

  const saveBooks = (v) => {
    const cache = books;
    if (v[1] !== false) {
      cache[v[1]] = v[0];
      setBooks(cache);
    } else {
      setBooks([...books, v[0]]);
    }
    setTab(tab + 1);
  };

  const removeBook = (index) => {
    const cache = [...books];
    cache.splice(index, 1);
    setBooks([...cache]);
  };

  useEffect(() => {
    if (books.length > 0) {
      setTab(2);
    }
    // eslint-disable-next-line
  }, [book]);

  const SignIn = (v, t) => {
    setDet(v);
    setTab(t);
  };

  return (
    <>
      <div
        className='container-fluid'
        style={{ background: 'white', padding: 0 }}
      >
        <div className='wizard row'>
          <div className='col-md-4 wizard-nav-container'>
            <ul className='wizard-tabs px-md-5'>
              <li
                className={`wizard-tab-nav ${tab === 1 && 'active'} ${
                  1 < tab && 'done'
                }`}
              >
                <span className='wizard-tab-circle mr-3'>
                  {1 < tab ? (
                    <span>
                      <i className='la la-check'></i>
                    </span>
                  ) : (
                    <span>1</span>
                  )}
                </span>
                <span className='wizard-tab-title'>Personal Details</span>
              </li>

              <li
                className={`wizard-tab-nav ${tab === 2 && 'active'} ${
                  2 < tab && 'done'
                }`}
              >
                <span className='wizard-tab-circle mr-3'>
                  {2 < tab ? (
                    <span>
                      <i className='la la-check'></i>
                    </span>
                  ) : (
                    <span>2</span>
                  )}
                </span>
                <span className='wizard-tab-title'>Book Information</span>
              </li>

              <li
                className={`wizard-tab-nav ${tab === 3 && 'active'} ${
                  3 < tab && 'done'
                }`}
              >
                <span className='wizard-tab-circle mr-3'>
                  {3 < tab ? (
                    <span>
                      <i className='la la-check'></i>
                    </span>
                  ) : (
                    <span>3</span>
                  )}
                </span>
                <span className='wizard-tab-title'>Confirm Details</span>
              </li>

              <li className={`wizard-tab-nav ${tab === 4 && 'active'} last`}>
                <span className='wizard-tab-circle mr-3'>
                  <span>4</span>
                </span>
                <span className='wizard-tab-title'>Finish</span>
              </li>
            </ul>
          </div>

          <div className='col-md-8'>
            <div style={{ padding: '15px' }}>
              {tab === 1 && (
                <UserInfo
                  nextStep={(v) => {
                    setUser(v);
                    setTab(2);
                  }}
                />
              )}
              {tab === 2 && <AddBook value={book} save={saveBooks} />}
              {tab === 3 && (
                <ConfirmBooksAdded
                  books={books}
                  removeBook={removeBook}
                  user={user}
                  setTab={SignIn}
                  setBook={setBook}
                />
              )}
              {tab === 4 && <LaunchProfile det={det} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
