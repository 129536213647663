import React from 'react';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import configureStore from './Store';
import * as serviceWorker from './serviceWorker';

import 'chart.js';

// Polyfills
import './polyfill';

// Global CSS styles
import './App.css';
import './assets/css/xs.css';
import './assets/css/custom.css';
import './assets/css/bootstrap.min.css';
import './assets/css/bambooks-1.5.min.css';
import './assets/css/bootstrap-select.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-circular-progressbar/dist/styles.css';

// It's useful to note that depending
// on where these params are used,
// e = event (in event handlers) or element (in array functions)
// d = data
// s = status or state
// m = message
// v = value

ReactGA.initialize('UA-175049308-1');

ReactDOM.render(
  <React.StrictMode>
    <Provider store={configureStore()}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
